// @ts-nocheck
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  __markAsModule(target);
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// ../core/src/constants/metrics.constants.ts
var VitalsRawMetrics = [
  "vitals_lcp_measurable",
  "vitals_lcp_sum",
  "vitals_lcp_good",
  "vitals_lcp_medium",
  "vitals_lcp_bad",
  "vitals_fid_measurable",
  "vitals_fid_sum",
  "vitals_fid_good",
  "vitals_fid_medium",
  "vitals_fid_bad",
  "vitals_cls_measurable",
  "vitals_cls_sum",
  "vitals_cls_good",
  "vitals_cls_medium",
  "vitals_cls_bad",
  "vitals_inp_measurable",
  "vitals_inp_sum",
  "vitals_inp_good",
  "vitals_inp_medium",
  "vitals_inp_bad",
  "vitals_ttfb_measurable",
  "vitals_ttfb_sum",
  "vitals_ttfb_good",
  "vitals_ttfb_medium",
  "vitals_ttfb_bad",
  "vitals_fcp_measurable",
  "vitals_fcp_sum",
  "vitals_fcp_good",
  "vitals_fcp_medium",
  "vitals_fcp_bad"
];
var VitalsCalculatedMetrics = [
  "vitals_lcp",
  "vitals_lcp_good_rate",
  "vitals_lcp_medium_rate",
  "vitals_lcp_bad_rate",
  "vitals_fid",
  "vitals_fid_good_rate",
  "vitals_fid_medium_rate",
  "vitals_fid_bad_rate",
  "vitals_cls",
  "vitals_cls_good_rate",
  "vitals_cls_medium_rate",
  "vitals_cls_bad_rate",
  "vitals_inp",
  "vitals_inp_good_rate",
  "vitals_inp_medium_rate",
  "vitals_inp_bad_rate",
  "vitals_ttfb",
  "vitals_ttfb_good_rate",
  "vitals_ttfb_medium_rate",
  "vitals_ttfb_bad_rate",
  "vitals_fcp",
  "vitals_fcp_good_rate",
  "vitals_fcp_medium_rate",
  "vitals_fcp_bad_rate"
];
var ALL_RAW_WEB_METRICS = [
  "scrollY",
  "impressions",
  "errors",
  "sum_pageView_activeEngagementTime",
  "count_event_end",
  "count_low_engagement",
  "sessions",
  "pageViews",
  "pageViews_ends",
  "sessions_ends",
  "session_starts",
  "pageView_starts",
  "pageView_with_ads",
  "session_with_ads",
  ...VitalsRawMetrics,
  "min_timestamp",
  "max_timestamp",
  "acquisition_cost",
  "acquisition_cost_measurable_session_starts",
  "nav_domain_lookup_time",
  "nav_network_time",
  "nav_server_time",
  "nav_transfer_time",
  "nav_dom_processing_time",
  "nav_dom_content_loading_time",
  "nav_unload_event_time",
  "nav_transfer_size",
  "nav_encoded_body_size",
  "nav_decoded_body_size"
];
var ALL_WEB_METRICS = [
  ...VitalsCalculatedMetrics,
  "pageViews",
  "sessions",
  "session_starts",
  "pageView_starts",
  "mean_scrollY",
  "ads_per_pageView",
  "active_pageView_time_minutes",
  "bounceRate",
  "pageViews_per_session",
  "ads_per_session",
  "errors_per_pageView",
  "errors_per_session",
  "active_session_time_minutes",
  "pageViews_without_ads_ratio",
  "sessions_without_ads_ratio",
  "errors",
  "acquisition_cost",
  "acquisition_cost_measurable_session_starts",
  "avg_network_time",
  "avg_transfer_time",
  "avg_dom_completion_time",
  "avg_page_load_time",
  "avg_server_time",
  "avg_dom_processing_time",
  "avg_on_load_time",
  "avg_encoded_body_size",
  "avg_decoded_body_size",
  "avg_transfer_size"
];
var ALL_RAW_YIELD_METRICS = [
  "impressions_filled",
  "ad_unfilled_count",
  "max_timestamp",
  "min_timestamp",
  "impressions",
  "impressions_filled",
  "impressions_unfilled",
  "impressions_lineitem_house",
  "pageViews",
  "pageViewsExact",
  "sessions",
  "sessions_with_bounced_clicks",
  "session_starts",
  "pageView_starts",
  "viewable",
  "viewable_measurable_impressions",
  "clicks",
  "clicksBounced",
  "clicksReturned",
  "prebid_revenue",
  "prebid_won_impressions",
  "prebid_won_revenue",
  "prebid_lost_impressions",
  "prebid_lost_revenue",
  "prebid_cached_count",
  "prebid_incremental_value",
  "nativo_impressions",
  "nativo_revenue",
  "taboola_impressions",
  "taboola_revenue",
  "yahooGemini_impressions",
  "yahooGemini_revenue",
  "aps_revenue",
  "aps_impressions",
  "adSense_impressions",
  "adSense_revenue",
  "ayMediation_impressions",
  "ayMediation_revenue",
  "outbrain_impressions",
  "outbrain_revenue",
  "dynamicAllocation_impressions",
  "dynamicAllocation_predicted_impressions",
  "dynamicAllocation_revenue",
  "dynamicAllocation_revenue_sum_of_squares",
  "dynamicAllocation_revenue_with_forecast",
  "dynamicAllocation_revenue_with_forecast_client",
  "dynamicAllocation_predicted_revenue",
  "dynamicAllocation_predicted_revenue_residual",
  "dynamicAllocation_predicted_revenue_abs_difference",
  "dynamicAllocation_predicted_revenue_server",
  "dynamicAllocation_predicted_revenue_server_residual",
  "dynamicAllocation_predicted_revenue_server_abs_difference",
  "dynamicAllocation_confirmed_clicks",
  "acquisition_cost",
  "acquisition_cost_measurable_session_starts",
  "direct_impressions",
  "direct_revenue",
  "lineItem_revenue",
  "direct_impressions_legacy",
  "direct_revenue_legacy",
  "lineItem_revenue_legacy",
  "bias",
  "_count",
  "prebid_lost_revenue_hb",
  "ivt_measurable_impressions",
  "givt_impressions",
  "sivt_impressions",
  ...VitalsRawMetrics,
  "uniqueExternalId",
  "first_five_indicator",
  "floor",
  "floor_measurable_impressions",
  "floor_empirical_revenue",
  "blocked_impressions",
  "prebid_user_id_providers_sum_uses",
  "prebid_user_id_providers_zero_usages"
];
var ALL_YIELD_METRICS = [
  "ad_unfilled",
  "ivt_rate",
  "givt_rate",
  "sivt_rate",
  "impressions",
  "impressions_filled",
  "impressions_filled_rate",
  "impressions_unfilled",
  "impressions_unfilled_rate",
  "impressions_unfilled_n_house",
  "impressions_unfilled_n_house_rate",
  "pageViews",
  "pageViewsExact",
  "sessions",
  "session_starts",
  "pageView_starts",
  "ads_per_pageView",
  "ads_per_pageViewExact",
  "ads_per_session",
  "revenue",
  "revenue_with_forecast",
  "clicks",
  "rpm",
  "rpm_with_forecast",
  "valueCpm",
  "pageView_rpm",
  "pageView_valueRpm",
  "session_rpm",
  "session_start_rpm",
  "session_rpm_with_forecast",
  "session_valueRpm",
  "pageViews_per_session",
  "pageViewsExact_per_session",
  "viewability",
  "ctr",
  "cbr",
  "clr",
  "direct_impressions",
  "direct_revenue",
  "direct_rpm",
  "direct_valueRpm",
  "direct_winRate",
  "direct_pageView_rpm",
  "direct_pageView_valueRpm",
  "direct_session_rpm",
  "direct_session_valueRpm",
  "programmatic_impressions",
  "programmatic_revenue",
  "programmatic_revenue_with_forecast",
  "programmatic_rpm",
  "programmatic_valueRpm",
  "programmatic_winRate",
  "programmatic_pageView_rpm",
  "programmatic_pageView_valueRpm",
  "programmatic_session_rpm",
  "programmatic_session_rpm_with_forecast",
  "programmatic_session_valueRpm",
  "nativo_impressions",
  "nativo_revenue",
  "nativo_rpm",
  "nativo_pageView_rpm",
  "nativo_session_rpm",
  "nativo_winRate",
  "content_recommendation_impressions",
  "content_recommendation_revenue",
  "content_recommendation_rpm",
  "content_recommendation_pageView_rpm",
  "content_recommendation_session_rpm",
  "content_recommendation_winRate",
  "dynamicAllocation_impressions",
  "dynamicAllocation_revenue",
  "dynamicAllocation_revenue_with_forecast",
  "dynamicAllocation_revenue_with_forecast_client",
  "dynamicAllocation_rpm",
  "dynamicAllocation_valueRpm",
  "dynamicAllocation_winRate",
  "dynamicAllocation_pageView_rpm",
  "dynamicAllocation_pageViewExact_rpm",
  "dynamicAllocation_pageView_valueRpm",
  "dynamicAllocation_session_rpm",
  "dynamicAllocation_session_rpm_with_forecast",
  "dynamicAllocation_session_valueRpm",
  "acquisition_cost",
  "acquisition_cost_measurable_session_starts",
  "prebid_rpm",
  "prebid_cache_rate",
  "prebid_valueRpm",
  "prebid_winRate",
  "prebid_revenue",
  "prebid_pageView_rpm",
  "prebid_pageView_valueRpm",
  "prebid_session_rpm",
  "prebid_session_valueRpm",
  "prebid_won_impressions",
  "prebid_won_rpm",
  "prebid_won_valueRpm",
  "prebid_won_revenue",
  "prebid_won_pageView_rpm",
  "prebid_won_pageViewExact_rpm",
  "prebid_won_pageView_valueRpm",
  "prebid_won_session_rpm",
  "prebid_won_session_valueRpm",
  "prebid_lost_impressions",
  "prebid_lost_rpm",
  "prebid_lost_valueRpm",
  "prebid_lost_revenue",
  "prebid_lost_pageView_rpm",
  "prebid_lost_pageView_valueRpm",
  "prebid_lost_session_rpm",
  "prebid_lost_session_valueRpm",
  "prebid_incremental_value",
  "prebid_incremental_value_rpm",
  "bias",
  "lost_rpm_hb",
  "min_cpm",
  "diff",
  "won_lost_diff",
  "lineItem_revenue",
  "dynamicAllocation_predicted_impressions",
  "dynamicAllocation_predicted_impressions_error",
  "dynamicAllocation_predicted_revenue",
  "dynamicAllocation_predicted_rpm",
  "dynamicAllocation_predicted_revenue_error",
  "dynamicAllocation_predicted_revenue_relative_error",
  "dynamicAllocation_predicted_revenue_r2",
  "dynamicAllocation_predicted_revenue_mae",
  "dynamicAllocation_predicted_revenue_rmse",
  "dynamicAllocation_predicted_revenue_server",
  "dynamicAllocation_predicted_rpm_server",
  "dynamicAllocation_predicted_revenue_error_server",
  "dynamicAllocation_predicted_revenue_r2_server",
  "dynamicAllocation_predicted_revenue_mae_server",
  "dynamicAllocation_predicted_revenue_rmse_server",
  "dynamicAllocation_predicted_revenue_relative_error_server",
  "dynamicAllocation_confirmed_clicks_rate",
  ...VitalsCalculatedMetrics,
  "uniqueExternalId",
  "adSense_impressions",
  "adSense_revenue",
  "adSense_rpm",
  "adSense_pageView_rpm",
  "adSense_session_rpm",
  "adSense_winRate",
  "ayMediation_impressions",
  "ayMediation_revenue",
  "ayMediation_rpm",
  "ayMediation_pageView_rpm",
  "ayMediation_session_rpm",
  "ayMediation_winRate",
  "floor_rpm",
  "floor_impressions",
  "floor_empirical_revenue_ratio",
  "clicks_bounce_session_rate",
  "sessions_with_bounced_clicks",
  "miss_click_impression_pct",
  "blocked_impressions",
  "blocked_impressions_rate",
  "aps_impressions",
  "aps_revenue",
  "aps_rpm",
  "aps_pageView_rpm",
  "aps_session_rpm",
  "aps_winRate",
  "prebid_user_id_providers_avg",
  "prebid_user_id_providers_zero_usages",
  "prebid_user_id_providers_zero_usages_rate"
];
var ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PROFILE_METRICS = [
  "request_reduction_server_samples_requests",
  "request_reduction_server_samples_win_rate",
  "request_reduction_server_samples_bid_rate",
  "request_reduction_server_samples_wins",
  "request_reduction_server_samples_bids",
  "request_reduction_server_samples_win_revenue",
  "request_reduction_server_samples_bid_revenue",
  "request_reduction_server_samples_win_cpm",
  "request_reduction_server_samples_bid_cpm"
];
var ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS = [
  ...ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PROFILE_METRICS,
  "request_reduction_server_samples_req_reduction_rate",
  "request_reduction_server_samples_lost_bids_rate",
  "request_reduction_server_samples_lost_wins_rate",
  "request_reduction_server_samples_lost_revenue_rate",
  "request_reduction_server_samples_missed_revenue",
  "request_reduction_server_samples_predicts",
  "request_reduction_server_samples_rpb",
  "request_reduction_server_samples_reduction_efficiency",
  "request_reduction_server_samples_bid_accuracy",
  "request_reduction_server_samples_bid_precision",
  "request_reduction_server_samples_bid_recall",
  "request_reduction_server_samples_bid_specificity",
  "request_reduction_server_samples_win_accuracy",
  "request_reduction_server_samples_win_precision",
  "request_reduction_server_samples_win_recall",
  "request_reduction_server_samples_win_specificity"
];

// ../core/src/constants/_constants.ts
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";

// ../core/src/utils/std.ts
var std_exports = {};
__export(std_exports, {
  chainUpdater: () => chainUpdater,
  fromEntries: () => fromEntries,
  getEntries: () => getEntries,
  getEntriesPartial: () => getEntriesPartial,
  getKeys: () => getKeys,
  getValues: () => getValues,
  makeArray: () => makeArray,
  makeArrayNonNull: () => makeArrayNonNull,
  memoizer: () => memoizer,
  promiseOrTimeout: () => promiseOrTimeout,
  removeNullishFromList: () => removeNullishFromList,
  reverseKv: () => reverseKv,
  uppercaseFirstLetter: () => uppercaseFirstLetter,
  uppercaseFirstLetterLowerRest: () => uppercaseFirstLetterLowerRest
});
var uppercaseFirstLetter = (s) => s.charAt(0).toUpperCase() + s.slice(1);
var uppercaseFirstLetterLowerRest = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
var getKeys = (obj) => Object.keys(obj);
var getValues = (obj) => Object.values(obj);
var fromEntries = (entries) => Object.fromEntries(entries);
var getEntries = (obj) => Object.entries(obj);
var getEntriesPartial = (obj) => Object.entries(obj);
var makeArrayNonNull = (it) => {
  if (Array.isArray(it)) {
    return it;
  }
  return [it];
};
var makeArray = (it) => {
  if (it == null) {
    return [];
  }
  return makeArrayNonNull(it);
};
var removeNullishFromList = (list) => list.reduce((p, c) => c != null ? [...p, c] : p, []);
var chainUpdater = (mod2) => (mod1) => (value) => mod2(mod1(value));
var memoizer = (fn) => {
  const _cache = {};
  return (...args) => {
    let key = "";
    try {
      if (args.length === 1) {
        const singleArg = args[0];
        if (typeof singleArg === "string") {
          key = singleArg;
        } else if (typeof singleArg === "number" || typeof singleArg === "boolean") {
          key = String(singleArg);
        } else {
          key = JSON.stringify(singleArg);
        }
      } else {
        key = JSON.stringify(args);
      }
    } catch (e) {
      key = args.join("");
    }
    const cached = _cache[key];
    if (cached != null) {
      return cached;
    }
    const val = fn(...args);
    _cache[key] = val;
    return val;
  };
};
var promiseOrTimeout = async (name, promise, timeoutMs) => {
  let timeoutId;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error(`${name} - Timeout of ${timeoutMs / 1e3 / 60} minutes reached`));
    }, timeoutMs);
  });
  try {
    const result = await Promise.race([promise, timeoutPromise]);
    clearTimeout(timeoutId);
    return result;
  } catch (e) {
    clearTimeout(timeoutId);
    throw e;
  }
};
var reverseKv = (it) => {
  const result = {};
  for (const [k, v] of Object.entries(it)) {
    result[v] = k;
  }
  return result;
};

// ../core/src/constants/_constants.ts
var appHeaderId = "app-header";
var appHeaderClassSelector = "#" + appHeaderId;
var zuluTimezones = [
  "UTC",
  "Zulu",
  "GMT",
  "GMT0",
  "GMT-0",
  "GMT+0",
  "Etc/UTC",
  "Etc/Zulu",
  "Etc/GMT",
  "Etc/GMT0",
  "Etc/GMT-0",
  "Etc/GMT+0"
];
var RenderDimension;
(function(RenderDimension2) {
  RenderDimension2["PlainString"] = "PlainString";
  RenderDimension2["Link"] = "Link";
})(RenderDimension || (RenderDimension = {}));
var mappingYieldDfp = ({
  fieldValue,
  fieldName,
  field
}) => {
  const ifUnknown = `concat('no mapping (', toString(toString(${fieldValue})), ')')`;
  const value = field(ifUnknown);
  return `dictGetStringOrDefault('dimensionMappings_dict', 'to', tuple(entityId, '${fieldName}', assumeNotNull(toString(${fieldValue})) ), ${value})`;
};
var mappingDimensions = (field, fieldValue = field) => {
  const stringNotNullField = `if(isNull(${fieldValue}), '', toString(${fieldValue}))`;
  const noMappingField = `if(${stringNotNullField} = '', '_undefined', ${stringNotNullField})`;
  return `dictGetStringOrDefault('dimensionMappings_dict', 'to', tuple(entityId, '${field}', assumeNotNull(${fieldValue}) ), concat('no mapping (', ${noMappingField}, ')'))`;
};
var trafficShapingMapping = (fieldValue, customerField = "customer") => `concat(dictGetStringOrDefault('trafficShapingMappings_dict', 'to', tuple(${customerField}, 'bidder_id', ${fieldValue} ), 'Unknown'), ' (', ${fieldValue}, ')')`;
var REVENUE_SOURCE_OPTIONS = [
  "prebid",
  "dynamicAllocation",
  "lineitem",
  "video",
  "nativo",
  "taboola",
  "outbrain",
  "yahooGemini",
  "adSense",
  "aps",
  "ayMediation_cpa"
];
var PREBID_SOURCE_OPTIONS = ["unknown", "client", "server"];
var revenueLabels = {
  prebid: "Prebid",
  dynamicAllocation: "GAM - Dynamic Allocation",
  lineitem: "GAM - LineItem",
  video: "Video",
  nativo: "Nativo",
  taboola: "Taboola",
  outbrain: "Outbrain",
  yahooGemini: "Yahoo Gemini",
  adSense: "AdSense",
  aps: "Amazon",
  ayMediation_cpa: "Ay Mediation CPA"
};
var winningBidderMultiIfNoPrebid = getEntries(revenueLabels).filter(([key, _value]) => key !== "prebid").map(([key, value]) => `source = '${key}', '${value}'`).join(", ");
var createDimensions = (it) => getEntries(it).reduce((acc, [key, value]) => {
  acc[key] = {
    id: key,
    ...value
  };
  return acc;
}, {});
var confusionMatrix = (field) => {
  const pred = `(predicted > __threshold)`;
  return `multiIf(
        ${pred} AND ${field}, 'True Positive',
        NOT ${pred} AND NOT ${field}, 'True Negative',
        ${pred} AND NOT ${field}, 'False Positive',
        NOT ${pred} AND ${field}, 'False Negative',
        'No class'
    )`;
};
var YM_VERSION_NAME = `dictGetOrNull('%%DICT_YIELD_MANAGER_VERSION_NAME%%', 'version_name', tuple(entityId, manager_versionId))`;
var FLOOR_THRESHOLD_RAW = `dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_threshold), ad_floor_threshold)`;
var FLOOR_PREBID_RAW = `dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_prebid), ad_floor_prebid)`;
var FLOOR_AMAZON_RAW = `dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_amazon), ad_floor_amazon)`;
var FLOOR_PBO_RAW = `dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_pbo), ad_floor_pbo)`;
var FLOOR_STRATEGY = (field) => `multiIf(ad_floor_group = 'optimize', 'optimize', ad_floor_group = 'optimize1', 'optimize1', ad_floor_group = 'optimize2', 'optimize2', ad_floor_group = 'optimize3', 'optimize3', ${field})`;
var dimensions = createDimensions({
  unified: {
    label: "unified",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  date: {
    label: "date",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_bid_confusion_matrix: {
    label: "Bid Confusion Matrix",
    type: "select",
    row_type: "String",
    field: confusionMatrix("did_bid"),
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_win_confusion_matrix: {
    label: "Win Confusion Matrix",
    type: "select",
    row_type: "String",
    field: confusionMatrix("did_win"),
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_threshold: {
    label: "Threshold",
    type: "select",
    row_type: "Number",
    field: "__threshold",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    isMultiAttribution: true
  },
  request_reduction_server_samples_bidder: {
    label: "Bidder",
    type: "select",
    row_type: "String",
    field: "bidder_id",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_datacenter: {
    label: "Data Center",
    type: "select",
    row_type: "String",
    field: "datacenter",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_bidder_mapped: {
    label: "Bidder (mapped)",
    type: "select",
    row_type: "String",
    field: trafficShapingMapping(`assumeNotNull(toString(bidder_id))`),
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_country: {
    label: "Country",
    type: "select",
    row_type: "String",
    field: "country",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_publisher_id: {
    label: "Publisher ID",
    type: "select",
    row_type: "String",
    field: "publisher_id",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_placement_id: {
    label: "Placement ID",
    type: "select",
    row_type: "String",
    field: "placement_id",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_request_type: {
    label: "Request Type",
    type: "select",
    row_type: "String",
    field: "request_type",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_format: {
    label: "Format",
    type: "select",
    row_type: "String",
    field: "format",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_sizes: {
    label: "Sizes",
    type: "select",
    row_type: "String",
    field: "toString(sizes)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_platform: {
    label: "Platform",
    type: "select",
    row_type: "String",
    field: "platform",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_bundle: {
    label: "Bundle",
    type: "select",
    row_type: "String",
    field: "bundle",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_os: {
    label: "Operating System",
    type: "select",
    row_type: "String",
    field: "os",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_browser: {
    label: "Browser",
    type: "select",
    row_type: "String",
    field: "browser",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_device: {
    label: "Device",
    type: "select",
    row_type: "String",
    field: "device",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_codebase_version: {
    label: "ML Codebase Version",
    type: "select",
    row_type: "String",
    field: "model_version",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_version: {
    label: "Version",
    type: "select",
    row_type: "String",
    field: "version",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_device_language: {
    label: "Device Language",
    type: "select",
    row_type: "String",
    field: "device_language",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_utc_offset: {
    label: "UTC Offset",
    type: "select",
    row_type: "Number",
    field: "utc_offset",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_gdpr: {
    label: "GDPR",
    type: "select",
    row_type: "Number",
    field: "gdpr",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_us_privacy: {
    label: "US Privacy",
    type: "select",
    row_type: "String",
    field: "us_privacy",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_video_startdelay: {
    label: "Video Start Delay",
    type: "select",
    row_type: "Number",
    field: "video_startdelay",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_video_skip: {
    label: "Video Skip",
    type: "select",
    row_type: "Number",
    field: "video_skip",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_video_rewarded: {
    label: "Video Rewarded",
    type: "select",
    row_type: "Number",
    field: "video_rewarded",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_url: {
    label: "URL",
    type: "select",
    row_type: "String",
    field: "url",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_domain: {
    label: "Domain",
    type: "select",
    row_type: "String",
    field: "domain(url)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_custom1: {
    label: "Custom 1",
    type: "select",
    row_type: "String",
    field: "custom_1",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_custom2: {
    label: "Custom 2",
    type: "select",
    row_type: "String",
    field: "custom_2",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_custom3: {
    label: "Custom 3",
    type: "select",
    row_type: "String",
    field: "custom_3",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_video_playback_method: {
    label: "Video Playback Method",
    type: "select",
    row_type: "String",
    field: "toString(video_playback_method)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_site_cat: {
    label: "Size Category",
    type: "select",
    row_type: "String",
    field: "toString(site_cat)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none",
    shouldLimit: true
  },
  request_reduction_server_samples_unit_types: {
    label: "Unit Types",
    type: "select",
    row_type: "String",
    field: "toString(unit_types)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_page_cat: {
    label: "Page Category",
    type: "select",
    row_type: "String",
    field: "toString(page_cat)",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_gps: {
    label: "Has GPS",
    type: "select",
    row_type: "Number",
    field: "has_gps",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_omsdk: {
    label: "Has OMSDK",
    type: "select",
    row_type: "Number",
    field: "has_omsdk",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_age: {
    label: "Has Age",
    type: "select",
    row_type: "Number",
    field: "has_age",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_gender: {
    label: "Has Gender",
    type: "select",
    row_type: "Number",
    field: "has_gender",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_mraid: {
    label: "Has MRaid",
    type: "select",
    row_type: "Number",
    field: "has_mraid",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_vast: {
    label: "Has Vast",
    type: "select",
    row_type: "Number",
    field: "has_vast",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_vpaid: {
    label: "Has VPaid",
    type: "select",
    row_type: "Number",
    field: "has_vpaid",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_has_consent: {
    label: "Has Consent",
    type: "select",
    row_type: "Number",
    field: "has_consent",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_samples_is_qps: {
    label: "Is QPS",
    type: "select",
    row_type: "Number",
    field: "is_qps",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_datacenter: {
    label: "Data Center",
    type: "select",
    row_type: "String",
    field: "datacenter",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_server_node: {
    label: "Node",
    type: "select",
    row_type: "String",
    field: "deploy_id",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  acquisition_clickId: {
    label: "Acquisition ClickId",
    field: "acquisition_clickId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield_api"],
    uiGroup: "none"
  },
  acquisition_clickId_param: {
    label: "Acquisition ClickId Param",
    field: "acquisition_clickId_param",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  preBidWon: {
    label: "Won",
    field: "prebid_won",
    fieldPrebid: "ifNull(rendered=1, 0)",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  bidder_prebid: {
    description: "The exchange/SSP partner.",
    label: "Bidder",
    fieldPrebid: "bidder",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["prebid"],
    uiGroup: "demand"
  },
  bidder: {
    description: "The winning/rendered bidder.",
    label: "Winning Bidder",
    field: "multiIf(source = 'prebid', prebid_highestBidPartner, source = 'dynamicAllocation', 'GAM - Dynamic Allocation', 'GAM - Other')",
    fieldPrebid: "if(ifNull(rendered, 0) = 1, bidder, 'Request did not result in Impression')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    isTombstone: true,
    uiGroup: "demand"
  },
  winning_bidder: {
    description: "The winning/rendered bidder.",
    label: "Winning Bidder",
    field: `multiIf(ad_unfilled = 1, 'Unfilled', source = 'prebid', prebid_highestBidPartner, ${winningBidderMultiIfNoPrebid}, 'Other')`,
    fieldPrebid: "if(ifNull(rendered, 0) = 1, bidder, 'Request did not result in Impression')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  highest_bidder: {
    description: "The highest bidder from prebid for the given placement.",
    label: "Highest Bidder",
    field: "prebid_highestBidPartner",
    fieldPrebid: "if(bidPosition = 0, bidder, '')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  dsp: {
    label: "DSP",
    field: "dictGetStringOrDefault('dsp', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_buyerId), concat(prebid_highestBidPartner, ' - ', toString(ad_buyerId)))",
    type: "select",
    fieldPrebid: "dictGetStringOrDefault('dsp', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_buyerId)), concat(bidder, ' - ', toString(ad_buyerId)))",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  buyer: {
    label: "Buyer",
    field: "dictGetStringOrDefault('buyer', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_buyerId), concat(prebid_highestBidPartner, ' - ', toString(ad_buyerId)))",
    fieldPrebid: "dictGetStringOrDefault('buyer', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_buyerId)), concat(bidder, ' - ', toString(ad_buyerId)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  advertiserDomain: {
    label: "Advertiser Domain",
    field: "ad_advertiserDomain",
    fieldPrebid: "ad_advertiserDomain",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  brand: {
    label: "Brand",
    field: "dictGetStringOrDefault('brand', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_brandId), concat(prebid_highestBidPartner, ' - ', toString(ad_brandId)))",
    fieldPrebid: "dictGetStringOrDefault('brand', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_brandId)), concat(bidder, ' - ', toString(ad_brandId)))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  deal: {
    label: "Deal ID",
    field: "ad_dealId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "demand"
  },
  mapped_deal: {
    label: "Deal ID - Mapped",
    field: mappingDimensions("ad_dealId"),
    fieldPrebid: mappingDimensions("dealId"),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  mediaType: {
    label: "Media Type",
    field: "ad_mediaType",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  bid: {
    label: "Bid Landscape",
    field: "FLOOR(prebid_highestBid / 1000000 * 20) / 20",
    fieldPrebid: "FLOOR(bid / 1000000 * 20) / 20",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  timeToRespond: {
    label: "Time to Respond",
    field: "FLOOR(prebid_timeToRespond / 50) * 50",
    fieldPrebid: "if(prebid_timeToRespond = 0, NULL, FLOOR(prebid_timeToRespond / 50) * 50)",
    where: "_dimension <= 5000",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "demand"
  },
  dfpAdvertiser: {
    label: "GAM Advertiser",
    field: "dictGetStringOrDefault('advertiser', 'name', toUInt64(dfp_advertiserId), CONCAT('Unknown - ', toString(dfp_advertiserId)))",
    type: "select",
    description: "The GAM Advertiser.",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "ad_server"
  },
  mapped_dfpAdvertiser: {
    description: "The GAM Advertiser.",
    label: "GAM Advertiser - Mapped",
    field: mappingYieldDfp({
      field: (ifUnknown) => `dictGetStringOrDefault('advertiser', 'name', toUInt64(dfp_advertiserId), ${ifUnknown})`,
      fieldName: "dfpAdvertiser",
      fieldValue: "dfp_advertiserId"
    }),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpAdvertiserId: {
    label: "GAM Advertiser Id",
    field: "toString(dfp_advertiserId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  mapped_dfpAdvertiserId: {
    description: "The GAM Advertiser Id.",
    label: "GAM Advertiser Id - Mapped",
    field: mappingDimensions("dfpAdvertiserId", "toString(dfp_advertiserId)"),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpHash: {
    label: "AY Hash K/V",
    field: "toString(dfp_hash)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    description: "The custom key/value used to sync GAM and AY.",
    uiGroup: "ad_server"
  },
  dfpHashRaw: {
    label: "Raw AY Hash K/V",
    field: "dfp_hash_raw",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  dfpOrder: {
    description: "The GAM Order.",
    label: "GAM Order",
    field: "dictGetStringOrDefault('campaign', 'name', toUInt64(dfp_campaignId), CONCAT('Unknown - ', toString(dfp_campaignId)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "ad_server"
  },
  mapped_dfpOrder: {
    description: "The GAM Order.",
    label: "GAM Order - Mapped",
    field: mappingYieldDfp({
      field: (ifUnknown) => `dictGetStringOrDefault('campaign', 'name', toUInt64(dfp_campaignId), ${ifUnknown})`,
      fieldName: "dfpOrder",
      fieldValue: "dfp_campaignId"
    }),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpOrderId: {
    label: "GAM Order Id",
    field: "toString(dfp_campaignId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  mapped_dfpOrderId: {
    description: "The GAM Order Id.",
    label: "GAM Order Id - Mapped",
    field: mappingDimensions("dfpOrderId", "toString(dfp_campaignId)"),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpLineItem: {
    description: "The GAM Line Item.",
    label: "GAM Line Item",
    field: "dictGetStringOrDefault('lineItem', 'name', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "ad_server"
  },
  mapped_dfpLineItem: {
    description: "The GAM Line Item.",
    label: "GAM Line Item - Mapped",
    field: mappingYieldDfp({
      field: (ifUnknown) => `dictGetStringOrDefault('lineItem', 'name', toUInt64(dfp_lineItemId), ${ifUnknown})`,
      fieldName: "dfpLineItem",
      fieldValue: "dfp_lineItemId"
    }),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpLineItemId: {
    label: "GAM Line Item Id",
    field: "toString(dfp_lineItemId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  mapped_dfpLineItemId: {
    description: "The GAM Line Item Id.",
    label: "GAM Line Item Id - Mapped",
    field: mappingDimensions("dfpLineItemId", "toString(dfp_lineItemId)"),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpLineItemType: {
    description: "The GAM Line Item Type.",
    label: "GAM Line Item Type",
    field: "dictGetStringOrDefault('lineItem', 'lineItemType', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "ad_server"
  },
  dfpLineItemCostType: {
    label: "GAM Line Item Cost Type",
    field: "dictGetStringOrDefault('lineItem', 'costType', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  dfpCreative: {
    description: "The GAM Creative.",
    label: "GAM Creative",
    field: "toString(dfp_creativeId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "ad_server"
  },
  mapped_dfpCreative: {
    description: "The GAM Creative.",
    label: "GAM Creative - Mapped",
    field: mappingDimensions("dfp_creativeId", "toString(dfp_creativeId)"),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  dfpIsBackfill: {
    label: "Is Dynamic Allocation",
    field: "dfp_isBackfill",
    fieldPrebid: "0",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "ad_server"
  },
  source: {
    hideFromApiDocs: true,
    label: "Revenue Source",
    field: "source",
    fieldPrebid: "'prebid'",
    type: "select",
    row_type: "Enum",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    enumOptions: REVENUE_SOURCE_OPTIONS,
    uiGroup: "none"
  },
  prebid_source: {
    label: "Prebid Source",
    field: "prebid_source",
    type: "select",
    row_type: "Enum",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    enumOptions: PREBID_SOURCE_OPTIONS,
    uiGroup: "implementation"
  },
  adUnitCode: {
    label: "Ad Unit Code",
    fieldPrebid: "dfp_adUnitPath",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["prebid"],
    uiGroup: "implementation"
  },
  placement: {
    label: "Placement",
    field: "ad_slotId",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "implementation"
  },
  adUnit: {
    label: "Ad Unit",
    field: "dfp_adUnitPath",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "implementation"
  },
  mapped_adUnit: {
    label: "Ad Unit - Mapped",
    field: mappingDimensions("adUnit", "toString(dfp_adUnitPath)"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "mapped"
  },
  size: {
    label: "Size",
    field: "ad_size",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "implementation"
  },
  adQualityIsBlocked: {
    label: "Ad Quality Is Blocked",
    field: "adQuality_blockingType > 0",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "demand"
  },
  adQualityBlockingType: {
    label: "Ad Quality Blocking Type",
    field: "dictGetString('quality_blocking_type', 'type', tuple(assumeNotNull(adQuality_blockingType)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "demand"
  },
  prebid_version: {
    label: "Prebid Version",
    field: "prebid_version",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "implementation"
  },
  assertiveVersion: {
    label: "Assertive Version",
    field: "assertiveVersion",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "implementation"
  },
  prebidUserIdProviderUsed: {
    label: "PB UID Provider Provided",
    type: "select",
    row_type: "Number",
    field: "has(prebid_userIds,arrayJoin(if(empty(prebid_configUserIds), [''], prebid_configUserIds)))",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    isMultiAttribution: true,
    uiGroup: "implementation"
  },
  prebidUserIdProviderConfigured: {
    label: "PB UID Provider",
    type: "select",
    row_type: "String",
    field: "arrayJoin(if(empty(prebid_configUserIds), [''], prebid_configUserIds))",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    isMultiAttribution: true,
    uiGroup: "implementation"
  },
  timeout: {
    label: "Timeout",
    field: "prebid_timeout",
    type: "select",
    shouldLimit: true,
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "implementation"
  },
  userState: {
    label: "User State",
    field: "custom_userState",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  layout: {
    label: "Layout",
    field: "custom_layout",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_1: {
    label: "Custom 1",
    field: "custom_1",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_2: {
    label: "Custom 2",
    field: "custom_2",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_3: {
    label: "Custom 3",
    field: "custom_3",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_4: {
    label: "Custom 4",
    field: "custom_4",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_5: {
    label: "Custom 5",
    field: "custom_5",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_6: {
    label: "Custom 6",
    field: "custom_6",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_7: {
    label: "Custom 7",
    field: "custom_7",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_8: {
    label: "Custom 8",
    field: "custom_8",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_9: {
    label: "Custom 9",
    field: "custom_9",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_10: {
    label: "Custom 10",
    field: "custom_10",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_11: {
    label: "Custom 11",
    field: "custom_11",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_12: {
    label: "Custom 12",
    field: "custom_12",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_13: {
    label: "Custom 13",
    field: "custom_13",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_14: {
    label: "Custom 14",
    field: "custom_14",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  custom_15: {
    label: "Custom 15",
    field: "custom_15",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "custom"
  },
  mapped_userState: {
    label: "User State - Mapped",
    field: mappingDimensions("custom_userState"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_layout: {
    label: "Layout - Mapped",
    field: mappingDimensions("custom_layout"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_1: {
    label: "Custom 1 - Mapped",
    field: mappingDimensions("custom_1"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_2: {
    label: "Custom 2 - Mapped",
    field: mappingDimensions("custom_2"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_3: {
    label: "Custom 3 - Mapped",
    field: mappingDimensions("custom_3"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_4: {
    label: "Custom 4 - Mapped",
    field: mappingDimensions("custom_4"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_5: {
    label: "Custom 5 - Mapped",
    field: mappingDimensions("custom_5"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_6: {
    label: "Custom 6 - Mapped",
    field: mappingDimensions("custom_6"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_7: {
    label: "Custom 7 - Mapped",
    field: mappingDimensions("custom_7"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_8: {
    label: "Custom 8 - Mapped",
    field: mappingDimensions("custom_8"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_custom_9: {
    label: "Custom 9 - Mapped",
    field: mappingDimensions("custom_9"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  is_ad_unfilled: {
    label: "Is Unfilled",
    field: "ad_unfilled",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  newVsReturning: {
    label: "New vs. Returning",
    field: "if(client_sessionCount = 1, 'New Visitor', 'Returning Visitor')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  clientDepthSessions: {
    label: "Device Depth Sessions",
    field: "if(client_sessionCount > 100, '100+', toString(client_sessionCount))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  sessionDepthPageViews: {
    description: "Number of PVs within a session",
    label: "Session Depth PV",
    field: "if(session_pageViewCount > 100, '100+', toString(session_pageViewCount))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  sessionDepthImpressions: {
    description: "Number of impressions within a session",
    label: "Session Depth Imps",
    field: "if(session_impressionCount > 200, '200+', toString(FLOOR(session_impressionCount / 5) * 5))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  device: {
    label: "Device",
    field: "if(isNull(device), 'Unknown', dictGetStringOrDefault('device', 'name', tuple(assumeNotNull(device)), 'Unknown'))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  window_innerSize: {
    label: "Window Size",
    field: "if(window_innerWidth != 0 AND window_innerHeight != 0, concat(toString(window_innerWidth), 'x', toString(window_innerHeight)), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    shouldLimit: true,
    uiGroup: "user"
  },
  window_scrollY: {
    description: "How many pixels users are scrolling down the page",
    label: "Scroll Depth",
    field: "if(window_scrollY > 4096, '4096+', toString(FLOOR(window_scrollY/100)*100) )",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  network_type: {
    label: "Network Type",
    field: "network_type",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  network_effectiveType: {
    label: "Network Effective Type",
    field: "network_effectiveType",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  network_downlink: {
    label: "Network Downlink",
    field: "toString(network_downlink)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  network_rtt: {
    label: "Network RTT (ping)",
    field: "multiIf(network_rtt < 0, 'Unknown', network_rtt > 1000, '1000+', toString(FLOOR(network_rtt/50)*50) )",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  network_saveData: {
    label: "Network Data-Saver",
    field: "multiIf(network_saveData < 0, 'Unknown', network_saveData > 0, 'On', 'Off')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  ivt_category: {
    label: "IVT Category",
    field: "dictGetStringOrDefault('ivt', 'name', tuple(ivt_category), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "user"
  },
  ivt_type: {
    label: "IVT Type",
    field: "dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "user"
  },
  operatingSystem: {
    label: "Operating System",
    field: "os_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web", "error_analytics"],
    uiGroup: "user"
  },
  topics: {
    label: "Topics",
    field: "arrayStringConcat(arrayMap(it -> dictGetString('topics', 'topic', tuple(it)), arraySort(topics)), ', ')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "web"],
    uiGroup: "user"
  },
  cookieDeprecationLabel: {
    label: "Cookie Deprecation",
    field: "cookieDeprecationLabel",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "user"
  },
  browser: {
    label: "Browser",
    field: "browser_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web", "error_analytics"],
    uiGroup: "user"
  },
  country: {
    label: "Country",
    field: "country_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web", "error_analytics"],
    uiGroup: "user"
  },
  countryCode: {
    label: "Country Code",
    field: "country_code",
    type: "select",
    row_type: "FixedString(2)",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web", "error_analytics"],
    uiGroup: "user"
  },
  region: {
    label: "Region",
    field: "region_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  continent: {
    label: "Continent",
    field: "continent_code",
    type: "select",
    row_type: "FixedString(2)",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  language: {
    label: "Language",
    field: "language",
    type: "select",
    row_type: "FixedString(2)",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "user"
  },
  sessionHadAds: {
    label: "Session Had Ads",
    field: "session_impressionCount > 0",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["web"],
    uiGroup: "implementation"
  },
  placementRenderCount: {
    label: "Placement Render Count",
    field: "(pageView_refreshCount+1)",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "implementation"
  },
  refreshCount: {
    label: "Ad Refresh Count",
    field: "pageView_refreshCount",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    uiGroup: "implementation"
  },
  pageViewHadAds: {
    label: "Page View Had Ads",
    field: "pageView_impressionCount > 0",
    type: "select",
    row_type: "Number",
    render: RenderDimension.PlainString,
    analytics_reports: ["web"],
    uiGroup: "implementation"
  },
  referrerType: {
    label: "Referrer Type",
    field: "dictGetStringOrDefault('referrer', 'type', tuple(assumeNotNull(cutToFirstSignificantSubdomain(referrer))), CASE WHEN cutToFirstSignificantSubdomain(referrer) = cutToFirstSignificantSubdomain(host) THEN 'Internal' WHEN referrer='' OR cutToFirstSignificantSubdomain(referrer) IS NULL THEN 'Direct' ELSE 'Referral' END)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  referrerDomain: {
    label: "Referrer Domain",
    field: "domainWithoutWWW(referrer)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  referrer: {
    label: "Referrer",
    field: "CONCAT(domainWithoutWWW(referrer), path(referrer))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  utmSource: {
    label: "UTM Source",
    field: "session_utm_source",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  utmMedium: {
    label: "UTM Medium",
    field: "session_utm_medium",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  utmCampaign: {
    label: "UTM Campaign",
    field: "session_utm_campaign",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  utmTerm: {
    label: "UTM Term",
    field: "session_utm_term",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  utmContent: {
    label: "UTM Content",
    field: "session_utm_content",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "source"
  },
  mapped_utmSource: {
    label: "UTM Source - Mapped",
    field: mappingDimensions("session_utm_source"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_utmMedium: {
    label: "UTM Medium - Mapped",
    field: mappingDimensions("session_utm_medium"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_utmCampaign: {
    label: "UTM Campaign - Mapped",
    field: mappingDimensions("session_utm_campaign"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_utmTerm: {
    label: "UTM Term - Mapped",
    field: mappingDimensions("session_utm_term"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  mapped_utmContent: {
    label: "UTM Content - Mapped",
    field: mappingDimensions("session_utm_content"),
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "mapped"
  },
  host: {
    label: "Domain",
    field: "host",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.Link,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  domain_and_content: {
    label: "Page Domain Path",
    field: "concat(host, pathname)",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.Link,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  error_analytics_meta_host: {
    label: "Domain",
    field: "meta_host",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["error_analytics"],
    uiGroup: "none"
  },
  error_analytics_meta_source: {
    label: "Error Source",
    field: "meta_source",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["error_analytics"],
    uiGroup: "none"
  },
  error_analytics_meta_version: {
    label: "Version (Error)",
    field: "meta_version",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["error_analytics"],
    uiGroup: "none"
  },
  entryPage: {
    label: "Entry Page",
    field: "session_entryPage",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  content: {
    label: "Content",
    field: "pathname",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  pageLevel1: {
    label: "Page Level 1",
    field: "assumeNotNull(concat(pathname_1, '/'))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  pageLevel2: {
    label: "Page Level 2",
    field: "assumeNotNull(concat(pathname_1, '/', pathname_2))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  pageLevel3: {
    label: "Page Level 3",
    field: "assumeNotNull(concat(pathname_1, '/', pathname_2, '/', pathname_3))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  pageLevel4: {
    label: "Page Level 4",
    field: "assumeNotNull(concat(pathname_1, '/', pathname_2, '/', pathname_3, '/', pathname_4))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "content"
  },
  content_headline: {
    label: "Content Headline",
    field: "content_headline",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "web"],
    uiGroup: "content"
  },
  content_author: {
    label: "Content Author",
    field: "content_author",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "web"],
    uiGroup: "content"
  },
  content_datePublished: {
    label: "Content Date Published",
    field: "toString(toDate(content_datePublished, %%TIMEZONE%%))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "web"],
    uiGroup: "content"
  },
  content_dateModified: {
    label: "Content Date Modified",
    field: "toString(toDate(content_dateModified, %%TIMEZONE%%))",
    type: "select",
    shouldLimit: true,
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "web"],
    uiGroup: "content"
  },
  pageLevel1Inf: {
    label: "Page Level 1 Inf",
    field: "assumeNotNull(concat(pathname_1, '/', ''))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "content"
  },
  entityId: {
    label: "Entity Id",
    field: "entityId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  entityName: {
    label: "AY Entity",
    field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'entity_name', tuple(entityId), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  publisherName: {
    label: "AY Publisher",
    field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'publisher_name', tuple(entityId), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  organizationName: {
    label: "AY Organization",
    field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'organization_name', tuple(entityId), 'Unknown')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  hourOfDay: {
    description: "Hour of the day, in the app's selected timezone.",
    label: "Hour of Day",
    field: "if(length(toString(toHour(timestamp, %%TIMEZONE%%))) = 1, concat('0', toString(toHour(timestamp, %%TIMEZONE%%)), 'h'), concat(toString(toHour(timestamp, %%TIMEZONE%%)), 'h'))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "time_based"
  },
  dayOfWeek: {
    description: "Day of the week, in the app's selected timezone.",
    label: "Day of the Week",
    field: "multiIf(toDayOfWeek(timestamp, %%TIMEZONE%%) = 1, 'Monday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 2, 'Tuesday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 3, 'Wednesday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 4, 'Thursday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 5, 'Friday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 6, 'Saturday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 7, 'Sunday', 'No day')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "time_based"
  },
  dayOfMonth: {
    description: "Day of the month, in the app's selected timezone.",
    label: "Day of the Month",
    field: "if(length(toString(toDayOfMonth(timestamp, %%TIMEZONE%%))) = 1, concat('0', toString(toDayOfMonth(timestamp, %%TIMEZONE%%))), toString(toDayOfMonth(timestamp, %%TIMEZONE%%)))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "time_based"
  },
  monthOfYear: {
    description: "Month of the year.",
    label: "Month on the year",
    field: "toString(toMonth(timestamp, %%TIMEZONE%%))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "time_based"
  },
  fullDate: {
    label: "Full Date",
    field: "toString(toDate(timestamp, %%TIMEZONE%%))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "time_based"
  },
  timeZone: {
    description: "User time zone.",
    label: "Time Zone",
    field: "timeZone",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "time_based"
  },
  externalId: {
    label: "External Id",
    field: "externalId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  sessionId: {
    label: "Session Id",
    field: "toString(sessionId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  buy_report_platform: {
    label: "Platform",
    field: "platform",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_campaign_name: {
    label: "Campaign Name",
    field: "campaign_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_adset_name: {
    label: "Ad Group Name",
    field: "adset_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_subAccountId: {
    label: "DSP Sub-Account Id",
    field: "subAccountId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_subAccountName: {
    label: "DSP Sub-Account",
    field: "subAccountName",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_ad_name: {
    label: "Ad Name",
    field: "ad_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_campaign_id: {
    label: "Campaign ID",
    field: "campaign_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_adset_id: {
    label: "Ad Group ID",
    field: "adset_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  buy_report_ad_id: {
    label: "Ad ID",
    field: "ad_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_platform: {
    label: "Platform",
    field: "platform",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_site: {
    label: "Site",
    field: "site",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_site_name: {
    label: "Site Name",
    field: "site_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_site_url: {
    label: "Site URL",
    field: "site_url",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_site_id: {
    label: "Site ID",
    field: "site_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_campaign_name: {
    label: "Campaign Name",
    field: "campaign_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_campaign_id: {
    label: "Campaign ID",
    field: "campaign_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_subAccountId: {
    label: "Sub Account ID",
    field: "subAccountId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  site_report_subAccountName: {
    label: "Sub Account Name",
    field: "subAccountName",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_platform: {
    label: "Platform",
    field: "platform",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_section_name: {
    label: "Section Name",
    field: "section_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_section_url: {
    label: "Section URL",
    field: "section_url",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_section_id: {
    label: "Section ID",
    field: "section_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_campaign_name: {
    label: "Campaign Name",
    field: "campaign_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_campaign_id: {
    label: "Campaign ID",
    field: "campaign_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_subAccountId: {
    label: "Sub Account ID",
    field: "subAccountId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  spent_by_section_report_subAccountName: {
    label: "Sub Account Name",
    field: "subAccountName",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  manager_status: {
    description: "Yield Manager's status.",
    label: "Yield Manager Status",
    field: "if(manager_versionId > -1, 'Active', 'Inactive')",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "yield_manager"
  },
  manager_deployId: {
    label: "Yield Manager Deploy ID",
    field: "manager_deployId",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "yield_manager"
  },
  manager_deployedAt: {
    label: "Yield Manager Deployed At",
    field: "toString(toDateTime(dictGetDateTime('%%DICT_YIELD_MANAGER_DEPLOYED_AT%%', 'deployed_at', tuple(entityId, manager_deployId)), %%TIMEZONE%%))",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "yield_manager"
  },
  manager_versionId: {
    description: "Yield Manager's version id.",
    label: "Yield Manager Version ID",
    field: "toString(manager_versionId)",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "yield_manager"
  },
  manager_versionName: {
    description: "Yield Manager's version name.",
    label: "Yield Manager Version Name",
    field: `multiIf(manager_versionId = -2, 'Working changes', manager_versionId = -1, 'Unknown', concat('v',toString(manager_versionId), if(isNull(${YM_VERSION_NAME}), '', concat(' - ', ${YM_VERSION_NAME}))))`,
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid", "web"],
    uiGroup: "yield_manager"
  },
  floor_prebid: {
    label: "Floor Prebid",
    field: FLOOR_STRATEGY(FLOOR_PREBID_RAW),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_amazon: {
    label: "Floor Amazon",
    field: FLOOR_STRATEGY(FLOOR_AMAZON_RAW),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_gpt: {
    label: "Floor GPT",
    field: "ad_floor_gpt",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_group: {
    label: "Floor Group",
    field: "ad_floor_group",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none"
  },
  floor_threshold_raw: {
    label: "Floor Threshold Raw",
    field: FLOOR_THRESHOLD_RAW,
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_threshold: {
    label: "Floor Threshold",
    field: FLOOR_STRATEGY(FLOOR_THRESHOLD_RAW),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_pbo: {
    label: "Floor PBO",
    field: FLOOR_STRATEGY(FLOOR_PBO_RAW),
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    hideFromApiDocs: true,
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  floor_status: {
    label: "Floor Status",
    field: "ad_floor_status",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "none",
    limitedToAdminOrDev: true
  },
  request_reduction_client_status: {
    label: "Bid Req. Optimization Status",
    field: "reduction_group",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["yield", "prebid"],
    uiGroup: "content"
  },
  discrepancy_revenue_bidder: {
    label: "SSP Name",
    field: "discrepancy_revenue_bidder",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  discrepancy_revenue_placement: {
    label: "Placement",
    field: "discrepancy_revenue_placement",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  financial_bidder: {
    label: "SSP Name",
    field: "financial_bidder",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  financial_placement_id: {
    label: "Placement",
    field: "financial_placement_id",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  financial_currency: {
    label: "SSP Currency",
    field: "financial_currency",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  financial_timezone: {
    label: "Timezone",
    field: "financial_timezone",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: [],
    uiGroup: "none"
  },
  request_reduction_client_v2_country: {
    label: "Country",
    field: "country_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  },
  request_reduction_client_v2_bidder: {
    label: "Bidder",
    field: "bidder",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  },
  request_reduction_client_v2_host: {
    label: "Domain",
    field: "host",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  },
  request_reduction_client_v2_device_name: {
    label: "Device Name",
    field: "device_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  },
  request_reduction_client_v2_os_name: {
    label: "OS Name",
    field: "os_name",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  },
  request_reduction_client_v2_reduction_group: {
    label: "Reduction Group",
    field: "reduction_group",
    type: "select",
    row_type: "String",
    render: RenderDimension.PlainString,
    analytics_reports: ["request_reduction_client_v2"],
    uiGroup: "none"
  }
});
var MAPPING_ALLOWED_ORIGINAL_DIMENSIONS = [
  dimensions.dfpAdvertiser,
  dimensions.dfpAdvertiserId,
  dimensions.dfpOrder,
  dimensions.dfpOrderId,
  dimensions.dfpLineItem,
  dimensions.dfpLineItemId,
  dimensions.dfpCreative,
  dimensions.adUnit,
  dimensions.utmCampaign,
  dimensions.utmContent,
  dimensions.utmMedium,
  dimensions.utmSource,
  dimensions.utmTerm,
  dimensions.deal,
  dimensions.layout,
  dimensions.userState,
  dimensions.custom_1,
  dimensions.custom_2,
  dimensions.custom_3,
  dimensions.custom_4,
  dimensions.custom_5,
  dimensions.custom_6,
  dimensions.custom_7,
  dimensions.custom_8,
  dimensions.custom_9
];
var MAPPING_IDS_RENAMED = {
  dfpAdvertiser: "dfpAdvertiser",
  dfpAdvertiserId: "dfpAdvertiserId",
  dfpOrder: "dfpOrder",
  dfpOrderId: "dfpOrderId",
  dfpLineItem: "dfpLineItem",
  dfpLineItemId: "dfpLineItemId",
  dfpCreative: "dfpCreative",
  adUnit: "adUnit",
  utmCampaign: "session_utm_campaign",
  utmContent: "session_utm_content",
  utmMedium: "session_utm_medium",
  utmSource: "session_utm_source",
  utmTerm: "session_utm_term",
  deal: "ad_dealId",
  layout: "custom_layout",
  userState: "custom_userState",
  custom_1: "custom_1",
  custom_2: "custom_2",
  custom_3: "custom_3",
  custom_4: "custom_4",
  custom_5: "custom_5",
  custom_6: "custom_6",
  custom_7: "custom_7",
  custom_8: "custom_8",
  custom_9: "custom_9"
};
var MAPPING_IDS_RENAMED_REVERSED = reverseKv(MAPPING_IDS_RENAMED);
var yieldFilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("yield")).map((it) => it.id);
var prebidFilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("prebid")).map((it) => it.id);
var webFilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("web")).map((it) => it.id);
var globalSegmentsFilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("web") || it.analytics_reports.includes("prebid") || it.analytics_reports.includes("yield")).map((it) => it.id);
var errorAnalyticsFilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("error_analytics")).map((it) => it.id);
var spentCampaignFilterIds = [
  "buy_report_platform",
  "buy_report_campaign_name",
  "buy_report_campaign_id",
  "buy_report_subAccountId",
  "buy_report_subAccountName"
];
var spentAdsetFilterIds = [
  "buy_report_platform",
  "buy_report_campaign_name",
  "buy_report_adset_name",
  "buy_report_campaign_id",
  "buy_report_adset_id",
  "buy_report_subAccountId",
  "buy_report_subAccountName"
];
var spentAdsFilterIds = [
  "buy_report_platform",
  "buy_report_campaign_name",
  "buy_report_adset_name",
  "buy_report_ad_name",
  "buy_report_campaign_id",
  "buy_report_adset_id",
  "buy_report_ad_id",
  "buy_report_subAccountId",
  "buy_report_subAccountName"
];
var siteReportFilterIds = getKeys(dimensions).filter((it) => it.startsWith("site_report_"));
var spentBySectionReportFilterIds = getKeys(dimensions).filter((it) => it.startsWith("spent_by_section_report_"));
var discrepancyRevenueReportFilterIds = getKeys(dimensions).filter((it) => it.startsWith("discrepancy_revenue"));
var financialReportFilterIds = getKeys(dimensions).filter((it) => it.startsWith("financial"));
var industryReportFiltersIds = [
  "device",
  "bidder",
  "country",
  "browser",
  "referrerType",
  "mediaType",
  "size"
];
var adRevenueReportFilterIds = [
  "device",
  "country",
  "browser",
  "referrerType",
  "mediaType",
  "size"
];
var requestReductionServerLatencyFilterIds = [
  "request_reduction_server_node",
  "request_reduction_server_datacenter"
];
var requestReductionServerSamplesProfileFilterIds = [
  "request_reduction_server_samples_bidder",
  "request_reduction_server_samples_bidder_mapped",
  "request_reduction_server_samples_country",
  "request_reduction_server_samples_publisher_id",
  "request_reduction_server_samples_placement_id",
  "request_reduction_server_samples_request_type",
  "request_reduction_server_samples_format",
  "request_reduction_server_samples_sizes",
  "request_reduction_server_samples_platform",
  "request_reduction_server_samples_bundle",
  "request_reduction_server_samples_os",
  "request_reduction_server_samples_browser",
  "request_reduction_server_samples_device",
  "request_reduction_server_samples_has_gps",
  "request_reduction_server_samples_codebase_version",
  "request_reduction_server_samples_version",
  "request_reduction_server_samples_device_language",
  "request_reduction_server_samples_utc_offset",
  "request_reduction_server_samples_gdpr",
  "request_reduction_server_samples_us_privacy",
  "request_reduction_server_samples_video_startdelay",
  "request_reduction_server_samples_video_skip",
  "request_reduction_server_samples_video_rewarded",
  "request_reduction_server_samples_url",
  "request_reduction_server_samples_video_playback_method",
  "request_reduction_server_samples_site_cat",
  "request_reduction_server_samples_unit_types",
  "request_reduction_server_samples_page_cat",
  "request_reduction_server_samples_has_omsdk",
  "request_reduction_server_samples_has_age",
  "request_reduction_server_samples_has_gender",
  "request_reduction_server_samples_has_mraid",
  "request_reduction_server_samples_has_vast",
  "request_reduction_server_samples_has_vpaid",
  "request_reduction_server_samples_has_consent",
  "request_reduction_server_samples_is_qps",
  "request_reduction_server_samples_datacenter",
  "request_reduction_server_samples_domain",
  "request_reduction_server_samples_custom1",
  "request_reduction_server_samples_custom2",
  "request_reduction_server_samples_custom3"
];
var requestReductionServerSamplesPredictFilterIds = [
  "request_reduction_server_samples_threshold",
  ...requestReductionServerSamplesProfileFilterIds,
  "request_reduction_server_samples_bid_confusion_matrix",
  "request_reduction_server_samples_win_confusion_matrix"
];
var requestReductionClientV2FilterIds = Object.values(dimensions).filter((it) => it.analytics_reports.includes("request_reduction_client_v2")).map((it) => it.id);
var STATUSPAGE_SUMMARY_URL = "https://status.assertiveyield.com/api/v2/summary.json";
var NETWORK_TIMEOUT_FOR_DOWNLOADS = 1e3 * 60 * 5;
var EDA_REQUEST_TIMEOUT_MS = 1e3 * 60 * 20;
var EXPOSED_SPENT_API_AD_DIMENSION_IDS_MAPPING = {
  platform: "buy_report_platform",
  campaign_name: "buy_report_campaign_name",
  adset_name: "buy_report_adset_name",
  ad_name: "buy_report_ad_name",
  campaign_id: "buy_report_campaign_id",
  adset_id: "buy_report_adset_id",
  ad_id: "buy_report_ad_id",
  sub_account_id: "buy_report_subAccountId",
  sub_account_name: "buy_report_subAccountName"
};
var EXPOSED_SPENT_API_PUBLISHER_DIMENSION_IDS_MAPPING = {
  site: "site_report_site",
  platform: "site_report_platform",
  site_name: "site_report_site_name",
  site_url: "site_report_site_url",
  site_id: "site_report_site_id",
  campaign_name: "site_report_campaign_name",
  campaign_id: "site_report_campaign_id",
  sub_account_id: "site_report_subAccountId",
  sub_account_name: "site_report_subAccountName"
};
var EXPOSED_SPENT_API_SECTION_DIMENSION_IDS_MAPPING = {
  platform: "spent_by_section_report_platform",
  section_name: "spent_by_section_report_section_name",
  section_url: "spent_by_section_report_section_url",
  section_id: "spent_by_section_report_section_id",
  campaign_name: "spent_by_section_report_campaign_name",
  campaign_id: "spent_by_section_report_campaign_id",
  sub_account_id: "spent_by_section_report_subAccountId",
  sub_account_name: "spent_by_section_report_subAccountName"
};
var DOCS_HOST = "https://meek-toffee-74f088.netlify.app";
var ADMIN_USER_ID = "p5T5CkFFhTwH9EbRf";
var INTERNAL_API_USER_ID = "qo3bJfdoKnt8sSfeR";
var INTERNAL_DEVELOPER_USER_ID = "7X9RhCCfesty2Erbp";
var DEV_IDS = [INTERNAL_DEVELOPER_USER_ID, INTERNAL_API_USER_ID];
var ADMIN_IDS = [ADMIN_USER_ID];
var ADMIN_OR_DEV_IDS = [...DEV_IDS, ...ADMIN_IDS];

// ../core/src/constants/placement.constants.ts
var AllBasePlacementKeyTypeCodec = [
  "buy_campaign",
  "buy_adset",
  "buy_ad",
  "industry",
  "ad_revenue",
  "session_revenue",
  "session_time",
  "site",
  "spent_by_section",
  "request_reduction_client_v2",
  "request_reduction_server_latency",
  "request_reduction_server_samples_predict",
  "request_reduction_server_samples_profile",
  "discrepancy_revenue",
  "financial",
  "time_series_forecast",
  "view_dispersion",
  "error_log",
  "web",
  "error_analytics",
  "flooring",
  "segments"
];
var ALL_PLACEMENTS = [...AllBasePlacementKeyTypeCodec, "yield", "prebid"];

// ../core/src/constants/reports.constants.ts
var AllReportPlacementKeyTypeInputCodec = [
  ...AllBasePlacementKeyTypeCodec,
  "yield_or_prebid_or_web",
  "__no_placement__"
];
var buildReports = (reports2) => reports2.map((report) => {
  var _a, _b, _c;
  return {
    ...report,
    description: (_c = report.description) != null ? _c : (_b = (_a = report.dimensions) == null ? void 0 : _a[0]) == null ? void 0 : _b.description
  };
});
var reports = buildReports([
  {
    path: "overview",
    uuid: "aa611175-0cbb-44b2-9dbd-ac2f9c8a168a",
    component: "ViewOverview",
    title: "Overview Prebid",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "overview_v2",
    uuid: "03d6bc4f-7aba-433b-a4d7-d7b3d33ae0b4",
    component: "ViewOverview",
    title: "Overview v2",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "overview_web",
    uuid: "925b1ab7-3ad5-4a11-a405-e626b1c8c48f",
    component: "ViewOverview",
    title: "Overview Web",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "industry/bidder",
    uuid: "2080401f-26d3-4f8f-a94e-7eab4f62c304",
    component: "View",
    title: "Industry - Bidder",
    description: "Last 30 days network wide bidder activity.",
    hide: true,
    dimensions: [dimensions.bidder],
    hasDemoVersion: true,
    placement: "industry",
    allowedDimensionIds: industryReportFiltersIds
  },
  {
    path: "industry/size",
    uuid: "5eff1e14-7408-4a56-94f4-bbbe5b6ff1e9",
    component: "View",
    title: "Industry - Size",
    description: "Last 30 days network wide activity, by creative size.",
    hide: true,
    dimensions: [dimensions.size],
    hasDemoVersion: true,
    placement: "industry",
    allowedDimensionIds: industryReportFiltersIds
  },
  {
    path: "industry/country",
    uuid: "758393db-a10c-4dbf-a701-115246cf857a",
    component: "View",
    title: "Industry - Country",
    description: "Last 30 days network wide activity, by country.",
    hide: true,
    dimensions: [dimensions.country],
    hasDemoVersion: true,
    placement: "industry",
    allowedDimensionIds: industryReportFiltersIds
  },
  {
    path: "industry/device",
    uuid: "0b05c30b-5efa-4cb4-8cca-708ef4074684",
    component: "View",
    title: "Industry - Device",
    description: "Last 30 days network wide activity, by device.",
    hide: true,
    dimensions: [dimensions.device],
    hasDemoVersion: true,
    placement: "industry",
    allowedDimensionIds: industryReportFiltersIds
  },
  {
    path: "industry/browser",
    uuid: "924581c2-fd04-47a5-96f0-30d702986c74",
    component: "View",
    title: "Industry - Browser",
    description: "Last 30 days network wide activity, by browser.",
    hide: true,
    dimensions: [dimensions.browser],
    hasDemoVersion: true,
    placement: "industry",
    allowedDimensionIds: industryReportFiltersIds
  },
  {
    path: "generic",
    uuid: "99d554ff-609f-4ca9-a168-34d31c0618be",
    component: "View",
    title: "Generic",
    hide: true,
    dimensions: [],
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "date",
    uuid: "09f48264-c8b5-470d-8ed4-4cb5f205ba69",
    component: "View",
    title: "Date",
    hide: true,
    dimensions: [dimensions.fullDate],
    chartType: "category",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "bidder-prebid",
    uuid: "ac8dd0a3-7513-43b6-8ff0-4d89d9f66633",
    component: "View",
    title: "Bidder",
    dimensions: [dimensions.bidder_prebid],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "bidder",
    uuid: "9e9d0e07-bc25-4758-ba92-e547e526f15e",
    component: "View",
    title: "Winning Bidder",
    dimensions: [dimensions.winning_bidder],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "hb-bidder",
    uuid: "50d70d52-2be6-4bf8-89f5-f6b3c267825a",
    component: "View",
    title: "Highest Bidder",
    dimensions: [dimensions.highest_bidder],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "advertiserDomain",
    uuid: "ace04fd1-0a27-42df-9888-9d0eebd56bbb",
    component: "View",
    title: "Advertiser Domain",
    dimensions: [dimensions.advertiserDomain],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "dsp",
    uuid: "ab65e81c-6c01-44af-af02-157dcc017f9d",
    component: "View",
    title: "DSP",
    dimensions: [dimensions.dsp],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "buyer",
    uuid: "0eba523e-8af2-45f4-a595-19a992e0946b",
    component: "View",
    title: "Buyer",
    dimensions: [dimensions.buyer],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "brand",
    uuid: "4239c71d-02f5-451a-87dd-1a15da5e1e7a",
    component: "View",
    title: "Brand",
    dimensions: [dimensions.brand],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "deal",
    uuid: "97adfe03-e5e4-49b1-836d-a6061dd8a01c",
    component: "View",
    title: "Deal",
    dimensions: [dimensions.deal],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "media-type",
    uuid: "f6cf7c77-e1f7-4e86-8bec-aff13b172bc9",
    component: "View",
    title: "Media Type",
    dimensions: [dimensions.mediaType],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "bid",
    uuid: "a0a38067-325d-4d92-a170-b1ad4e0bbe5c",
    component: "View",
    title: "Bid",
    chartType: "logarithmic",
    dimensions: [dimensions.bid],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "time-to-respond",
    uuid: "5b0b7370-2a07-4e97-bfca-104424cbc81b",
    component: "View",
    title: "Time to Respond",
    chartType: "logarithmic",
    dimensions: [dimensions.timeToRespond],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "ad-quality-is-blocked",
    uuid: "e665f088-fee3-4a85-a4e6-58d87bfbbcd0",
    component: "View",
    title: "Ad Quality Is Blocked",
    dimensions: [dimensions.adQualityIsBlocked],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "demand",
    path: "ad-quality-blocking-type",
    uuid: "c1b6da7f-aaee-4a5f-b4a9-e0d36124c30e",
    component: "View",
    title: "Ad Quality Blocking Type",
    dimensions: [dimensions.adQualityBlockingType],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "advertiser",
    uuid: "c802a490-528b-462a-8069-0e6412fccae5",
    component: "View",
    title: "GAM Advertiser",
    dimensions: [dimensions.dfpAdvertiser],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "order",
    uuid: "9bf5533b-3730-4a9e-8961-12dc94436239",
    component: "View",
    title: "GAM Order",
    dimensions: [dimensions.dfpOrder],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "lineItem",
    uuid: "99e4f8e1-19d5-4cbf-8d78-0f80c537697b",
    component: "View",
    title: "GAM Line Item",
    dimensions: [dimensions.dfpLineItem],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "lineItemType",
    uuid: "8064ac9d-ce0f-4b2d-89b8-b0e32d4ace93",
    component: "View",
    title: "GAM Line Item Type",
    dimensions: [dimensions.dfpLineItemType],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "creative",
    uuid: "9c9395c6-aa8c-4200-a5ec-0df7f4c710d1",
    component: "View",
    title: "GAM Creative",
    dimensions: [dimensions.dfpCreative],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "adServer",
    path: "hash",
    uuid: "dd0531d5-7fd3-4a68-839d-ab091bf5e322",
    component: "View",
    title: "AY Hash K/V",
    dimensions: [dimensions.dfpHash],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "ad-unit-code",
    uuid: "5d9e1862-d313-40ca-8ab0-257abaace814",
    component: "View",
    title: "Ad Unit Code",
    dimensions: [dimensions.adUnitCode],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "placements",
    uuid: "d4e93954-d484-4b2f-b43d-3f8793bd5c1d",
    component: "View",
    title: "Placements",
    dimensions: [dimensions.placement],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "ad-units",
    uuid: "dddb72d6-22d8-477b-aa44-45f557104dfa",
    component: "View",
    title: "Ad Units",
    dimensions: [dimensions.adUnit],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-ad-unit",
    uuid: "9202a43f-17e4-4c43-811e-b666c0dc20b1",
    component: "View",
    title: "Ad Units",
    dimensions: [dimensions.mapped_adUnit],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "sizes",
    uuid: "0c6348ed-bac6-4df5-9a07-d44b4a3dc65a",
    component: "View",
    title: "Creative Sizes",
    dimensions: [dimensions.size],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "timeout",
    uuid: "c350da4b-fc28-4344-bea4-122fe85ed062",
    component: "View",
    title: "Timeout Setting",
    dimensions: [dimensions.timeout],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "prebid-version",
    uuid: "1be323b2-7f9d-4f79-8716-681a07466d99",
    component: "View",
    title: "Prebid Version",
    dimensions: [dimensions.prebid_version],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "assertive-version",
    uuid: "c6e512e8-3ff3-4c94-acbf-7c1a29679619",
    component: "View",
    title: "Assertive Version",
    dimensions: [dimensions.assertiveVersion],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "floor-status",
    uuid: "1b6104c2-c47b-4859-90d4-7ae341e91fc0",
    component: "View",
    title: "Floor Status",
    dimensions: [dimensions.floor_status],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "web-session-had-ads",
    uuid: "35d7d40f-370f-42f9-a58c-096b56eb7908",
    component: "View",
    title: "Session Had Ads",
    dimensions: [dimensions.sessionHadAds],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "web-page-view-had-ads",
    uuid: "e77b67ca-1950-42ab-835c-dee52a9e1467",
    component: "View",
    title: "Page View Had Ads",
    dimensions: [dimensions.pageViewHadAds],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "refresh-count",
    uuid: "cbc0aa09-6d79-4d99-aadf-f2d3c6b51b54",
    component: "View",
    title: "Ad Refresh Count",
    dimensions: [dimensions.refreshCount],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "placement-render-count",
    uuid: "d2cf2e32-0c49-42cb-a7ea-6da5e81cc6f7",
    component: "View",
    title: "Placement Render Count",
    chartType: "logarithmic",
    dimensions: [dimensions.placementRenderCount],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "prebid-user-id-provider",
    uuid: "764ac8bc-e3fd-4069-9016-058ba9cb91c6",
    component: "View",
    title: "PB UID Provider",
    description: "Prebid User ID Provider",
    dimensions: [dimensions.prebidUserIdProviderConfigured, dimensions.prebidUserIdProviderUsed],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "implementation",
    path: "prebid-source",
    uuid: "a01e099e-9856-4a4e-b039-7b0980064d09",
    component: "View",
    title: "Prebid Source",
    description: "Prebid Source",
    dimensions: [dimensions.prebid_source],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "userstate",
    uuid: "ea0f239d-9f34-4f33-a04c-b63f65c230fb",
    component: "View",
    title: "User State",
    dimensions: [dimensions.userState],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "layout",
    uuid: "da8f0678-04a3-4972-a901-76cc98bc71cb",
    component: "View",
    title: "Layout",
    dimensions: [dimensions.layout],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-1",
    uuid: "457a6750-3b14-477f-baa2-97e62f5b2beb",
    component: "View",
    title: "Custom 1",
    dimensions: [dimensions.custom_1],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-2",
    uuid: "1b831057-83f3-45ef-abfa-9aa192aed5a9",
    component: "View",
    title: "Custom 2",
    dimensions: [dimensions.custom_2],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-3",
    uuid: "0091ada6-e3da-4f49-bee1-00b557d8b57b",
    component: "View",
    title: "Custom 3",
    dimensions: [dimensions.custom_3],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-4",
    uuid: "a03c9343-e643-4bb6-9a32-d9354e6171dd",
    component: "View",
    title: "Custom 4",
    dimensions: [dimensions.custom_4],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-5",
    uuid: "19e1d5e4-27a4-4664-9bbf-5db96588e157",
    component: "View",
    title: "Custom 5",
    dimensions: [dimensions.custom_5],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-6",
    uuid: "93903e5b-0cd1-4d0a-8761-9ede897033e1",
    component: "View",
    title: "Custom 6",
    dimensions: [dimensions.custom_6],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-7",
    uuid: "b173bbab-85af-4f62-b20f-48d929824746",
    component: "View",
    title: "Custom 7",
    dimensions: [dimensions.custom_7],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-8",
    uuid: "9eac7ba3-589a-4c78-90e8-7884fd90b932",
    component: "View",
    title: "Custom 8",
    dimensions: [dimensions.custom_8],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-9",
    uuid: "5461a359-d9ff-4b85-9a51-5da53618c73d",
    component: "View",
    title: "Custom 9",
    dimensions: [dimensions.custom_9],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-10",
    uuid: "b0f27f01-7042-452c-88fd-3b40d2eca89f",
    component: "View",
    title: "Custom 10",
    dimensions: [dimensions.custom_10],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-11",
    uuid: "a17a175f-5ae7-42c5-ae5b-bec4614395da",
    component: "View",
    title: "Custom 11",
    dimensions: [dimensions.custom_11],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-12",
    uuid: "0da54acb-41ff-4b3e-9f7f-7a40ffc2eb51",
    component: "View",
    title: "Custom 12",
    dimensions: [dimensions.custom_12],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-13",
    uuid: "90f35114-53ee-48ec-b04e-da34aaf0e9b4",
    component: "View",
    title: "Custom 13",
    dimensions: [dimensions.custom_13],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-14",
    uuid: "57417798-8999-41c5-ad0f-9b75ebf6bdcb",
    component: "View",
    title: "Custom 14",
    dimensions: [dimensions.custom_14],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "custom",
    path: "custom-15",
    uuid: "c1ec4501-2ae2-4490-bf59-322dd536d0c1",
    component: "View",
    title: "Custom 15",
    dimensions: [dimensions.custom_15],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "new-vs-returning",
    uuid: "96d44e6e-cf04-4894-b436-b7be5e2ae180",
    component: "View",
    title: "New vs Returning",
    dimensions: [dimensions.newVsReturning],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "client-depth-sessions",
    uuid: "7148ab15-93b6-4c8e-a703-ef32432eeb3c",
    component: "View",
    title: "Device Depth Sessions",
    chartType: "logarithmic",
    dimensions: [dimensions.clientDepthSessions],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "sessionDepthImpressions",
    uuid: "2f8e6c4e-455e-4ec5-9f8e-a4d3831490b1",
    component: "View",
    title: "Session Depth Impressions",
    chartType: "logarithmic",
    dimensions: [dimensions.sessionDepthImpressions],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "sessionDepthPageViews",
    uuid: "0d3ea74f-5048-40b3-b25d-488429039350",
    component: "View",
    title: "Session Depth PageViews",
    chartType: "logarithmic",
    dimensions: [dimensions.sessionDepthPageViews],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "devices",
    uuid: "3ab70c51-b765-4dca-a759-1b130d86a09b",
    component: "View",
    title: "Devices",
    dimensions: [dimensions.device],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "operating-system",
    uuid: "46f64606-7add-4382-9521-ca90c84fe346",
    component: "View",
    title: "Operating System",
    dimensions: [dimensions.operatingSystem],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "browser",
    uuid: "6c300534-17a5-4e50-8048-418ed2b964c4",
    component: "View",
    title: "Browser",
    dimensions: [dimensions.browser],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "countries",
    uuid: "9709a225-560c-4c4d-92ab-174e0b5da7ca",
    component: "View",
    title: "Countries",
    dimensions: [dimensions.country],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "regions",
    uuid: "06cc070f-c6f1-4dbd-b2a4-1c22aceb8c61",
    component: "View",
    title: "Regions",
    dimensions: [dimensions.region],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "continents",
    uuid: "8af1a052-552c-4c82-ad56-cc76fd397023",
    component: "View",
    title: "Continents",
    dimensions: [dimensions.continent],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "languages",
    uuid: "9196c454-17fb-44a9-b309-08544867de59",
    component: "View",
    title: "Languages",
    dimensions: [dimensions.language],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "window-size",
    uuid: "cbf260f1-65b3-48b4-a592-778d804d4954",
    component: "View",
    title: "Window Size",
    dimensions: [dimensions.window_innerSize],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "scroll-depth",
    uuid: "82114880-4646-4db4-a10b-8c892538727b",
    component: "View",
    title: "Scroll Depth",
    chartType: "logarithmic",
    dimensions: [dimensions.window_scrollY],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "netwok-type",
    uuid: "d2edd863-054d-483c-9667-2d292ef732f4",
    component: "View",
    title: "Network Type",
    dimensions: [dimensions.network_type],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "netwok-effective-type",
    uuid: "90d42e27-27b7-440b-8203-4e9a2f8344c3",
    component: "View",
    title: "Network Effective Type",
    dimensions: [dimensions.network_effectiveType],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "netwok-downlink",
    uuid: "c1a59c45-2999-4eab-89e0-3f7d994f6395",
    component: "View",
    title: "Network Downlink",
    dimensions: [dimensions.network_downlink],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "netwok-rtt",
    uuid: "d3de66a5-0c5f-4dd2-aaad-61dffe751f3c",
    component: "View",
    title: "Network RTT (ping)",
    chartType: "logarithmic",
    dimensions: [dimensions.network_rtt],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "netwok-save-data",
    uuid: "4e99f119-7634-4b4d-85cd-8517942def39",
    component: "View",
    title: "Network Data-Saver",
    dimensions: [dimensions.network_saveData],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "ivt-category",
    uuid: "ee022f95-8786-433d-add0-619014d7f198",
    component: "View",
    title: "IVT Category",
    dimensions: [dimensions.ivt_category],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "ivt-type",
    uuid: "851ef074-831a-4d3e-aa52-defa96d17335",
    component: "View",
    title: "IVT Type",
    dimensions: [dimensions.ivt_type],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "topics",
    uuid: "66a13a44-959a-4e60-a5bb-2ff9539a6bb7",
    component: "View",
    title: "Topics",
    dimensions: [dimensions.topics],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "user",
    path: "cookieDeprecationLabel",
    uuid: "004ed1e0-0da1-42e5-97f6-22ea7c8861af",
    component: "View",
    title: "Cookie Deprecation",
    dimensions: [dimensions.cookieDeprecationLabel],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "referrer",
    uuid: "3da7622e-4d96-4f7d-a766-f0fd43fc8ab5",
    component: "View",
    title: "Referrer Type",
    dimensions: [dimensions.referrerType],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "referrer-domain",
    uuid: "56d16ec3-58c6-434c-9350-1e8cfbece64a",
    component: "View",
    title: "Referrer Domain",
    dimensions: [dimensions.referrerDomain],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "referrer-url",
    uuid: "a00499b1-62f9-4d0d-99b2-d412777d62be",
    component: "View",
    title: "Referrer URL",
    dimensions: [dimensions.referrer],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "utm-source",
    uuid: "880f728c-c6ed-42f2-aceb-f7ba7277694f",
    component: "View",
    title: "UTM Source",
    dimensions: [dimensions.utmSource],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "utm-medium",
    uuid: "4b7e30fc-da22-4476-b778-b6589ff4e315",
    component: "View",
    title: "UTM Medium",
    dimensions: [dimensions.utmMedium],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "utm-campaign",
    uuid: "cfb09991-b5cd-4483-9d7e-50a55f4348f0",
    component: "View",
    title: "UTM Campaign",
    dimensions: [dimensions.utmCampaign],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "utm-term",
    uuid: "301dffb6-6dec-416b-84ba-fd4b45c93157",
    component: "View",
    title: "UTM Term",
    dimensions: [dimensions.utmTerm],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "source",
    path: "utm-content",
    uuid: "aea30928-d2b6-4996-be32-0127221873a8",
    component: "View",
    title: "UTM Content",
    dimensions: [dimensions.utmContent],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "request-reduction/client/status",
    uuid: "7b212e51-6ed7-4a07-8057-b76471058e9a",
    component: "View",
    title: "Status",
    hide: true,
    dimensions: [dimensions.request_reduction_client_status],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "domain",
    uuid: "65b2fe26-cd57-4333-9c44-8dcb22b90981",
    component: "View",
    title: "Domain",
    dimensions: [dimensions.host],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "entry-page",
    uuid: "69fe870f-d39a-44a1-aeb1-b447e7b6ceee",
    component: "View",
    title: "Entry Page",
    dimensions: [dimensions.entryPage],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "content",
    uuid: "1a4e98d8-e1b0-4580-beeb-cbc23b2e69e6",
    component: "View",
    title: "Content",
    dimensions: [dimensions.content],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "domain_and_content",
    uuid: "c644416a-0890-4b12-bc4e-9e948393e6b2",
    component: "View",
    title: "Page Domain Path",
    dimensions: [dimensions.domain_and_content],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "pagelevel1-inf",
    uuid: "9db975c5-05ba-4d43-a9a7-ec375631e28b",
    component: "View",
    title: "Page Level 1 Inf",
    dimensions: [dimensions.pageLevel1Inf],
    hide: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "pagelevel1",
    uuid: "b51063c1-946c-40f5-a33f-e2b61ef3e8da",
    component: "View",
    title: "Page Level 1",
    dimensions: [dimensions.pageLevel1],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "pagelevel2",
    uuid: "44b09afb-8e37-4b7d-97ed-d543f284c67c",
    component: "View",
    title: "Page Level 2",
    dimensions: [dimensions.pageLevel2],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "pagelevel3",
    uuid: "cae7c71c-78ea-4de8-99e7-bc4af4848889",
    component: "View",
    title: "Page Level 3",
    dimensions: [dimensions.pageLevel3],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "pagelevel4",
    uuid: "aa118c79-42b1-4d5a-902f-2268a06cd28d",
    component: "View",
    title: "Page Level 4",
    dimensions: [dimensions.pageLevel4],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "headline",
    uuid: "2f686f51-5c63-439b-9dc4-b1fb8d6e5cb1",
    component: "View",
    title: "Headline",
    dimensions: [dimensions.content_headline],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "author",
    uuid: "e11fd17e-97a2-45bc-a20f-b8c8a7305b92",
    component: "View",
    title: "Author",
    dimensions: [dimensions.content_author],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "datePublished",
    uuid: "feebe259-ff85-4674-9cda-9d61c91320a5",
    component: "View",
    title: "Date Published",
    dimensions: [dimensions.content_datePublished],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "content",
    path: "dateModified",
    uuid: "319a9232-b64c-47ee-9a75-0f4ec81a0416",
    component: "View",
    title: "Date Modified",
    dimensions: [dimensions.content_dateModified],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-deal",
    uuid: "09c67646-0733-4205-a1d1-da721a62f565",
    component: "View",
    title: "Deal",
    dimensions: [dimensions.mapped_deal],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-userstate",
    uuid: "fcd40c8b-ae38-400b-9658-5485b90197e2",
    component: "View",
    title: "User State",
    dimensions: [dimensions.mapped_userState],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-layout",
    uuid: "35427f7e-b48d-4b5e-9858-4baf6598282f",
    component: "View",
    title: "Layout",
    dimensions: [dimensions.mapped_layout],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-1",
    uuid: "f2cd37f4-970c-4a45-8be5-0b925600aa42",
    component: "View",
    title: "Custom 1",
    dimensions: [dimensions.mapped_custom_1],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-2",
    uuid: "37eb2df7-5544-4134-aa8f-1e0c4f1cb085",
    component: "View",
    title: "Custom 2",
    dimensions: [dimensions.mapped_custom_2],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-3",
    uuid: "fa4ffdb5-f040-49f4-ad53-8b65c9cf2ebe",
    component: "View",
    title: "Custom 3",
    dimensions: [dimensions.mapped_custom_3],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-4",
    uuid: "3cda372e-32c8-49b3-a5e7-72c039631f74",
    component: "View",
    title: "Custom 4",
    dimensions: [dimensions.mapped_custom_4],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-5",
    uuid: "6ebe2e39-fdc2-47f1-9bbf-921598adb53f",
    component: "View",
    title: "Custom 5",
    dimensions: [dimensions.mapped_custom_5],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-6",
    uuid: "18f97499-ec1b-4f87-aa48-b0a1c86d4e0b",
    component: "View",
    title: "Custom 6",
    dimensions: [dimensions.mapped_custom_6],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-7",
    uuid: "a18acfa0-b358-40d9-85f7-9806da018718",
    component: "View",
    title: "Custom 7",
    dimensions: [dimensions.mapped_custom_7],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-8",
    uuid: "ccaaa954-1b72-46a9-940c-88b8a0b5f7cd",
    component: "View",
    title: "Custom 8",
    dimensions: [dimensions.mapped_custom_8],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-custom-9",
    uuid: "e08aca01-dcd1-4e13-8c8b-a8ea49fe8cf3",
    component: "View",
    title: "Custom 9",
    dimensions: [dimensions.mapped_custom_9],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-utm-source",
    uuid: "d97bf95f-1734-4426-a020-1e30e2278e54",
    component: "View",
    title: "UTM Source",
    dimensions: [dimensions.mapped_utmSource],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-utm-medium",
    uuid: "b2657616-170c-413e-9bad-762b94b2517a",
    component: "View",
    title: "UTM Medium",
    dimensions: [dimensions.mapped_utmMedium],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-utm-campaign",
    uuid: "42b1d027-cd8d-4ed2-b6d9-911b6f0b7574",
    component: "View",
    title: "UTM Campaign",
    dimensions: [dimensions.mapped_utmCampaign],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-utm-term",
    uuid: "92b01776-9096-4ece-999e-4d400139b6f6",
    component: "View",
    title: "UTM Term",
    dimensions: [dimensions.mapped_utmTerm],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-utm-content",
    uuid: "51bc79cb-3dd8-4a06-af61-b8db536ce2b2",
    component: "View",
    title: "UTM Content",
    dimensions: [dimensions.mapped_utmContent],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-advertiser",
    uuid: "7ec0ed73-a4b4-4542-bae3-bfab7a90befe",
    component: "View",
    title: "GAM Advertiser",
    dimensions: [dimensions.mapped_dfpAdvertiser],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-advertiser-id",
    uuid: "93a9a248-7f5d-4a11-991f-e73fbe9b478c",
    component: "View",
    title: "GAM Advertiser Id",
    dimensions: [dimensions.mapped_dfpAdvertiserId],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-order",
    uuid: "e10a9ab3-d04a-4cf1-b5b4-a78db027a682",
    component: "View",
    title: "GAM Order",
    dimensions: [dimensions.mapped_dfpOrder],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-order-id",
    uuid: "78ec3c42-0a65-47ba-b387-a4d5323ec458",
    component: "View",
    title: "GAM Order Id",
    dimensions: [dimensions.mapped_dfpOrderId],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-lineItem",
    uuid: "9ddfdae8-38fd-4992-830f-cb1c4a985b94",
    component: "View",
    title: "GAM Line Item",
    dimensions: [dimensions.mapped_dfpLineItem],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-lineItem-id",
    uuid: "df636c3d-9db6-43e1-adb1-5bf40bb2ec59",
    component: "View",
    title: "GAM Line Item Id",
    dimensions: [dimensions.mapped_dfpLineItemId],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "mapped",
    path: "mapped-creative",
    uuid: "daa66d2e-ad6e-43a7-81ed-6a7909fbb549",
    component: "View",
    title: "GAM Creative",
    dimensions: [dimensions.mapped_dfpCreative],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "time",
    path: "hourOfDay",
    uuid: "259f2daf-3da1-4736-9735-7810b09c36fe",
    component: "View",
    title: "Hour Of Day",
    dimensions: [dimensions.hourOfDay],
    isAnalyticsReport: true,
    chartType: "categoryTime",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "time",
    path: "dayOfWeek",
    uuid: "35dca6bc-6556-48f1-a188-da1434c042a0",
    component: "View",
    title: "Day of the Week",
    dimensions: [dimensions.dayOfWeek],
    isAnalyticsReport: true,
    chartType: "categoryTime",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "time",
    path: "dayOfMonth",
    uuid: "32555624-9524-41cc-8e9f-3f014b39e941",
    component: "View",
    title: "Day of the Month",
    dimensions: [dimensions.dayOfMonth],
    isAnalyticsReport: true,
    chartType: "categoryTime",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "time",
    path: "monthOfYear",
    uuid: "0677a557-b9d7-4df8-bba4-e72667a985e4",
    component: "View",
    title: "Month of the Year",
    dimensions: [dimensions.monthOfYear],
    isAnalyticsReport: true,
    chartType: "categoryTime",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "time",
    path: "timeZone",
    uuid: "a5b5a427-fd9f-46ed-ab29-a7fd18260624",
    component: "View",
    title: "Time Zone",
    dimensions: [dimensions.timeZone],
    isAnalyticsReport: true,
    chartType: "category",
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "yieldManager",
    path: "yield-manager-status",
    uuid: "0df48c36-bc37-4609-96c1-4afe6b0ec2dc",
    component: "View",
    title: "Status",
    dimensions: [dimensions.manager_status],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "yieldManager",
    path: "yield-manager-deploy-id",
    uuid: "03f61822-600f-4932-b071-d30dabd4a62f",
    component: "View",
    title: "Deploy ID",
    description: "Yield Manager's deploy id.",
    dimensions: [dimensions.manager_deployId, dimensions.manager_deployedAt],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "yieldManager",
    path: "yield-manager-version-id",
    uuid: "c4e3964e-b770-404c-8a4a-1a9e6608a3be",
    component: "View",
    title: "Version ID",
    dimensions: [dimensions.manager_versionId],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    pkg: "yieldManager",
    path: "yield-manager-version-name",
    uuid: "e98f0112-9860-40c9-bc57-2bb537118d80",
    component: "View",
    title: "Version Name",
    dimensions: [dimensions.manager_versionName],
    isAnalyticsReport: true,
    placement: "yield_or_prebid_or_web",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "sessionRevQuantiles",
    uuid: "8fcc408e-23b5-43ef-b024-9b896322c9e6",
    component: "ViewHPT",
    title: "Session Revenue Quantiles",
    placement: "session_revenue",
    allowedDimensionIds: yieldFilterIds,
    dimensions: [dimensions.country]
  },
  {
    path: "sessionTime",
    uuid: "351dec39-8d39-43dd-b89c-3cae5556acda",
    component: "ViewHPT",
    title: "Session Time",
    placement: "session_time",
    allowedDimensionIds: yieldFilterIds,
    dimensions: [dimensions.country]
  },
  {
    path: "adRevenueIndex/global",
    uuid: "903b67e3-0512-46ab-82d5-1a9d5fefb40e",
    component: "View",
    title: "Ad Revenue Index - Global",
    description: "Network wide revenue.",
    hide: true,
    dimensions: [],
    hasDemoVersion: true,
    placement: "ad_revenue",
    allowedDimensionIds: adRevenueReportFilterIds
  },
  {
    path: "adRevenueIndex/size",
    uuid: "efdae57c-cdf4-4b2e-96a0-4ee7dfd884b6",
    component: "View",
    title: "Ad Revenue Index - Size",
    description: "Network wide revenue, per creative size.",
    hide: true,
    dimensions: [dimensions.size],
    hasDemoVersion: true,
    placement: "ad_revenue",
    allowedDimensionIds: adRevenueReportFilterIds
  },
  {
    path: "adRevenueIndex/country",
    uuid: "6ecc0e84-f77f-47d6-a40d-84f6fae0d94f",
    component: "View",
    title: "Ad Revenue Index - Country",
    description: "Network wide revenue, per country.",
    hide: true,
    dimensions: [dimensions.country],
    hasDemoVersion: true,
    placement: "ad_revenue",
    allowedDimensionIds: adRevenueReportFilterIds
  },
  {
    path: "adRevenueIndex/device",
    uuid: "3cd47a69-535a-4b14-bcc3-57719ffed0b8",
    component: "View",
    title: "Ad Revenue Index - Device",
    description: "Network wide revenue, per device.",
    hide: true,
    dimensions: [dimensions.device],
    hasDemoVersion: true,
    placement: "ad_revenue",
    allowedDimensionIds: adRevenueReportFilterIds
  },
  {
    path: "adRevenueIndex/browser",
    uuid: "d396fecd-9b2e-4866-86ca-7b7596e0fe40",
    component: "View",
    title: "Ad Revenue Index - Browser",
    description: "Network wide revenue, per browser.",
    hide: true,
    dimensions: [dimensions.browser],
    hasDemoVersion: true,
    placement: "ad_revenue",
    allowedDimensionIds: adRevenueReportFilterIds
  },
  {
    path: "request-reduction/client/bidder",
    uuid: "719295c4-02de-496d-9c43-82357449f6db",
    component: "View",
    title: "Bidder",
    description: "Request reduction, by bidder.",
    hide: true,
    dimensions: [dimensions.request_reduction_client_v2_bidder],
    hasDemoVersion: false,
    placement: "request_reduction_client_v2",
    allowedDimensionIds: requestReductionClientV2FilterIds
  },
  {
    path: "request-reduction/client/country",
    uuid: "3cf27376-cccd-46f8-a8a3-1efc0ce6fedd",
    component: "View",
    title: "Country",
    description: "Request reduction, by country.",
    hide: true,
    dimensions: [dimensions.request_reduction_client_v2_country],
    hasDemoVersion: false,
    placement: "request_reduction_client_v2",
    allowedDimensionIds: requestReductionClientV2FilterIds
  },
  {
    path: "request-reduction/client/domain",
    uuid: "4bd22087-60cf-4380-b47b-7987906f5ea8",
    component: "View",
    title: "Domain",
    description: "Request reduction, by domain.",
    hide: true,
    dimensions: [dimensions.request_reduction_client_v2_host],
    hasDemoVersion: false,
    placement: "request_reduction_client_v2",
    allowedDimensionIds: requestReductionClientV2FilterIds
  },
  {
    path: "request-reduction/client/device",
    uuid: "5195f532-b678-409a-bafa-af7a0169a387",
    component: "View",
    title: "Device Type",
    description: "Request reduction, by device type.",
    hide: true,
    dimensions: [dimensions.request_reduction_client_v2_device_name],
    hasDemoVersion: false,
    placement: "request_reduction_client_v2",
    allowedDimensionIds: requestReductionClientV2FilterIds
  },
  {
    path: "request-reduction/client/os",
    uuid: "3b5e996d-17ae-4ee9-83d9-ab04ed17e785",
    component: "View",
    title: "Operating System",
    description: "Request reduction, by operating system.",
    hide: true,
    dimensions: [dimensions.request_reduction_client_v2_os_name],
    hasDemoVersion: false,
    placement: "request_reduction_client_v2",
    allowedDimensionIds: requestReductionClientV2FilterIds
  },
  {
    path: "floor/status",
    uuid: "3e3d5ea7-a24f-4015-9352-d3bd30649f95",
    component: "View",
    title: "Floor Status",
    hide: true,
    dimensions: [dimensions.floor_status],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/prebid",
    uuid: "8ab08e6c-dcd4-409f-895f-5d303896b7d7",
    component: "View",
    title: "Floor Prebid",
    hide: true,
    dimensions: [dimensions.floor_prebid],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/amazon",
    uuid: "b30c32e0-d95a-11ed-afa1-0242ac120002",
    component: "View",
    title: "Floor Amazon",
    hide: true,
    dimensions: [dimensions.floor_amazon],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/gpt",
    uuid: "031c5141-ba02-4fcb-848f-f089978ebc85",
    component: "View",
    title: "Floor GPT",
    hide: true,
    dimensions: [dimensions.floor_gpt],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/group",
    uuid: "d703b990-6dc1-4ad4-bf3e-29427fc45a3e",
    component: "View",
    title: "Floor Group",
    hide: true,
    dimensions: [dimensions.floor_group],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/threshold",
    uuid: "bce0e28d-67bd-4abf-8e59-5cfb098f3786",
    component: "View",
    title: "Floor Threshold",
    hide: true,
    dimensions: [dimensions.floor_threshold],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/thresholdRaw",
    uuid: "e555c18e-e9ff-4de8-9725-bba3154b74e0",
    component: "View",
    title: "Floor Threshold Raw",
    hide: true,
    dimensions: [dimensions.floor_threshold_raw],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "floor/pbo",
    uuid: "8d22fde5-06b1-47a1-9b8a-a5d8dc5aa147",
    component: "View",
    title: "Floor PBO",
    hide: true,
    dimensions: [dimensions.floor_pbo],
    hasDemoVersion: false,
    placement: "flooring",
    allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions"
  },
  {
    path: "request-reduction/server/samples-predict/threshold",
    uuid: "166fc858-567c-466a-bd65-16101751b14b",
    component: "View",
    title: "Threshold",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_threshold],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/bid-confusion-matrix",
    uuid: "c40a9f9d-2333-4b9d-9142-1bde4877c640",
    component: "View",
    title: "Bid Confusion Matrix",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_bid_confusion_matrix
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/win-confusion-matrix",
    uuid: "e7b35597-1457-49c0-ab26-5c3cc7fd323c",
    component: "View",
    title: "Win Confusion Matrix",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_win_confusion_matrix
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/bidder",
    uuid: "0fd98e46-a87d-4a6e-93f2-9c31cae396d5",
    component: "View",
    title: "Bidder",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_bidder
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/bidder-mapped",
    component: "View",
    title: "Bidder (mapped)",
    uuid: "b932d1a2-3b1a-495c-bd0a-72e4a9d841d6",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_bidder_mapped
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/country",
    uuid: "360b2cbd-5c4a-4558-8f04-33f5923c9fdd",
    component: "View",
    title: "Country",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_country
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/datacenter",
    uuid: "ccb0ee0f-f90e-49e5-844a-1c440e3e689f",
    component: "View",
    title: "Data Center",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_datacenter
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/publisher_id",
    uuid: "69f625a4-17d4-407c-b519-3c1fba6b5dd7",
    component: "View",
    title: "Publisher ID",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_publisher_id
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/placement_id",
    uuid: "b936f911-6993-43eb-8c11-7659ccbff20c",
    component: "View",
    title: "Placement ID",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_placement_id
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/request_type",
    uuid: "907be25c-7522-46d1-9193-97a79c23362d",
    component: "View",
    title: "Request Type",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_request_type
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/format",
    uuid: "e1347ab2-79c4-447f-9eb1-6a2698f49529",
    component: "View",
    title: "Format",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_format
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/sizes",
    uuid: "bc7dcd4f-ff23-4335-84f1-dfd5739fbe89",
    component: "View",
    title: "Sizes",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_sizes
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/platform",
    uuid: "1685acd5-b34b-4410-8f82-15ecda0de4a5",
    component: "View",
    title: "Platform",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_platform
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/bundle",
    uuid: "89e96d3f-c77f-4ff5-b827-8b77802f0127",
    component: "View",
    title: "Bundle",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_bundle
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/os",
    uuid: "1ceac51e-8aae-48e2-b32d-1b37ac80c1fa",
    component: "View",
    title: "Operating System",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_os
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/browser",
    uuid: "6cc64ad3-9670-404a-82df-8621bfc8e919",
    component: "View",
    title: "Browser",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_browser
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/device",
    uuid: "2de9e298-cf7a-4042-babc-011718633e05",
    component: "View",
    title: "Device",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_device
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/codebase_version",
    uuid: "d64a0cc9-334c-4ee3-8250-28a797615174",
    component: "View",
    title: "ML Codebase Version",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_codebase_version
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/version",
    uuid: "564c78a8-da6a-442d-bd6e-c6d7ff50806a",
    component: "View",
    title: "Version",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_version
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/device-language",
    uuid: "bed86543-148f-45b3-9359-5e9cc8f2d958",
    component: "View",
    title: "Device Language",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_device_language
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/url",
    uuid: "dd1405b7-b775-492f-a320-120139c635fb",
    component: "View",
    title: "URL",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_url
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/domain",
    uuid: "9cda1e8e-0685-4ba2-b2c8-c3225ebd1ba1",
    component: "View",
    title: "Domain",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_domain
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/custom1",
    uuid: "bd28adce-89f8-4296-a007-d238fde21dec",
    component: "View",
    title: "Custom 1",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_custom1
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/custom2",
    uuid: "57853de4-4688-476f-b9f1-9af87f290c3e",
    component: "View",
    title: "Custom 2",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_custom2
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-predict/custom3",
    uuid: "e9eb0885-c139-47cf-8b33-98105a0d74c1",
    component: "View",
    title: "Custom 3",
    hide: true,
    dimensions: [
      dimensions.request_reduction_server_samples_threshold,
      dimensions.request_reduction_server_samples_custom3
    ],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_predict",
    allowedDimensionIds: requestReductionServerSamplesPredictFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/bidder",
    uuid: "7a9eddc6-f8e6-42e0-aae6-6bba29930a43",
    component: "View",
    title: "Bidder",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_bidder],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/bidder-mapped",
    component: "View",
    title: "Bidder (mapped)",
    uuid: "ceb8110a-4283-4775-af2c-e62e5c7bb5df",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_bidder_mapped],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/country",
    uuid: "245e74e0-6a31-40ca-b44a-2b97295e5c60",
    component: "View",
    title: "Country",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_country],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/publisher_id",
    uuid: "dbbffe0d-c9cd-48b3-ae3f-49f8ba394669",
    component: "View",
    title: "Publisher ID",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_publisher_id],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/placement_id",
    uuid: "e307d104-b662-465c-b148-79cae16f40a8",
    component: "View",
    title: "Placement ID",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_placement_id],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/request_type",
    uuid: "2a483b27-e6fa-474a-8a75-47385d2f7aa9",
    component: "View",
    title: "Request Type",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_request_type],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/format",
    uuid: "509511e5-8693-42c6-b66a-ca3df182411d",
    component: "View",
    title: "Format",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_format],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/sizes",
    uuid: "cc2b7465-1aa5-4fdf-92c4-ef29c069d093",
    component: "View",
    title: "Sizes",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_sizes],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/platform",
    uuid: "8056cb90-7d15-4f82-910f-153e2da15930",
    component: "View",
    title: "Platform",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_platform],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/bundle",
    uuid: "695a421d-5922-45b8-b267-e6de5ead6406",
    component: "View",
    title: "Bundle",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_bundle],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/os",
    uuid: "13d17b17-ae88-4577-9e82-364ba66bf137",
    component: "View",
    title: "Operating System",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_os],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/browser",
    uuid: "b8dbeb48-e63c-4f7d-bf2f-c850d3b61222",
    component: "View",
    title: "Browser",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_browser],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/device",
    uuid: "e1ed66ca-7d68-432d-83f0-a7b06b19890f",
    component: "View",
    title: "Device",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_device],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/codebase_version",
    uuid: "2c7b6ea7-9c02-4bd1-b4af-6b46c2b5fdd8",
    component: "View",
    title: "ML Codebase Version",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_codebase_version],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/version",
    uuid: "37b00666-0478-4f48-95ce-9ce9b0043373",
    component: "View",
    title: "Version",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_version],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/device-language",
    uuid: "53222616-223c-40b6-a760-ef56321713ca",
    component: "View",
    title: "Device Language",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_device_language],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/url",
    uuid: "f928a84e-5ff8-4dff-8099-6df98628ea23",
    component: "View",
    title: "URL",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_url],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/domain",
    uuid: "c1ca5545-e044-4a5e-bfef-051f51b6bfb6",
    component: "View",
    title: "Domain",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_domain],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/custom1",
    uuid: "1a034aa6-d7bd-4517-a573-2d28ce9180c1",
    component: "View",
    title: "Custom 1",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_custom1],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/custom2",
    uuid: "7480540b-dc01-4ec0-bd1e-0d9cb5cbcd8b",
    component: "View",
    title: "Custom 2",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_custom2],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/custom3",
    uuid: "0186bddb-80fe-430e-b9ad-73a2717fafd5",
    component: "View",
    title: "Custom 3",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_custom3],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/samples-profile/datacenter",
    uuid: "a33bb3b7-25c4-4680-83ba-b6e633510bb9",
    component: "View",
    title: "Data Center",
    hide: true,
    dimensions: [dimensions.request_reduction_server_samples_datacenter],
    hasDemoVersion: false,
    placement: "request_reduction_server_samples_profile",
    allowedDimensionIds: requestReductionServerSamplesProfileFilterIds
  },
  {
    path: "request-reduction/server/health-report",
    uuid: "ba15c603-63d5-44ca-bf39-d5348682bf07",
    component: "View",
    title: "Health Report",
    description: "Reports on the latency added by the traffic shaping module.",
    hide: true,
    dimensions: [dimensions.request_reduction_server_datacenter],
    hasDemoVersion: false,
    placement: "request_reduction_server_latency",
    allowedDimensionIds: requestReductionServerLatencyFilterIds
  },
  {
    path: "logs/error",
    uuid: "e1842e16-600d-4542-9d6d-64d5f9127bdb",
    component: "ViewErrorLog",
    title: "Error Logs",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "logs/api",
    uuid: "3b9d33a2-741e-4cf4-9057-9e7be6d880ca",
    component: "ViewApiLog",
    title: "API Logs",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "logs/spent",
    uuid: "13f0bdad-b232-455e-84b1-0d0a84dbae84",
    component: "ViewJobLog",
    title: "Spent Import Logs",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "logs/revenue",
    uuid: "504581fd-af71-4348-8311-400a73ea5b2c",
    component: "ViewJobLog",
    title: "Revenue Import logs",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    path: "logs/server_to_server",
    uuid: "06eb15cd-ef63-4427-84a6-2d8befec634f",
    component: "ViewJobLog",
    title: "S2S logs",
    placement: "__no_placement__",
    allowedDimensionIds: []
  },
  {
    component: "View",
    path: "spent/campaign",
    uuid: "5ae346ab-fb43-44d3-8348-8f4cd218b14f",
    title: "Campaign Report",
    description: "Summary of revenue with expenses, on campaign level",
    hide: true,
    isEarlyAccess: true,
    dimensions: [dimensions.buy_report_platform, dimensions.buy_report_campaign_name],
    hasDemoVersion: false,
    placement: "buy_campaign",
    allowedDimensionIds: spentCampaignFilterIds
  },
  {
    component: "View",
    path: "spent/adset",
    uuid: "8fe26fa1-b3eb-47fd-965d-340460741a3a",
    title: "Adset Report",
    description: "Summary of revenue with expenses, on adset level",
    hide: true,
    isEarlyAccess: true,
    dimensions: [
      dimensions.buy_report_platform,
      dimensions.buy_report_campaign_name,
      dimensions.buy_report_adset_name
    ],
    hasDemoVersion: false,
    placement: "buy_adset",
    allowedDimensionIds: spentAdsetFilterIds
  },
  {
    component: "View",
    path: "spent/ad",
    uuid: "575c7b72-8e78-4c7b-94bf-6af8ebc161f0",
    title: "Ad Report",
    description: "Summary of revenue with expenses, on ad level",
    hide: true,
    isEarlyAccess: true,
    dimensions: [
      dimensions.buy_report_platform,
      dimensions.buy_report_campaign_name,
      dimensions.buy_report_adset_name,
      dimensions.buy_report_ad_name
    ],
    hasDemoVersion: false,
    placement: "buy_ad",
    allowedDimensionIds: spentAdsFilterIds
  },
  {
    component: "View",
    path: "spent/publisher",
    uuid: "317a8ba6-2713-42a5-aa78-bbf3997e8b1f",
    title: "Publisher Report",
    description: "Summary of revenue with expenses, per publisher (site)",
    hide: true,
    isEarlyAccess: true,
    dimensions: [dimensions.site_report_platform, dimensions.site_report_site_name],
    hasDemoVersion: false,
    placement: "site",
    allowedDimensionIds: siteReportFilterIds
  },
  {
    component: "View",
    path: "spent/section",
    uuid: "8e93ba40-6fd3-4bb0-9a02-7048a28f4d8e",
    title: "Section Report",
    description: "Summary of revenue with expenses, per section",
    hide: true,
    isEarlyAccess: true,
    dimensions: [dimensions.spent_by_section_report_platform, dimensions.spent_by_section_report_section_name],
    hasDemoVersion: false,
    placement: "spent_by_section",
    allowedDimensionIds: spentBySectionReportFilterIds
  },
  {
    component: "View",
    path: "connected-ssps/discrepancy",
    uuid: "9e695d52-37ed-49b0-95de-a1b92c28571f",
    title: "Discrepancy Revenue",
    description: "Discrepancy Revenue between connected SSP's and Assertive Events",
    hide: true,
    isEarlyAccess: true,
    dimensions: [dimensions.discrepancy_revenue_bidder],
    hasDemoVersion: false,
    placement: "discrepancy_revenue",
    allowedDimensionIds: discrepancyRevenueReportFilterIds
  },
  {
    path: "timeSeriesForecast",
    uuid: "35f1a860-0cfe-4aae-97a5-db03b051118a",
    component: "ViewTimeSeriesForecast",
    title: "Time Series Forecast",
    placement: "time_series_forecast",
    allowedDimensionIds: yieldFilterIds,
    dimensions: []
  },
  {
    path: "debugDispersion",
    uuid: "b701133b-4c8b-4cb9-86b6-d40efc9cdc10",
    component: "ViewDispersion",
    title: "Debug Dispersion",
    placement: "view_dispersion",
    allowedDimensionIds: yieldFilterIds,
    dimensions: [],
    hide: true
  },
  {
    component: "View",
    path: "connected-ssps/financial",
    uuid: "337649ed-0c4f-4ab6-b556-1e3fb85c0894",
    title: "Financial",
    description: "Financial Report from connected SSP's",
    hide: true,
    isEarlyAccess: true,
    dimensions: [dimensions.financial_bidder],
    hasDemoVersion: false,
    placement: "financial",
    allowedDimensionIds: financialReportFilterIds
  }
]);
var isGenericReportUuid = (uuid) => uuid == "99d554ff-609f-4ca9-a168-34d31c0618be";
var GENERIC_REPORT = reports.find((it) => isGenericReportUuid(it.uuid));
var yieldReports = reports.map((it) => {
  if (isGenericReportUuid(it.uuid)) {
    return {
      ...it,
      title: "Yield Report"
    };
  }
  return it;
}).filter((report) => {
  var _a, _b, _c;
  const isPrebidReport = !!(report.isAnalyticsReport && ((_c = (_b = (_a = report == null ? void 0 : report.dimensions) == null ? void 0 : _a[0]) == null ? void 0 : _b.analytics_reports) != null ? _c : []).includes("yield")) || isGenericReportUuid(report.uuid);
  return isPrebidReport;
});
var prebidReports = reports.map((it) => {
  if (isGenericReportUuid(it.uuid)) {
    return {
      ...it,
      title: "Prebid Report"
    };
  }
  return it;
}).filter((report) => {
  var _a, _b, _c;
  const isPrebidReport = !!(report.isAnalyticsReport && ((_c = (_b = (_a = report == null ? void 0 : report.dimensions) == null ? void 0 : _a[0]) == null ? void 0 : _b.analytics_reports) != null ? _c : []).includes("prebid")) || isGenericReportUuid(report.uuid);
  return isPrebidReport;
});
var webReports = reports.map((it) => {
  if (isGenericReportUuid(it.uuid)) {
    return {
      ...it,
      title: "Web Report"
    };
  }
  return it;
}).filter((report) => {
  var _a, _b, _c;
  const isPrebidReport = !!(report.isAnalyticsReport && ((_c = (_b = (_a = report == null ? void 0 : report.dimensions) == null ? void 0 : _a[0]) == null ? void 0 : _b.analytics_reports) != null ? _c : []).includes("web")) || isGenericReportUuid(report.uuid);
  return isPrebidReport;
});

// ../core/src/constants/raw-metrics.constants.ts
var QUERY_CURRENCY_KEY = `%%QUERY_CURRENCY%%`;
var REVENUE_BIAS_MULTIPLY = `%%REVENUE_BIAS_MULTIPLY%%`;
var TABLE_NAME = `%%TABLE_NAME%%`;
var clickBounceThreshold = 1e4;
var currencyConversionFactorE = (fromCurrency) => `dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${QUERY_CURRENCY_KEY})) / dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${fromCurrency} ))`;
var currencyConversionFactorPV = (fromCurrency) => `dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${QUERY_CURRENCY_KEY})) / dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${fromCurrency} ))`;
var convertToCurrencyCreator = (currencyConversionFactor) => (fromCurrency) => `if( ${fromCurrency} = '', 1.0, ${currencyConversionFactor(fromCurrency)} )`;
var convertToCurrency = convertToCurrencyCreator(currencyConversionFactorE);
var getCurrencyFromLineItem = `dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '')`;
var convertLineItemCurrency = convertToCurrency(getCurrencyFromLineItem);
var nativoRevenueCurrencyConverted = (field) => {
  return `(${field} * ${convertToCurrency("'USD'")})`;
};
var lineItemLookup = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0), toFloat64(assumeNotNull(revenue))) / 1000`;
var lineItemLookupWithCurrency = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0) * ${convertLineItemCurrency}, toFloat64(assumeNotNull(revenue))) / 1000`;
var directRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
var directRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
var lineItemRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
var lineItemRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
var apsRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;
var apsRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;
var directRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
var directRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
var lineItemRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
var lineItemRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
var rawMetricsRequestReductionClientV2 = {
  requests: {
    field: "count()"
  },
  waste: {
    field: "countIf(didBid = 0)"
  },
  bids: {
    field: "countIf(didBid = 1)"
  },
  wins: {
    field: "countIf(rendered = 1)"
  },
  saved_waste: {
    field: "countIf(didBid = 0 AND shouldBid = 0)"
  },
  untouched_bids: {
    field: "countIf(didBid = 1 AND shouldBid = 1)"
  },
  lost_bids: {
    field: "countIf(didBid = 1 AND shouldBid = 0)"
  },
  lost_wins: {
    field: "countIf(rendered = 1 AND shouldBid = 0)"
  },
  lost_bid_volume: {
    field: "sumIf(bid, shouldBid = 0)"
  },
  bid_volume: {
    field: "sum(bid)"
  },
  lost_won_volume: {
    field: "sumIf(bid, shouldBid = 0 AND rendered = 1)"
  },
  won_volume: {
    field: "sumIf(bid, rendered = 1)"
  },
  untouched_bid_volume: {
    field: "sumIf(bid, shouldBid = 1)"
  },
  left_waste: {
    field: "countIf(didBid = 0 AND shouldBid = 1)"
  },
  auctions: {
    field: "uniq(prebid_auctionId)"
  }
};
var rawMetricsRequestReductionServerSamplesPredicted = `(predicted > __threshold)`;
var rawMetricsRequestReductionServerSamples = {
  request_reduction_server_samples_wins: {
    field: "countIf(did_win)"
  },
  request_reduction_server_samples_bids: {
    field: "countIf(did_bid)"
  },
  request_reduction_server_samples_predicts: {
    field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted})`
  },
  request_reduction_server_samples_bid_true_positive: {
    field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`
  },
  request_reduction_server_samples_bid_true_negative: {
    field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`
  },
  request_reduction_server_samples_bid_false_positive: {
    field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`
  },
  request_reduction_server_samples_bid_false_negative: {
    field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`
  },
  request_reduction_server_samples_win_true_positive: {
    field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`
  },
  request_reduction_server_samples_win_true_negative: {
    field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`
  },
  request_reduction_server_samples_win_false_positive: {
    field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`
  },
  request_reduction_server_samples_win_false_negative: {
    field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`
  },
  request_reduction_server_samples_missed_revenue: {
    field: `sumIf(cost_cpm, did_win AND NOT ${rawMetricsRequestReductionServerSamplesPredicted})`
  },
  request_reduction_server_samples_requests: {
    field: "count()"
  },
  request_reduction_server_samples_win_revenue: {
    field: "sumIf(cost_cpm, did_win)"
  },
  request_reduction_server_samples_bid_revenue: {
    field: "sumIf(predict_samples.bid_cpm, predict_samples.did_bid)"
  }
};
var rawMetricsErrorAnalytics = {
  sessions: {
    field: "uniq(assumeNotNull(userAgent), assumeNotNull(latitude), assumeNotNull(longitude))"
  },
  errors: {
    field: "count()"
  }
};
var rawMetricsWeb = {
  pageViews_ends: {
    field: "countIf(event_end = 1)"
  },
  sessions_ends: {
    field: "uniqIf(sessionId, event_end = 1)"
  },
  pageView_with_ads: {
    field: "countIf(pageView_impressionCount>0 AND event_end = 1)"
  },
  session_with_ads: {
    field: "uniqIf(sessionId, session_impressionCount>0 AND event_end = 1)"
  },
  scrollY: {
    field: "SUM(window_scrollY)"
  },
  impressions: {
    field: "SUM(pageView_impressionCount)"
  },
  errors: {
    field: "SUM(pageView_errorCount)"
  },
  sum_pageView_activeEngagementTime: {
    field: "sumIf(pageView_activeEngagementTime, event_end = 1)"
  },
  count_event_end: {
    field: "countIf(event_end = 1)"
  },
  count_low_engagement: {
    field: "countIf(pageView_activeEngagementTime < 10000 AND session_pageViewCount < 2 AND event_end = 1)"
  },
  pageViews: {
    field: "uniq(pageViewId)"
  },
  sessions: {
    field: "uniq(sessionId)"
  },
  session_starts: {
    field: "countIf(session_pageViewCount = 1)"
  },
  pageView_starts: {
    field: "countIf(pageview_isInitial = 1)"
  },
  vitals_lcp_measurable: {
    field: "countIf(vitals_lcp != -1 AND event_end = 1)"
  },
  vitals_lcp_sum: {
    field: "sumIf(vitals_lcp, vitals_lcp != -1 AND event_end = 1)/1000"
  },
  vitals_lcp_good: {
    field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp <= 2500)"
  },
  vitals_lcp_medium: {
    field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp > 2500 AND vitals_lcp <= 4000)"
  },
  vitals_lcp_bad: {
    field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp > 4000)"
  },
  vitals_fid_measurable: {
    field: "countIf(vitals_fid != -1 AND event_end = 1)"
  },
  vitals_fid_sum: {
    field: "sumIf(vitals_fid, vitals_fid != -1 AND event_end = 1)"
  },
  vitals_fid_good: {
    field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid <= 100)"
  },
  vitals_fid_medium: {
    field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid > 100 AND vitals_fid <= 300)"
  },
  vitals_fid_bad: {
    field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid > 300)"
  },
  vitals_cls_measurable: {
    field: "countIf(vitals_cls != -1 AND event_end = 1)"
  },
  vitals_cls_sum: {
    field: "sumIf(vitals_cls, vitals_cls != -1 AND event_end = 1)"
  },
  vitals_cls_good: {
    field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls <= 0.1)"
  },
  vitals_cls_medium: {
    field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls > 0.1 AND vitals_cls <= 0.25)"
  },
  vitals_cls_bad: {
    field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls > 0.25)"
  },
  vitals_inp_measurable: {
    field: "countIf(vitals_inp != -1 AND event_end = 1)"
  },
  vitals_inp_sum: {
    field: "sumIf(vitals_inp, vitals_inp != -1 AND event_end = 1)"
  },
  vitals_inp_good: {
    field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp <= 200)"
  },
  vitals_inp_medium: {
    field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp > 200 AND vitals_inp <= 500)"
  },
  vitals_inp_bad: {
    field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp > 500)"
  },
  vitals_ttfb_measurable: {
    field: "countIf(vitals_ttfb != -1 AND event_end = 1)"
  },
  vitals_ttfb_sum: {
    field: "sumIf(vitals_ttfb, vitals_ttfb != -1 AND event_end = 1)"
  },
  vitals_ttfb_good: {
    field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb <= 800)"
  },
  vitals_ttfb_medium: {
    field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb > 800 AND vitals_ttfb <= 1800)"
  },
  vitals_ttfb_bad: {
    field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb > 1800)"
  },
  vitals_fcp_measurable: {
    field: "countIf(vitals_fcp != -1 AND event_end = 1)"
  },
  vitals_fcp_sum: {
    field: "sumIf(vitals_fcp, vitals_fcp != -1 AND event_end = 1)"
  },
  vitals_fcp_good: {
    field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp <= 1800)"
  },
  vitals_fcp_medium: {
    field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp > 1800 AND vitals_fcp <= 3000)"
  },
  vitals_fcp_bad: {
    field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp > 3000)"
  },
  min_timestamp: {
    field: "toDateTime(min(timestamp), %%TIMEZONE%%)"
  },
  max_timestamp: {
    field: "toDateTime(max(timestamp), %%TIMEZONE%%)"
  },
  acquisition_cost: {
    field: `sumIf(acquisition_cost * ${convertToCurrencyCreator(currencyConversionFactorPV)("acquisition_currency")}, session_pageViewCount = 1)`,
    fieldNoCurrencyConversion: `sumIf(acquisition_cost, session_pageViewCount = 1)`
  },
  acquisition_cost_measurable_session_starts: {
    field: `countIf(pageviews.acquisition_cost > 0 AND session_pageViewCount = 1)`
  },
  nav_domain_lookup_time: {
    field: "SUM(nav_domain_lookup_time)"
  },
  nav_network_time: {
    field: "SUM(nav_network_time)"
  },
  nav_server_time: {
    field: "SUM(nav_server_time)"
  },
  nav_transfer_time: {
    field: "SUM(nav_transfer_time)"
  },
  nav_dom_processing_time: {
    field: "SUM(nav_dom_processing_time)"
  },
  nav_dom_content_loading_time: {
    field: "SUM(nav_dom_content_loading_time) * 1000"
  },
  nav_unload_event_time: {
    field: "SUM(nav_unload_event_time) * 1000"
  },
  nav_transfer_size: {
    field: "SUM(nav_transfer_size)"
  },
  nav_encoded_body_size: {
    field: "SUM(nav_encoded_body_size)"
  },
  nav_decoded_body_size: {
    field: "SUM(nav_decoded_body_size)"
  }
};
var rawMetricsPrebid = {
  prebid_report_ivt_measurable_impressions: {
    field: "countIf(ivt_category > 0)"
  },
  prebid_report_givt_impressions: {
    field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'GIVT')"
  },
  prebid_report_sivt_impressions: {
    field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'SIVT')"
  },
  prebid_report_sessions: {
    field: "uniq(sessionId)"
  },
  prebid_report_pageViews: {
    field: "uniq(pageViewId)"
  },
  prebid_report_auctions: {
    field: "uniq(prebid_auctionId)"
  },
  prebid_report_requests: {
    field: "COUNT()"
  },
  prebid_report_bids: {
    field: "countIf(didBid)"
  },
  prebid_report_noBids: {
    field: "countIf(didBid = 0 AND didTimeout = 0)"
  },
  prebid_report_wins: {
    field: "countIf(if(isNull(rendered), 0, rendered))"
  },
  prebid_report_timeouts: {
    field: "countIf(didTimeout)"
  },
  prebid_report_bid_position: {
    field: "SUM(bidPosition + 1)"
  },
  prebid_report_bids_revenue: {
    field: `SUM(CASE WHEN didBid = 1 THEN bid ${REVENUE_BIAS_MULTIPLY} ELSE 0 END)`,
    isRevenue: true,
    isPrebid: true
  },
  prebid_report_wins_revenue: {
    field: `SUM(CASE WHEN if(isNull(rendered), 0, rendered) = 1 THEN bid ${REVENUE_BIAS_MULTIPLY} ELSE 0 END)`,
    isRevenue: true,
    isPrebid: true
  },
  prebid_report_timeToRespond: {
    field: "SUM(prebid_timeToRespond)"
  },
  prebid_report_setTargetingTime: {
    field: "SUM(setTargetingTime)"
  },
  prebid_report_renderedTime: {
    field: "SUM(renderedTime)"
  },
  prebid_report_floor_sum: {
    field: "SUM(ad_floor)"
  },
  prebid_report_floor_non_null: {
    field: "countIf(isNotNull(ad_floor))"
  },
  prebid_report_highest_bid: {
    field: "countIf(didBid AND if(isNull(bidPosition), -1, bidPosition) = 0)"
  }
};
var yieldRevenue = `multiIf(source='lineitem',${lineItemLookupWithCurrency}/1000/1000, source='prebid', toFloat64(events.prebid_highestBid/1000/1000/1000), source = 'nativo', toFloat64(${nativoRevenueCurrencyConverted("events.revenue")}/1000/1000/1000/1000/1000), toFloat64(events.revenue/1000/1000/1000))`;
var rawMetricsYield = {
  blocked_impressions: {
    field: "countIf(adQuality_blockingType > 0 AND event_impression = 1)"
  },
  impressions_filled: {
    field: "countIf(ad_unfilled = 0 AND event_impression = 1)"
  },
  prebid_cached_count: {
    field: `countIf(prebid_cachedBid AND source = 'prebid' AND event_impression)`
  },
  ad_unfilled_count: {
    field: "countIf(ad_unfilled = 1 AND event_impression = 1)"
  },
  prebid_user_id_providers_sum_uses: {
    field: "sumIf(length(prebid_userIds), event_impression = 1)"
  },
  prebid_user_id_providers_zero_usages: {
    field: "countIf(length(prebid_userIds) = 0 AND event_impression = 1)"
  },
  vitals_lcp_measurable: {
    field: "countIf(vitals_lcp != -1)"
  },
  vitals_lcp_sum: {
    field: "sumIf(vitals_lcp, vitals_lcp != -1)/1000"
  },
  vitals_lcp_good: {
    field: "countIf(vitals_lcp != -1 AND vitals_lcp <= 2500)"
  },
  vitals_lcp_medium: {
    field: "countIf(vitals_lcp != -1 AND vitals_lcp > 2500 AND vitals_lcp <= 4000)"
  },
  vitals_lcp_bad: {
    field: "countIf(vitals_lcp != -1 AND vitals_lcp > 4000)"
  },
  vitals_fid_measurable: {
    field: "countIf(vitals_fid != -1)"
  },
  vitals_fid_sum: {
    field: "sumIf(vitals_fid, vitals_fid != -1)"
  },
  vitals_fid_good: {
    field: "countIf(vitals_fid != -1 AND vitals_fid <= 100)"
  },
  vitals_fid_medium: {
    field: "countIf(vitals_fid != -1 AND vitals_fid > 100 AND vitals_fid <= 300)"
  },
  vitals_fid_bad: {
    field: "countIf(vitals_fid != -1 AND vitals_fid > 300)"
  },
  vitals_cls_measurable: {
    field: "countIf(vitals_cls != -1)"
  },
  vitals_cls_sum: {
    field: "sumIf(vitals_cls, vitals_cls != -1)"
  },
  vitals_cls_good: {
    field: "countIf(vitals_cls != -1 AND vitals_cls <= 0.1)"
  },
  vitals_cls_medium: {
    field: "countIf(vitals_cls != -1 AND vitals_cls > 0.1 AND vitals_cls <= 0.25)"
  },
  vitals_cls_bad: {
    field: "countIf(vitals_cls != -1 AND vitals_cls > 0.25)"
  },
  vitals_inp_measurable: {
    field: "countIf(vitals_inp != -1)"
  },
  vitals_inp_sum: {
    field: "sumIf(vitals_inp, vitals_inp != -1)"
  },
  vitals_inp_good: {
    field: "countIf(vitals_inp != -1 AND vitals_inp <= 200)"
  },
  vitals_inp_medium: {
    field: "countIf(vitals_inp != -1 AND vitals_inp > 200 AND vitals_inp <= 500)"
  },
  vitals_inp_bad: {
    field: "countIf(vitals_inp != -1 AND vitals_inp > 500)"
  },
  vitals_ttfb_measurable: {
    field: "countIf(vitals_ttfb != -1)"
  },
  vitals_ttfb_sum: {
    field: "sumIf(vitals_ttfb, vitals_ttfb != -1)"
  },
  vitals_ttfb_good: {
    field: "countIf(vitals_ttfb != -1 AND vitals_ttfb <= 800)"
  },
  vitals_ttfb_medium: {
    field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 800 AND vitals_ttfb <= 1800)"
  },
  vitals_ttfb_bad: {
    field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 1800)"
  },
  vitals_fcp_measurable: {
    field: "countIf(vitals_fcp != -1)"
  },
  vitals_fcp_sum: {
    field: "sumIf(vitals_fcp, vitals_fcp != -1)"
  },
  vitals_fcp_good: {
    field: "countIf(vitals_fcp != -1 AND vitals_fcp <= 1800)"
  },
  vitals_fcp_medium: {
    field: "countIf(vitals_fcp != -1 AND vitals_fcp > 1800 AND vitals_fcp <= 3000)"
  },
  vitals_fcp_bad: {
    field: "countIf(vitals_fcp != -1 AND vitals_fcp > 3000)"
  },
  min_timestamp: {
    field: "toDateTime(min(timestamp), %%TIMEZONE%%)"
  },
  max_timestamp: {
    field: "toDateTime(max(timestamp), %%TIMEZONE%%)"
  },
  ivt_measurable_impressions: {
    field: "countIf(ivt_category > 0 AND event_impression)"
  },
  givt_impressions: {
    field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'GIVT' AND event_impression)"
  },
  sivt_impressions: {
    field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'SIVT' AND event_impression)"
  },
  impressions: {
    field: "countIf(event_impression)"
  },
  impressions_unfilled: {
    field: "countIf(ad_unfilled AND event_impression)"
  },
  impressions_lineitem_house: {
    field: "countIf(event_impression AND dictGetStringOrDefault('lineItem', 'lineItemType', toUInt64(dfp_lineItemId), 'Unknown') = 'HOUSE')"
  },
  pageViews: {
    field: "uniqIf(pageViewId, event_impression)"
  },
  pageViewsExact: {
    field: "uniqExact(pageViewId)",
    hideFromApi: true
  },
  sessions: {
    field: "uniqIf(sessionId, event_impression)"
  },
  sessions_with_bounced_clicks: {
    field: `uniqIf(sessionId, event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
    config: {
      clickBounceThreshold
    }
  },
  session_starts: {
    field: "countIf(session_impressionCount = 1 AND event_impression)"
  },
  pageView_starts: {
    field: "countIf(pageView_impressionCount = 1 AND event_impression)"
  },
  first_five_indicator: {
    field: "sumIf(prebid_highestBid, session_impressionCount < 6 AND event_impression)"
  },
  viewable: {
    field: "countIf(event_viewed > 0 AND viewed_measurable)"
  },
  viewable_measurable_impressions: {
    field: "countIf(event_impression AND viewed_measurable)"
  },
  clicks: {
    field: "countIf(event_clicked > 0 AND ad_unfilled = 0)"
  },
  clicksBounced: {
    field: `countIf(event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
    config: {
      clickBounceThreshold
    }
  },
  clicksReturned: {
    field: "countIf(event_clickBounced > 0)"
  },
  prebid_revenue: {
    field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression)`,
    isRevenue: true,
    isPrebid: true
  },
  prebid_won_impressions: {
    field: "countIf(event_impression AND source = 'prebid')"
  },
  prebid_won_revenue: {
    field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
    isRevenue: true,
    isPrebid: true
  },
  prebid_lost_impressions: {
    field: "countIf(event_impression AND source != 'prebid')"
  },
  prebid_lost_revenue: {
    field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source != 'prebid')`,
    isRevenue: true,
    isPrebid: true
  },
  prebid_incremental_value: {
    field: `sumIf((assumeNotNull(prebid_highestBid) - assumeNotNull(prebid_secondHighestBid)) ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
    isRevenue: true,
    isPrebid: true
  },
  nativo_revenue: {
    field: `sumIf(${nativoRevenueCurrencyConverted("revenue")} ${REVENUE_BIAS_MULTIPLY}, source = 'nativo')/1000/1000/1000`,
    fieldNoCurrencyConversion: `sumIf(revenue, source = 'nativo')/1000/1000/1000`,
    isRevenue: true,
    isNativo: true
  },
  nativo_impressions: {
    field: "countIf(event_impression AND source = 'nativo')"
  },
  outbrain_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY} * ${currencyConversionFactorE("'USD'")}, source = 'outbrain')/1000`,
    fieldNoCurrencyConversion: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'outbrain')/1000`,
    isRevenue: true,
    isOutbrain: true
  },
  outbrain_impressions: {
    field: "countIf(event_impression AND source = 'outbrain')"
  },
  taboola_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'taboola')/1000`,
    isRevenue: true,
    isTaboola: true
  },
  taboola_impressions: {
    field: "countIf(event_impression AND source = 'taboola')"
  },
  yahooGemini_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'yahooGemini')/1000`,
    isRevenue: true,
    isYahooGemini: true
  },
  yahooGemini_impressions: {
    field: "countIf(event_impression AND source = 'yahooGemini')"
  },
  aps_revenue: {
    field: apsRevenueField,
    fieldNoCurrencyConversion: apsRevenueFieldNoCurrencyConversion,
    isRevenue: true,
    isAmazonPublisherServices: true
  },
  aps_impressions: {
    field: "countIf(event_impression AND source = 'aps')"
  },
  adSense_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'adSense')/1000`,
    isRevenue: true,
    isGoogleAdsense: true
  },
  adSense_impressions: {
    field: "countIf(event_impression AND source = 'adSense')"
  },
  ayMediation_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'ayMediation_cpa')/1000`,
    isRevenue: true,
    isAyMediation: true
  },
  ayMediation_impressions: {
    field: "countIf(event_impression AND source = 'ayMediation_cpa')"
  },
  dynamicAllocation_impressions: {
    field: "countIf(event_impression AND source = 'dynamicAllocation')"
  },
  dynamicAllocation_predicted_impressions: {
    field: "countIf(event_impression AND source = 'dynamicAllocation' AND isNotNull(da_predicted))"
  },
  dynamicAllocation_revenue: {
    field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation')/ 1000`,
    isRevenue: true,
    isDynamicAllocation: true
  },
  dynamicAllocation_revenue_sum_of_squares: {
    field: `SUM(if(source = 'dynamicAllocation', if(isNotNull(revenue), pow(revenue / 1000, 2), 0), 0) ${REVENUE_BIAS_MULTIPLY})`,
    isRevenue: true,
    isDynamicAllocation: true,
    hideFromApi: true
  },
  dynamicAllocation_revenue_with_forecast: {
    field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted_server))) ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation') / 1000`,
    isRevenue: true,
    isDynamicAllocation: true
  },
  dynamicAllocation_revenue_with_forecast_client: {
    field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted) )) ${REVENUE_BIAS_MULTIPLY},source = 'dynamicAllocation') / 1000`,
    isRevenue: true,
    isDynamicAllocation: true
  },
  dynamicAllocation_predicted_revenue: {
    field: `sumIf(da_predicted ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
    isRevenue: true,
    isDynamicAllocation: true
  },
  dynamicAllocation_predicted_revenue_residual: {
    field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
    isRevenue: true,
    isDynamicAllocation: true,
    hideFromApi: true
  },
  dynamicAllocation_predicted_revenue_abs_difference: {
    field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
    isRevenue: true,
    isDynamicAllocation: true,
    hideFromApi: true
  },
  dynamicAllocation_predicted_revenue_server: {
    field: `sumIf(da_predicted_server ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
    isRevenue: true,
    isDynamicAllocation: true
  },
  dynamicAllocation_predicted_revenue_server_residual: {
    field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
    isRevenue: true,
    isDynamicAllocation: true,
    hideFromApi: true
  },
  dynamicAllocation_predicted_revenue_server_abs_difference: {
    field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
    isRevenue: true,
    isDynamicAllocation: true,
    hideFromApi: true
  },
  dynamicAllocation_confirmed_clicks: {
    field: "countIf(event_impression AND dfp_confirmedClick)"
  },
  direct_impressions: {
    field: "countIf(event_impression AND source = 'lineitem')"
  },
  direct_revenue: {
    field: directRevenueField,
    fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversion,
    isRevenue: true,
    isDirect: true
  },
  direct_impressions_legacy: {
    field: "countIf(event_impression AND (source = 'lineitem' OR source = 'aps'))",
    hideFromApi: true
  },
  direct_revenue_legacy: {
    field: directRevenueFieldLegacy,
    fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversionLegacy,
    isRevenue: true,
    isDirect: true,
    hideFromApi: true
  },
  lineItem_revenue_legacy: {
    field: lineItemRevenueFieldLegacy,
    fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversionLegacy,
    isRevenue: true,
    isDirect: true,
    hideFromApi: true
  },
  lineItem_revenue: {
    field: lineItemRevenueField,
    fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversion,
    isRevenue: true,
    isDirect: true
  },
  acquisition_cost: {
    field: `sumIf(acquisition_cost * ${convertToCurrency("acquisition_currency")}, session_impressionCount = 1 AND event_impression)`,
    fieldNoCurrencyConversion: `sumIf(acquisition_cost, session_impressionCount = 1 AND event_impression)`
  },
  acquisition_cost_measurable_session_starts: {
    field: `countIf(events.acquisition_cost > 0 AND session_impressionCount = 1 AND event_impression)`
  },
  floor: {
    field: "sumIf(ad_floor, event_impression)"
  },
  floor_empirical_revenue: {
    field: `SUM(assumeNotNull(if((toFloat64(ad_floor/1000/1000/1000) > ${yieldRevenue}) OR NOT event_impression, 0.0, least(toFloat64(ad_floor/1000/1000/1000), ${yieldRevenue}))))`
  },
  floor_measurable_impressions: {
    field: "countIf(event_impression AND isNotNull(ad_floor))"
  },
  bias: {
    hideFromApi: true
  },
  _count: {
    hideFromApi: true
  },
  prebid_lost_revenue_hb: {
    hideFromApi: true
  },
  uniqueExternalId: {
    field: "uniq(externalId)"
  }
};
var rawMetricsDiscrepancyRevenue = {
  actual_revenue: {
    field: "actual_revenue",
    isRevenue: true,
    hideFromApi: true
  },
  assertive_revenue: {
    field: "assertive_revenue",
    isRevenue: true,
    hideFromApi: true
  },
  assertive_impressions: {
    field: "assertive_impressions",
    hideFromApi: true
  },
  actual_impressions: {
    field: "actual_impressions",
    hideFromApi: true
  }
};
var rawMetricsFinancialReport = {
  actual_revenue: {
    field: "actual_revenue",
    isRevenue: true,
    hideFromApi: true
  },
  actual_impressions: {
    field: "actual_impressions",
    hideFromApi: true
  },
  actual_revenue_ssp_currency: {
    field: "actual_revenue_ssp_currency",
    isRevenue: true,
    hideFromApi: true
  }
};

// ../core/src/constants/yieldConfig.ts
var satisfiesOne = (it) => it;
var yieldConfig = satisfiesOne({
  avaliableMetrics: [...ALL_YIELD_METRICS],
  initialMetrics: [
    "impressions",
    "session_starts",
    "revenue",
    "rpm",
    "session_rpm",
    "viewability",
    "sivt_rate",
    "vitals_cls_good_rate"
  ],
  setup: {
    tabMeta: {
      overall: {
        name: "Overall",
        explanation: "Prebid Won + Dynamic Allocation + Direct + Content Rec. + AdSense + Amazon"
      },
      prebid_won: { name: "Prebid Won", explanation: "Impressions prebid won over other demand." },
      dynamicAllocation: { name: "Dynamic Allocation", explanation: "Impressions won by Dynamic Allocation." },
      amazon: { name: "Amazon", explanation: "Amazon Publisher Services demand" },
      direct: { name: "Direct", explanation: "Direct demand (winning non prebid line items)" },
      ad_sense: { name: "AdSense", explanation: "AdSense demand" },
      ay_mediation: { name: "AY Mediation", explanation: "AY Mediation demand" },
      content_recommendation: { name: "Content Rec.", explanation: "Content Recommendation demand" },
      ivt_and_vitals: { name: "IVT & Vitals", explanation: "Invalid Traffic & Web Vitals" },
      miss_clicks: { name: "Miss-Click", explanation: "Miss-clicks metrics" },
      prebid: { name: "Prebid", explanation: "Prebid Won + Prebid Lost" },
      prebid_lost: {
        name: "Prebid Lost",
        explanation: "Impressions prebid lost to other demand."
      },
      programmatic: {
        name: "Programmatic",
        explanation: "Prebid Won + Dynamic Allocation + Content Rec. + AdSense + Amazon"
      },
      other: {
        name: "Other",
        explanation: "Single metrics that are not worth their own category."
      },
      nativo: { name: "Nativo", explanation: "Nativo demand" }
    },
    columnNames: {
      general: "General",
      impression: "Impression",
      pageView: "Page View",
      session: "Session",
      miscellaneous: "Miscellaneous",
      da_predicted_client: "DA Predicted (Client)",
      da_predicted_server: "DA Predicted (Server)",
      invalid_traffic: "Invalid Traffic",
      web_vitals_avg: "Web Vitals Avg.",
      web_vitals_good: "Web Vitals Good Rate",
      combined: "Combined",
      spent: "Spent",
      other: "Other",
      yield: "Yield"
    },
    tableMapping: {
      overall: {
        general: ["revenue", "revenue_with_forecast", "clicks", "viewability", "ctr", "clr"],
        impression: [
          "impressions",
          "rpm",
          "rpm_with_forecast",
          "valueCpm",
          "impressions_unfilled",
          "impressions_unfilled_rate",
          "impressions_unfilled_n_house",
          "impressions_unfilled_n_house_rate",
          "impressions_filled",
          "impressions_filled_rate",
          "blocked_impressions",
          "blocked_impressions_rate"
        ],
        pageView: ["pageViews", "ads_per_pageView", "pageView_rpm", "pageView_valueRpm", "pageView_starts"],
        session: [
          "sessions",
          "session_starts",
          "ads_per_session",
          "session_rpm",
          "session_start_rpm",
          "session_rpm_with_forecast",
          "session_valueRpm",
          "pageViews_per_session",
          "acquisition_cost",
          "acquisition_cost_measurable_session_starts"
        ]
      },
      prebid_won: {
        general: ["prebid_winRate", "prebid_won_impressions", "prebid_won_revenue"],
        impression: ["prebid_won_rpm", "prebid_won_valueRpm"],
        pageView: ["prebid_won_pageView_rpm", "prebid_won_pageView_valueRpm"],
        session: ["prebid_won_session_rpm", "prebid_won_session_valueRpm"]
      },
      dynamicAllocation: {
        general: [
          "dynamicAllocation_impressions",
          "dynamicAllocation_revenue",
          "dynamicAllocation_revenue_with_forecast",
          "dynamicAllocation_winRate"
        ],
        impression: [
          "dynamicAllocation_rpm",
          "dynamicAllocation_valueRpm",
          "dynamicAllocation_confirmed_clicks_rate"
        ],
        pageView: ["dynamicAllocation_pageView_rpm", "dynamicAllocation_pageView_valueRpm"],
        session: [
          "dynamicAllocation_session_rpm",
          "dynamicAllocation_session_rpm_with_forecast",
          "dynamicAllocation_session_valueRpm"
        ]
      },
      amazon: {
        general: ["aps_impressions", "aps_revenue", "aps_winRate"],
        impression: ["aps_rpm"],
        pageView: ["aps_pageView_rpm"],
        session: ["aps_session_rpm"]
      },
      direct: {
        general: ["direct_impressions", "direct_revenue", "direct_winRate"],
        impression: ["direct_rpm", "direct_valueRpm"],
        pageView: ["direct_pageView_rpm", "direct_pageView_valueRpm"],
        session: ["direct_session_rpm", "direct_session_valueRpm"]
      },
      ad_sense: {
        general: ["adSense_impressions", "adSense_revenue", "adSense_winRate"],
        impression: ["adSense_rpm"],
        pageView: ["adSense_pageView_rpm"],
        session: ["adSense_session_rpm"]
      },
      ay_mediation: {
        general: ["ayMediation_impressions", "ayMediation_revenue", "ayMediation_winRate"],
        impression: ["ayMediation_rpm"],
        pageView: ["ayMediation_pageView_rpm"],
        session: ["ayMediation_session_rpm"]
      },
      content_recommendation: {
        general: [
          "content_recommendation_impressions",
          "content_recommendation_revenue",
          "content_recommendation_winRate"
        ],
        impression: ["content_recommendation_rpm"],
        pageView: ["content_recommendation_pageView_rpm"],
        session: ["content_recommendation_session_rpm"]
      },
      ivt_and_vitals: {
        invalid_traffic: ["ivt_rate", "givt_rate", "sivt_rate"],
        web_vitals_avg: ["vitals_cls", "vitals_lcp", "vitals_inp", "vitals_fid", "vitals_ttfb", "vitals_fcp"],
        web_vitals_good: [
          "vitals_cls_good_rate",
          "vitals_lcp_good_rate",
          "vitals_inp_good_rate",
          "vitals_fid_good_rate",
          "vitals_ttfb_good_rate",
          "vitals_fcp_good_rate"
        ]
      },
      miss_clicks: {
        general: ["cbr", "clicks_bounce_session_rate"],
        impression: ["miss_click_impression_pct"],
        session: ["sessions_with_bounced_clicks"]
      },
      prebid: {
        general: [
          "prebid_winRate",
          "prebid_revenue",
          "prebid_incremental_value",
          "prebid_user_id_providers_avg",
          "prebid_user_id_providers_zero_usages",
          "prebid_user_id_providers_zero_usages_rate"
        ],
        impression: ["prebid_rpm", "prebid_valueRpm", "prebid_incremental_value_rpm", "prebid_cache_rate"],
        pageView: ["prebid_pageView_rpm", "prebid_pageView_valueRpm"],
        session: ["prebid_session_rpm", "prebid_session_valueRpm"]
      },
      prebid_lost: {
        general: ["prebid_lost_impressions", "prebid_lost_revenue"],
        impression: ["prebid_lost_rpm", "prebid_lost_valueRpm"],
        pageView: ["prebid_lost_pageView_rpm", "prebid_lost_pageView_valueRpm"],
        session: ["prebid_lost_session_rpm", "prebid_lost_session_valueRpm"]
      },
      programmatic: {
        general: [
          "programmatic_impressions",
          "programmatic_revenue",
          "programmatic_revenue_with_forecast",
          "programmatic_winRate"
        ],
        impression: ["programmatic_rpm", "programmatic_valueRpm"],
        pageView: ["programmatic_pageView_rpm", "programmatic_pageView_valueRpm"],
        session: [
          "programmatic_session_rpm",
          "programmatic_session_rpm_with_forecast",
          "programmatic_session_valueRpm"
        ]
      },
      nativo: {
        general: ["nativo_impressions", "nativo_revenue", "nativo_winRate"],
        impression: ["nativo_rpm"],
        pageView: ["nativo_pageView_rpm"],
        session: ["nativo_session_rpm"]
      },
      other: {
        miscellaneous: [
          "dynamicAllocation_revenue_with_forecast_client",
          "lineItem_revenue",
          "uniqueExternalId",
          "dynamicAllocation_predicted_impressions",
          "dynamicAllocation_predicted_impressions_error",
          "floor_rpm",
          "floor_impressions",
          "floor_empirical_revenue_ratio"
        ],
        da_predicted_client: [
          "dynamicAllocation_predicted_revenue",
          "dynamicAllocation_predicted_rpm",
          "dynamicAllocation_predicted_revenue_error",
          "dynamicAllocation_predicted_revenue_r2",
          "dynamicAllocation_predicted_revenue_mae",
          "dynamicAllocation_predicted_revenue_rmse",
          "dynamicAllocation_predicted_revenue_relative_error"
        ],
        da_predicted_server: [
          "dynamicAllocation_predicted_revenue_server",
          "dynamicAllocation_predicted_rpm_server",
          "dynamicAllocation_predicted_revenue_error_server",
          "dynamicAllocation_predicted_revenue_r2_server",
          "dynamicAllocation_predicted_revenue_mae_server",
          "dynamicAllocation_predicted_revenue_rmse_server",
          "dynamicAllocation_predicted_revenue_relative_error_server"
        ]
      }
    }
  }
});

// ../core/src/constants/currencies.constants.ts
var currencies_source = [
  "TRY",
  "RWF",
  "GMD",
  "SCR",
  "BYN",
  "BMD",
  "VND",
  "CLF",
  "BIF",
  "UAH",
  "FJD",
  "BAM",
  "SVC",
  "BHD",
  "JEP",
  "KYD",
  "BZD",
  "ARS",
  "BGN",
  "AFN",
  "ZAR",
  "HUF",
  "COP",
  "CNY",
  "ILS",
  "KWD",
  "BTN",
  "NOK",
  "SOS",
  "USD",
  "PLN",
  "HRK",
  "LSL",
  "RSD",
  "JMD",
  "TMT",
  "VUV",
  "MRU",
  "PEN",
  "PAB",
  "AMD",
  "FKP",
  "MZN",
  "GEL",
  "PKR",
  "XDR",
  "XAG",
  "OMR",
  "RON",
  "LBP",
  "MOP",
  "GNF",
  "INR",
  "NPR",
  "CUC",
  "STN",
  "MMK",
  "KRW",
  "LKR",
  "SGD",
  "GHS",
  "ANG",
  "AOA",
  "GYD",
  "NIO",
  "AED",
  "ISK",
  "UZS",
  "BOB",
  "TJS",
  "CRC",
  "TTD",
  "THB",
  "XAU",
  "KGS",
  "ERN",
  "TWD",
  "NZD",
  "KES",
  "IRR",
  "KZT",
  "QAR",
  "BWP",
  "HTG",
  "TND",
  "DZD",
  "SBD",
  "BND",
  "AZN",
  "CAD",
  "IQD",
  "STD",
  "KPW",
  "MUR",
  "PYG",
  "SEK",
  "SRD",
  "BTC",
  "BBD",
  "GGP",
  "SLL",
  "BRL",
  "CUP",
  "WST",
  "CLP",
  "NAD",
  "IDR",
  "VES",
  "XPD",
  "IMP",
  "CNH",
  "MWK",
  "JOD",
  "TOP",
  "KMF",
  "XCD",
  "ALL",
  "GIP",
  "BSD",
  "HNL",
  "XAF",
  "XPF",
  "UYU",
  "MNT",
  "DOP",
  "MGA",
  "RUB",
  "ETB",
  "PHP",
  "MVR",
  "SDG",
  "CHF",
  "TZS",
  "SAR",
  "SZL",
  "EUR",
  "JPY",
  "MKD",
  "DKK",
  "ZMW",
  "BDT",
  "CDF",
  "AWG",
  "CVE",
  "MDL",
  "MXN",
  "HKD",
  "SHP",
  "LRD",
  "NGN",
  "DJF",
  "GTQ",
  "XPT",
  "PGK",
  "EGP",
  "SYP",
  "GBP",
  "YER",
  "UGX",
  "KHR",
  "XOF",
  "AUD",
  "MYR",
  "ZWL",
  "MAD",
  "SSP",
  "LYD",
  "LAK",
  "CZK"
];

// ../core/src/codecs/valid-currency-codec.codec.ts
var ALL_CURRENCIES = currencies_source;

// ../core/src/constants/timezones.constants.ts
var timezones_source = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Kyiv",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Factory",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu"
];

// ../core/src/codecs/valid-timezone.codec.ts
var ALL_TIMEZONES = [...timezones_source];

// ../core/src/constants/calculated-metrics/calculated-metrics.types-input.ts
import Topo from "@hapi/topo";
var CalculationType;
(function(CalculationType2) {
  CalculationType2["Sum"] = "Sum";
  CalculationType2["ProportionRatio"] = "ProportionRatio";
  CalculationType2["ProportionPercentage"] = "ProportionPercentage";
  CalculationType2["Mean"] = "Mean";
  CalculationType2["SpecialRate"] = "SpecialRate";
})(CalculationType || (CalculationType = {}));
var OptimizationObjective;
(function(OptimizationObjective2) {
  OptimizationObjective2["Maximize"] = "Maximize";
  OptimizationObjective2["Minimize"] = "Minimize";
  OptimizationObjective2["NoObjective"] = "NoObjective";
})(OptimizationObjective || (OptimizationObjective = {}));
var re = /\w\.(\w+)/g;
function addFormulaDependenciesAndId(ms) {
  Object.entries(ms).forEach(([id, m]) => {
    m.id = id;
  });
  Object.values(ms).forEach((m) => {
    if (m.formula) {
      const dependencies = [];
      let match;
      do {
        match = re.exec(m.formula.toString());
        if (match && match[1] && !dependencies.includes(match[1])) {
          dependencies.push(match[1]);
        }
      } while (match);
      m.formulaDependencies = dependencies;
    } else {
      m.formulaDependencies = [];
    }
  });
  Object.values(ms).forEach((m) => {
    if (m.calculationType === CalculationType.Sum) {
      m.shareOfVoiceChildren = m.formulaDependencies.filter((it) => Object.keys(ms).includes(it) && it != m.id);
    } else {
      m.shareOfVoiceChildren = [];
    }
  });
}
var sortMetricsTopological = (it) => {
  const sorter = new Topo.Sorter();
  it.forEach((metric) => {
    var _a, _b;
    sorter.add(metric, {
      after: (_b = (_a = metric.formulaDependencies) == null ? void 0 : _a.filter((dep) => dep !== metric.id)) != null ? _b : [],
      group: metric.id
    });
  });
  return sorter.nodes;
};
function addRawDependencies(ms, rawMetrics, otherMetrics = {}) {
  sortMetricsTopological(Object.values(ms)).forEach((m) => {
    if (m.formulaDependencies) {
      const rawDependencies = [];
      m.formulaDependencies.forEach((dep) => {
        if (rawMetrics[dep]) {
          rawDependencies.push(dep);
        } else if (otherMetrics[dep]) {
          const m2 = otherMetrics[dep].rawDependencies;
          if (m2 == null) {
            throw new Error(`metric ${dep} is missing rawDependencies`);
          }
          rawDependencies.push(...m2);
        } else if (ms[dep]) {
          const m2 = ms[dep].rawDependencies;
          if (m2 == null) {
            throw new Error(`metric ${dep} is missing rawDependencies`);
          }
          rawDependencies.push(...m2);
        }
      });
      m.rawDependencies = rawDependencies;
      rawDependencies.forEach((dep) => {
        const { config } = rawMetrics[dep];
        if (config) {
          m.config = { ...m.config, ...config };
        }
      });
    }
  });
}

// ../core/src/utils/ztable.ts
var ztable = {
  "-4.0": 3e-5,
  "-3.9": 5e-5,
  "-3.8": 1e-4,
  "-3.7": 1e-4,
  "-3.6": 2e-4,
  "-3.5": 2e-4,
  "-3.45": 3e-4,
  "-3.4": 3e-4,
  "-3.3": 5e-4,
  "-3.25": 6e-4,
  "-3.24": 6e-4,
  "-3.23": 6e-4,
  "-3.22": 6e-4,
  "-3.21": 7e-4,
  "-3.2": 7e-4,
  "-3.19": 7e-4,
  "-3.18": 7e-4,
  "-3.17": 8e-4,
  "-3.16": 8e-4,
  "-3.15": 8e-4,
  "-3.14": 8e-4,
  "-3.13": 9e-4,
  "-3.12": 9e-4,
  "-3.11": 9e-4,
  "-3.1": 1e-3,
  "-3.09": 1e-3,
  "-3.08": 1e-3,
  "-3.07": 11e-4,
  "-3.06": 11e-4,
  "-3.05": 11e-4,
  "-3.04": 12e-4,
  "-3.03": 12e-4,
  "-3.02": 13e-4,
  "-3.01": 13e-4,
  "-3.0": 13e-4,
  "-2.99": 14e-4,
  "-2.98": 14e-4,
  "-2.97": 15e-4,
  "-2.96": 15e-4,
  "-2.95": 16e-4,
  "-2.94": 16e-4,
  "-2.93": 17e-4,
  "-2.92": 18e-4,
  "-2.91": 18e-4,
  "-2.9": 19e-4,
  "-2.89": 19e-4,
  "-2.88": 2e-3,
  "-2.87": 21e-4,
  "-2.86": 21e-4,
  "-2.85": 22e-4,
  "-2.84": 23e-4,
  "-2.83": 23e-4,
  "-2.82": 24e-4,
  "-2.81": 25e-4,
  "-2.8": 26e-4,
  "-2.79": 26e-4,
  "-2.78": 27e-4,
  "-2.77": 28e-4,
  "-2.76": 29e-4,
  "-2.75": 3e-3,
  "-2.74": 31e-4,
  "-2.73": 32e-4,
  "-2.72": 33e-4,
  "-2.71": 34e-4,
  "-2.7": 35e-4,
  "-2.69": 36e-4,
  "-2.68": 37e-4,
  "-2.67": 38e-4,
  "-2.66": 39e-4,
  "-2.65": 4e-3,
  "-2.64": 41e-4,
  "-2.63": 43e-4,
  "-2.62": 44e-4,
  "-2.61": 45e-4,
  "-2.6": 47e-4,
  "-2.59": 48e-4,
  "-2.58": 49e-4,
  "-2.57": 51e-4,
  "-2.56": 52e-4,
  "-2.55": 54e-4,
  "-2.54": 55e-4,
  "-2.53": 57e-4,
  "-2.52": 59e-4,
  "-2.51": 6e-3,
  "-2.5": 62e-4,
  "-2.49": 64e-4,
  "-2.48": 66e-4,
  "-2.47": 68e-4,
  "-2.46": 69e-4,
  "-2.45": 71e-4,
  "-2.44": 73e-4,
  "-2.43": 75e-4,
  "-2.42": 78e-4,
  "-2.41": 8e-3,
  "-2.4": 82e-4,
  "-2.39": 84e-4,
  "-2.38": 87e-4,
  "-2.37": 89e-4,
  "-2.36": 91e-4,
  "-2.35": 94e-4,
  "-2.34": 96e-4,
  "-2.33": 99e-4,
  "-2.32": 0.0102,
  "-2.31": 0.0104,
  "-2.3": 0.0107,
  "-2.29": 0.011,
  "-2.28": 0.0113,
  "-2.27": 0.0116,
  "-2.26": 0.0119,
  "-2.25": 0.0122,
  "-2.24": 0.0125,
  "-2.23": 0.0129,
  "-2.22": 0.0132,
  "-2.21": 0.0136,
  "-2.2": 0.0139,
  "-2.19": 0.0143,
  "-2.18": 0.0146,
  "-2.17": 0.015,
  "-2.16": 0.0154,
  "-2.15": 0.0158,
  "-2.14": 0.0162,
  "-2.13": 0.0166,
  "-2.12": 0.017,
  "-2.11": 0.0174,
  "-2.1": 0.0179,
  "-2.09": 0.0183,
  "-2.08": 0.0188,
  "-2.07": 0.0192,
  "-2.06": 0.0197,
  "-2.05": 0.0202,
  "-2.04": 0.0207,
  "-2.03": 0.0212,
  "-2.02": 0.0217,
  "-2.01": 0.0222,
  "-2.0": 0.0228,
  "-1.99": 0.0233,
  "-1.98": 0.0239,
  "-1.97": 0.0244,
  "-1.96": 0.025,
  "-1.95": 0.0256,
  "-1.94": 0.0262,
  "-1.93": 0.0268,
  "-1.92": 0.0274,
  "-1.91": 0.0281,
  "-1.9": 0.0287,
  "-1.89": 0.0294,
  "-1.88": 0.0301,
  "-1.87": 0.0307,
  "-1.86": 0.0314,
  "-1.85": 0.0322,
  "-1.84": 0.0329,
  "-1.83": 0.0336,
  "-1.82": 0.0344,
  "-1.81": 0.0351,
  "-1.8": 0.0359,
  "-1.79": 0.0367,
  "-1.78": 0.0375,
  "-1.77": 0.0384,
  "-1.76": 0.0392,
  "-1.75": 0.0401,
  "-1.74": 0.0409,
  "-1.73": 0.0418,
  "-1.72": 0.0427,
  "-1.71": 0.0436,
  "-1.7": 0.0446,
  "-1.69": 0.0455,
  "-1.68": 0.0465,
  "-1.67": 0.0475,
  "-1.66": 0.0485,
  "-1.65": 0.0495,
  "-1.64": 0.0505,
  "-1.63": 0.0516,
  "-1.62": 0.0526,
  "-1.61": 0.0537,
  "-1.6": 0.0548,
  "-1.59": 0.0559,
  "-1.58": 0.0571,
  "-1.57": 0.0582,
  "-1.56": 0.0594,
  "-1.55": 0.0606,
  "-1.54": 0.0618,
  "-1.53": 0.063,
  "-1.52": 0.0643,
  "-1.51": 0.0655,
  "-1.5": 0.0668,
  "-1.49": 0.0681,
  "-1.48": 0.0694,
  "-1.47": 0.0708,
  "-1.46": 0.0721,
  "-1.45": 0.0735,
  "-1.44": 0.0749,
  "-1.43": 0.0764,
  "-1.42": 0.0778,
  "-1.41": 0.0793,
  "-1.4": 0.0808,
  "-1.39": 0.0823,
  "-1.38": 0.0838,
  "-1.37": 0.0853,
  "-1.36": 0.0869,
  "-1.35": 0.0885,
  "-1.34": 0.0901,
  "-1.33": 0.0918,
  "-1.32": 0.0934,
  "-1.31": 0.0951,
  "-1.3": 0.0968,
  "-1.29": 0.0985,
  "-1.28": 0.1003,
  "-1.27": 0.102,
  "-1.26": 0.1038,
  "-1.25": 0.1056,
  "-1.24": 0.1075,
  "-1.23": 0.1093,
  "-1.22": 0.1112,
  "-1.21": 0.1131,
  "-1.2": 0.1151,
  "-1.19": 0.117,
  "-1.18": 0.119,
  "-1.17": 0.121,
  "-1.16": 0.123,
  "-1.15": 0.1251,
  "-1.14": 0.1271,
  "-1.13": 0.1292,
  "-1.12": 0.1314,
  "-1.11": 0.1335,
  "-1.1": 0.1357,
  "-1.09": 0.1379,
  "-1.08": 0.1401,
  "-1.07": 0.1423,
  "-1.06": 0.1446,
  "-1.05": 0.1469,
  "-1.04": 0.1492,
  "-1.03": 0.1515,
  "-1.02": 0.1539,
  "-1.01": 0.1562,
  "-1.0": 0.1587,
  "-0.99": 0.1611,
  "-0.98": 0.1635,
  "-0.97": 0.166,
  "-0.96": 0.1685,
  "-0.95": 0.1711,
  "-0.94": 0.1736,
  "-0.93": 0.1762,
  "-0.92": 0.1788,
  "-0.91": 0.1814,
  "-0.9": 0.1841,
  "-0.89": 0.1867,
  "-0.88": 0.1894,
  "-0.87": 0.1922,
  "-0.86": 0.1949,
  "-0.85": 0.1977,
  "-0.84": 0.2005,
  "-0.83": 0.2033,
  "-0.82": 0.2061,
  "-0.81": 0.209,
  "-0.8": 0.2119,
  "-0.79": 0.2148,
  "-0.78": 0.2177,
  "-0.77": 0.2206,
  "-0.76": 0.2236,
  "-0.75": 0.2266,
  "-0.74": 0.2296,
  "-0.73": 0.2327,
  "-0.72": 0.2358,
  "-0.71": 0.2389,
  "-0.7": 0.242,
  "-0.69": 0.2451,
  "-0.68": 0.2483,
  "-0.67": 0.2514,
  "-0.66": 0.2546,
  "-0.65": 0.2578,
  "-0.64": 0.2611,
  "-0.63": 0.2643,
  "-0.62": 0.2676,
  "-0.61": 0.2709,
  "-0.6": 0.2743,
  "-0.59": 0.2776,
  "-0.58": 0.281,
  "-0.57": 0.2843,
  "-0.56": 0.2877,
  "-0.55": 0.2912,
  "-0.54": 0.2946,
  "-0.53": 0.2981,
  "-0.52": 0.3015,
  "-0.51": 0.305,
  "-0.5": 0.3085,
  "-0.49": 0.3121,
  "-0.48": 0.3156,
  "-0.47": 0.3192,
  "-0.46": 0.3228,
  "-0.45": 0.3264,
  "-0.44": 0.33,
  "-0.43": 0.3336,
  "-0.42": 0.3372,
  "-0.41": 0.3409,
  "-0.4": 0.3446,
  "-0.39": 0.3483,
  "-0.38": 0.352,
  "-0.37": 0.3557,
  "-0.36": 0.3594,
  "-0.35": 0.3632,
  "-0.34": 0.3669,
  "-0.33": 0.3707,
  "-0.32": 0.3745,
  "-0.31": 0.3783,
  "-0.3": 0.3821,
  "-0.29": 0.3859,
  "-0.28": 0.3897,
  "-0.27": 0.3936,
  "-0.26": 0.3974,
  "-0.25": 0.4013,
  "-0.24": 0.4052,
  "-0.23": 0.409,
  "-0.22": 0.4129,
  "-0.21": 0.4168,
  "-0.2": 0.4207,
  "-0.19": 0.4247,
  "-0.18": 0.4286,
  "-0.17": 0.4325,
  "-0.16": 0.4364,
  "-0.15": 0.4404,
  "-0.14": 0.4443,
  "-0.13": 0.4483,
  "-0.12": 0.4522,
  "-0.11": 0.4562,
  "-0.1": 0.4602,
  "-0.09": 0.4641,
  "-0.08": 0.4681,
  "-0.07": 0.4721,
  "-0.06": 0.4761,
  "-0.05": 0.4801,
  "-0.04": 0.484,
  "-0.03": 0.488,
  "-0.02": 0.492,
  "-0.01": 0.496,
  "-0.0": 0.5,
  "0.0": 0.5,
  "0.01": 0.504,
  "0.02": 0.508,
  "0.03": 0.512,
  "0.04": 0.516,
  "0.05": 0.5199,
  "0.06": 0.5239,
  "0.07": 0.5279,
  "0.08": 0.5319,
  "0.09": 0.5359,
  "0.1": 0.5398,
  "0.11": 0.5438,
  "0.12": 0.5478,
  "0.13": 0.5517,
  "0.14": 0.5557,
  "0.15": 0.5596,
  "0.16": 0.5636,
  "0.17": 0.5675,
  "0.18": 0.5714,
  "0.19": 0.5753,
  "0.2": 0.5793,
  "0.21": 0.5832,
  "0.22": 0.5871,
  "0.23": 0.591,
  "0.24": 0.5948,
  "0.25": 0.5987,
  "0.26": 0.6026,
  "0.27": 0.6064,
  "0.28": 0.6103,
  "0.29": 0.6141,
  "0.3": 0.6179,
  "0.31": 0.6217,
  "0.32": 0.6255,
  "0.33": 0.6293,
  "0.34": 0.6331,
  "0.35": 0.6368,
  "0.36": 0.6406,
  "0.37": 0.6443,
  "0.38": 0.648,
  "0.39": 0.6517,
  "0.4": 0.6554,
  "0.41": 0.6591,
  "0.42": 0.6628,
  "0.43": 0.6664,
  "0.44": 0.67,
  "0.45": 0.6736,
  "0.46": 0.6772,
  "0.47": 0.6808,
  "0.48": 0.6844,
  "0.49": 0.6879,
  "0.5": 0.6915,
  "0.51": 0.695,
  "0.52": 0.6985,
  "0.53": 0.7019,
  "0.54": 0.7054,
  "0.55": 0.7088,
  "0.56": 0.7123,
  "0.57": 0.7157,
  "0.58": 0.719,
  "0.59": 0.7224,
  "0.6": 0.7257,
  "0.61": 0.7291,
  "0.62": 0.7324,
  "0.63": 0.7357,
  "0.64": 0.7389,
  "0.65": 0.7422,
  "0.66": 0.7454,
  "0.67": 0.7486,
  "0.68": 0.7517,
  "0.69": 0.7549,
  "0.7": 0.758,
  "0.71": 0.7611,
  "0.72": 0.7642,
  "0.73": 0.7673,
  "0.74": 0.7704,
  "0.75": 0.7734,
  "0.76": 0.7764,
  "0.77": 0.7794,
  "0.78": 0.7823,
  "0.79": 0.7852,
  "0.8": 0.7881,
  "0.81": 0.791,
  "0.82": 0.7939,
  "0.83": 0.7967,
  "0.84": 0.7995,
  "0.85": 0.8023,
  "0.86": 0.8051,
  "0.87": 0.8078,
  "0.88": 0.8106,
  "0.89": 0.8133,
  "0.9": 0.8159,
  "0.91": 0.8186,
  "0.92": 0.8212,
  "0.93": 0.8238,
  "0.94": 0.8264,
  "0.95": 0.8289,
  "0.96": 0.8315,
  "0.97": 0.834,
  "0.98": 0.8365,
  "0.99": 0.8389,
  "1.0": 0.8413,
  "1.01": 0.8438,
  "1.02": 0.8461,
  "1.03": 0.8485,
  "1.04": 0.8508,
  "1.05": 0.8531,
  "1.06": 0.8554,
  "1.07": 0.8577,
  "1.08": 0.8599,
  "1.09": 0.8621,
  "1.1": 0.8643,
  "1.11": 0.8665,
  "1.12": 0.8686,
  "1.13": 0.8708,
  "1.14": 0.8729,
  "1.15": 0.8749,
  "1.16": 0.877,
  "1.17": 0.879,
  "1.18": 0.881,
  "1.19": 0.883,
  "1.2": 0.8849,
  "1.21": 0.8869,
  "1.22": 0.8888,
  "1.23": 0.8907,
  "1.24": 0.8925,
  "1.25": 0.8944,
  "1.26": 0.8962,
  "1.27": 0.898,
  "1.28": 0.8997,
  "1.29": 0.9015,
  "1.3": 0.9032,
  "1.31": 0.9049,
  "1.32": 0.9066,
  "1.33": 0.9082,
  "1.34": 0.9099,
  "1.35": 0.9115,
  "1.36": 0.9131,
  "1.37": 0.9147,
  "1.38": 0.9162,
  "1.39": 0.9177,
  "1.4": 0.9192,
  "1.41": 0.9207,
  "1.42": 0.9222,
  "1.43": 0.9236,
  "1.44": 0.9251,
  "1.45": 0.9265,
  "1.46": 0.9279,
  "1.47": 0.9292,
  "1.48": 0.9306,
  "1.49": 0.9319,
  "1.5": 0.9332,
  "1.51": 0.9345,
  "1.52": 0.9357,
  "1.53": 0.937,
  "1.54": 0.9382,
  "1.55": 0.9394,
  "1.56": 0.9406,
  "1.57": 0.9418,
  "1.58": 0.9429,
  "1.59": 0.9441,
  "1.6": 0.9452,
  "1.61": 0.9463,
  "1.62": 0.9474,
  "1.63": 0.9484,
  "1.64": 0.9495,
  "1.65": 0.9505,
  "1.66": 0.9515,
  "1.67": 0.9525,
  "1.68": 0.9535,
  "1.69": 0.9545,
  "1.7": 0.9554,
  "1.71": 0.9564,
  "1.72": 0.9573,
  "1.73": 0.9582,
  "1.74": 0.9591,
  "1.75": 0.9599,
  "1.76": 0.9608,
  "1.77": 0.9616,
  "1.78": 0.9625,
  "1.79": 0.9633,
  "1.8": 0.9641,
  "1.81": 0.9649,
  "1.82": 0.9656,
  "1.83": 0.9664,
  "1.84": 0.9671,
  "1.85": 0.9678,
  "1.86": 0.9686,
  "1.87": 0.9693,
  "1.88": 0.9699,
  "1.89": 0.9706,
  "1.9": 0.9713,
  "1.91": 0.9719,
  "1.92": 0.9726,
  "1.93": 0.9732,
  "1.94": 0.9738,
  "1.95": 0.9744,
  "1.96": 0.975,
  "1.97": 0.9756,
  "1.98": 0.9761,
  "1.99": 0.9767,
  "2.0": 0.9772,
  "2.01": 0.9778,
  "2.02": 0.9783,
  "2.03": 0.9788,
  "2.04": 0.9793,
  "2.05": 0.9798,
  "2.06": 0.9803,
  "2.07": 0.9808,
  "2.08": 0.9812,
  "2.09": 0.9817,
  "2.1": 0.9821,
  "2.11": 0.9826,
  "2.12": 0.983,
  "2.13": 0.9834,
  "2.14": 0.9838,
  "2.15": 0.9842,
  "2.16": 0.9846,
  "2.17": 0.985,
  "2.18": 0.9854,
  "2.19": 0.9857,
  "2.2": 0.9861,
  "2.21": 0.9864,
  "2.22": 0.9868,
  "2.23": 0.9871,
  "2.24": 0.9875,
  "2.25": 0.9878,
  "2.26": 0.9881,
  "2.27": 0.9884,
  "2.28": 0.9887,
  "2.29": 0.989,
  "2.3": 0.9893,
  "2.31": 0.9896,
  "2.32": 0.9898,
  "2.33": 0.9901,
  "2.34": 0.9904,
  "2.35": 0.9906,
  "2.36": 0.9909,
  "2.37": 0.9911,
  "2.38": 0.9913,
  "2.39": 0.9916,
  "2.4": 0.9918,
  "2.41": 0.992,
  "2.42": 0.9922,
  "2.43": 0.9925,
  "2.44": 0.9927,
  "2.45": 0.9929,
  "2.46": 0.9931,
  "2.47": 0.9932,
  "2.48": 0.9934,
  "2.49": 0.9936,
  "2.5": 0.9938,
  "2.51": 0.994,
  "2.52": 0.9941,
  "2.53": 0.9943,
  "2.54": 0.9945,
  "2.55": 0.9946,
  "2.56": 0.9948,
  "2.57": 0.9949,
  "2.58": 0.9951,
  "2.59": 0.9952,
  "2.6": 0.9953,
  "2.61": 0.9955,
  "2.62": 0.9956,
  "2.63": 0.9957,
  "2.64": 0.9959,
  "2.65": 0.996,
  "2.66": 0.9961,
  "2.67": 0.9962,
  "2.68": 0.9963,
  "2.69": 0.9964,
  "2.7": 0.9965,
  "2.71": 0.9966,
  "2.72": 0.9967,
  "2.73": 0.9968,
  "2.74": 0.9969,
  "2.75": 0.997,
  "2.76": 0.9971,
  "2.77": 0.9972,
  "2.78": 0.9973,
  "2.79": 0.9974,
  "2.8": 0.9974,
  "2.81": 0.9975,
  "2.82": 0.9976,
  "2.83": 0.9977,
  "2.84": 0.9977,
  "2.85": 0.9978,
  "2.86": 0.9979,
  "2.87": 0.9979,
  "2.88": 0.998,
  "2.89": 0.9981,
  "2.9": 0.9981,
  "2.91": 0.9982,
  "2.92": 0.9982,
  "2.93": 0.9983,
  "2.94": 0.9984,
  "2.95": 0.9984,
  "2.96": 0.9985,
  "2.97": 0.9985,
  "2.98": 0.9986,
  "2.99": 0.9986,
  "3.0": 0.9987,
  "3.01": 0.9987,
  "3.02": 0.9987,
  "3.03": 0.9988,
  "3.04": 0.9988,
  "3.05": 0.9989,
  "3.06": 0.9989,
  "3.07": 0.9989,
  "3.08": 0.999,
  "3.09": 0.999,
  "3.1": 0.999,
  "3.11": 0.9991,
  "3.12": 0.9991,
  "3.13": 0.9991,
  "3.14": 0.9992,
  "3.15": 0.9992,
  "3.16": 0.9992,
  "3.17": 0.9992,
  "3.18": 0.9993,
  "3.19": 0.9993,
  "3.2": 0.9993,
  "3.21": 0.9993,
  "3.22": 0.9994,
  "3.23": 0.9994,
  "3.24": 0.9994,
  "3.25": 0.9994,
  "3.3": 0.9995,
  "3.4": 0.9997,
  "3.45": 0.9997,
  "3.5": 0.9998,
  "3.6": 0.9998,
  "3.7": 0.9999,
  "3.8": 0.9999,
  "3.9": 0.99995,
  "4.0": 0.99997
};
var ztableEntries = Object.entries(ztable);
var zScoreToUnitRatio = (number) => {
  const n = Number(number);
  const twoDecimalPrecision = n.toFixed(2);
  const oneDecimalPrecision = n.toFixed(1);
  const twoDec = ztable[twoDecimalPrecision];
  if (twoDec != null) {
    return twoDec;
  }
  const oneDec = ztable[oneDecimalPrecision];
  if (oneDec != null) {
    return oneDec;
  }
  if (Math.abs(n) < 0.01) {
    return 0.5;
  }
  if (n > 0) {
    return 1;
  }
  if (n < 0) {
    return 0;
  }
  return 0;
};

// ../core/src/constants/calculated-metrics/data/calculated-metrics.types.ts
var WebMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsWeb);
  return args;
};
var ErrorAnalyticsMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsErrorAnalytics);
  return args;
};
var YieldMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield);
  return args;
};
var PrebidMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsPrebid);
  return args;
};
var IndustryMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield);
  return args;
};
var AdRevenueMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield);
  return args;
};
var RequestReductionServerSamplesPredictMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsRequestReductionServerSamples);
  return args;
};
var RequestReductionServerSamplesProfileMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsRequestReductionServerSamples);
  return args;
};
var RequestReductionServerLatencyMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield);
  return args;
};
var RequestReductionClientV2MetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsRequestReductionClientV2);
  return args;
};
var DiscrepancyRevenueMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsDiscrepancyRevenue);
  return args;
};
var FinancialMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsFinancialReport);
  return args;
};

// ../core/src/constants/calculated-metrics/data/by-placement/ad_revenue.ts
var calculatedAdRevenueMetrics = AdRevenueMetricMap({
  ad_revenue_index_percentage: {
    label: "Score",
    explanation: "Following a normal distribution, n% of samples would perform worse than this group.",
    formula: (m, opname) => {
      if (opname === "total") {
        return 50;
      }
      let zScore = m.weighed_stouffer / m.impressions;
      if (opname === "groupTotal") {
        const correctionForUndersampling = 3 / Math.pow(m.agg_count, 1.5);
        zScore = zScore - correctionForUndersampling;
      }
      return zScoreToUnitRatio(zScore) * 100;
    },
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  ad_revenue_index_impressions: {
    label: "Impressions",
    explanation: "This item impressions / Total impressions",
    formula: (m, opName, total, totalByDate) => {
      if (opName === "groupTotal") {
        return m.impressions / total.impressions;
      }
      if (opName === "total") {
        return 1;
      }
      if (opName === "groupByDate" || opName === "byDate") {
        if (totalByDate != null) {
          return m.impressions / totalByDate.impressions;
        }
      }
      return 1;
    },
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/utils/iss.ts
var manualISSStats = {
  prebid_user_id_providers_avg: {
    avg: 1,
    std: 1
  },
  prebid_report_floor_cpm: {
    avg: 1,
    std: 1
  },
  nativo_revenue_per_nativo_impression: {
    avg: 0.003791637823054129,
    std: 0.0018507854928320896
  },
  nativo_revenue_per_nativo_pageView: {
    avg: 0.005105854088481795,
    std: 0.003453066230381102
  },
  nativo_revenue_per_nativo_session: {
    avg: 0.03172814008085117,
    std: 0.07015245420866856
  },
  adSense_revenue_per_adSense_impression: {
    avg: 7363612407539085e-9,
    std: 14419654806717891e-9
  },
  adSense_revenue_per_adSense_pageView: {
    avg: 7363867721005774e-9,
    std: 14423591098343825e-9
  },
  adSense_revenue_per_adSense_session: {
    avg: 7918469917269774e-8,
    std: 1849794665136247e-7
  },
  ayMediation_revenue_per_ayMediation_impression: {
    avg: 7363612407539085e-9,
    std: 14419654806717891e-9
  },
  ayMediation_revenue_per_ayMediation_pageView: {
    avg: 7363867721005774e-9,
    std: 14423591098343825e-9
  },
  ayMediation_revenue_per_ayMediation_session: {
    avg: 7918469917269774e-8,
    std: 1849794665136247e-7
  },
  aps_revenue_per_aps_impression: {
    avg: 7363612407539085e-9,
    std: 14419654806717891e-9
  },
  aps_revenue_per_aps_pageView: {
    avg: 7363867721005774e-9,
    std: 14423591098343825e-9
  },
  aps_revenue_per_aps_session: {
    avg: 7918469917269774e-8,
    std: 1849794665136247e-7
  },
  buy_spent_per_click: {
    avg: 45.16352999471093,
    std: 41.594045909922855
  },
  buy_spent_per_new_sessions: {
    avg: 1.585648035412046,
    std: 3.9612475872182578
  },
  buy_spent_per_impressions: {
    avg: 0.06072201200732988,
    std: 0.1494630841752645
  },
  buy_revenue_per_click: {
    avg: 2296.953043745495,
    std: 4197.39001254295
  },
  buy_ffi_per_session_starts: {
    avg: 3344480642256703e-9,
    std: 5805145083752478e-9
  },
  site_spent_per_click: {
    avg: 165.18179166049933,
    std: 66.61186389057558
  },
  site_spent_per_new_sessions: {
    avg: 0.3564414747478823,
    std: 1.5368843930975082
  },
  site_spent_per_impressions: {
    avg: 0.051898581894765185,
    std: 0.273960546764922
  },
  site_revenue_per_click: {
    avg: 562313.8077382864,
    std: 4703959122725625e-9
  },
  site_ffi_per_session_starts: {
    avg: 3.3148875406080913e6,
    std: 3280224354853315e-9
  },
  spent_by_section_spent_per_click: {
    avg: 186.76316164409081,
    std: 82.59047210820155
  },
  spent_by_section_spent_per_new_sessions: {
    avg: 0.1432360596828065,
    std: 0.14448855280821918
  },
  spent_by_section_spent_per_impressions: {
    avg: 0.021144445598799144,
    std: 0.04116071377631576
  },
  spent_by_section_revenue_per_click: {
    avg: 6394502608475168e-8,
    std: 11441405319950964e-7
  },
  spent_by_section_ffi_per_session_starts: {
    avg: 3803335885432714e-9,
    std: 4985504357906782e-9
  },
  mock_web_avg: {
    avg: 1,
    std: 1
  }
};

// ../core/src/constants/calculated-metrics/data/shared/web-vitals.ts
var satisfies = (it) => it;
var WebVitalsMetrics = satisfies({
  vitals_lcp: {
    label: "WV Avg. LCP",
    explanation: "Avg. Largest Contentful Paint, in seconds. Pages should have a LCP of 2.5 seconds or less.",
    formula: (m) => m.vitals_lcp_sum / m.vitals_lcp_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_lcp_good_rate: {
    label: "WV LCP Good",
    explanation: "Rate of Largest Contentful Paint which were 2.5 seconds or less.",
    formula: (m) => m.vitals_lcp_good / m.vitals_lcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_lcp_medium_rate: {
    label: "WV LCP Medium",
    explanation: "Rate of Largest Contentful Paint which were between 2.5 seconds and 4 seconds.",
    formula: (m) => m.vitals_lcp_medium / m.vitals_lcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_lcp_bad_rate: {
    label: "WV LCP Bad",
    explanation: "Rate of Largest Contentful Paint which were 4 seconds or more.",
    formula: (m) => m.vitals_lcp_bad / m.vitals_lcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_fid: {
    label: "WV Avg. FID",
    explanation: "Avg. First Input Delay, in milliseconds. Pages should have a FID of 100 milliseconds or less",
    formula: (m) => m.vitals_fid_sum / m.vitals_fid_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_fid_good_rate: {
    label: "WV FID Good",
    explanation: "Rate of First Input Delay which were 100 milliseconds or less",
    formula: (m) => m.vitals_fid_good / m.vitals_fid_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_fid_medium_rate: {
    label: "WV FID Medium",
    explanation: "Rate of First Input Delay which were between 100 milliseconds and 300 milliseconds",
    formula: (m) => m.vitals_fid_medium / m.vitals_fid_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_fid_bad_rate: {
    label: "WV FID Bad",
    explanation: "Rate of First Input Delay which were 300 milliseconds or more",
    formula: (m) => m.vitals_fid_bad / m.vitals_fid_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_cls: {
    label: "WV Avg. CLS",
    explanation: "Avg. Cumulative Layout Shift. Pages should have a CLS of 0.1 or less.",
    formula: (m) => m.vitals_cls_sum / m.vitals_cls_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_cls_good_rate: {
    label: "WV CLS Good",
    explanation: "Rate of Cumulative Layout Shift which were 0.1 or less",
    formula: (m) => m.vitals_cls_good / m.vitals_cls_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_cls_medium_rate: {
    label: "WV CLS Medium",
    explanation: "Rate of Cumulative Layout Shift which were between 0.1 and 0.25",
    formula: (m) => m.vitals_cls_medium / m.vitals_cls_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_cls_bad_rate: {
    label: "WV CLS Bad",
    explanation: "Rate of Cumulative Layout Shift which were 0.25 or more",
    formula: (m) => m.vitals_cls_bad / m.vitals_cls_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_inp: {
    label: "WV Avg. INP",
    explanation: "Avg. Interaction to Next Paint. Pages should have a INP of 200 milliseconds or less.",
    formula: (m) => m.vitals_inp_sum / m.vitals_inp_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_inp_good_rate: {
    label: "WV INP Good",
    explanation: "Rate of Interaction to Next Paint which were 200 milliseconds or less",
    formula: (m) => m.vitals_inp_good / m.vitals_inp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_inp_medium_rate: {
    label: "WV INP Medium",
    explanation: "Rate of Interaction to Next Paint which were between 200 milliseconds and 500 milliseconds",
    formula: (m) => m.vitals_inp_medium / m.vitals_inp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_inp_bad_rate: {
    label: "WV INP Bad",
    explanation: "Rate of Interaction to Next Paint which were 500 milliseconds or more",
    formula: (m) => m.vitals_inp_bad / m.vitals_inp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_ttfb: {
    label: "WV Avg. TTFB",
    explanation: "Avg. Time to First Byte. Pages should have a TTFB of 800 milliseconds or less.",
    formula: (m) => m.vitals_ttfb_sum / m.vitals_ttfb_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_ttfb_good_rate: {
    label: "WV TTFB Good",
    explanation: "Rate of Time to First Byte which were 800 milliseconds or less",
    formula: (m) => m.vitals_ttfb_good / m.vitals_ttfb_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_ttfb_medium_rate: {
    label: "WV TTFB Medium",
    explanation: "Rate of Time to First Byte which were between 800 milliseconds and 1800 milliseconds",
    formula: (m) => m.vitals_ttfb_medium / m.vitals_ttfb_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_ttfb_bad_rate: {
    label: "WV TTFB Bad",
    explanation: "Rate of Time to First Byte which were 1800 milliseconds or more",
    formula: (m) => m.vitals_ttfb_bad / m.vitals_ttfb_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_fcp: {
    label: "WV Avg. FCP",
    explanation: "Avg. First Contentful Paint. Pages should have a FCP of 1800 milliseconds seconds or less.",
    formula: (m) => m.vitals_fcp_sum / m.vitals_fcp_measurable,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  vitals_fcp_good_rate: {
    label: "WV FCP Good",
    explanation: "Rate of First Contentful Paint which were 1800 milliseconds or less",
    formula: (m) => m.vitals_fcp_good / m.vitals_fcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  vitals_fcp_medium_rate: {
    label: "WV FCP Medium",
    explanation: "Rate of First Contentful Paint which were between 1800 milliseconds and 3000 milliseconds",
    formula: (m) => m.vitals_fcp_medium / m.vitals_fcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  vitals_fcp_bad_rate: {
    label: "WV FCP Bad",
    explanation: "Rate of First Contentful Paint which were 3000 milliseconds or more",
    formula: (m) => m.vitals_fcp_bad / m.vitals_fcp_measurable,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/yield.ts
var dayInMinutes = 60 * 24;
var sessionTimeSpan = 30;
var calculatedYieldMetrics = YieldMetricMap({
  ad_unfilled: {
    label: "Ad Unfilled",
    explanation: "Count of unfilled ads",
    formula: (m) => m.ad_unfilled_count,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  impressions: {
    label: "Impressions",
    explanation: "Rendered Impressions (including unfilled if explicitly enabled)",
    formula: (m) => m.impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  blocked_impressions: {
    label: "Blocked Impressions",
    explanation: "Count of blocked impressions",
    formula: (m) => m.blocked_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  blocked_impressions_rate: {
    label: "Blocked Impressions Rate",
    explanation: "Rate of blocked impressions",
    formula: (m) => m.blocked_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    timeSpan: dayInMinutes,
    objective: OptimizationObjective.Minimize
  },
  impressions_filled: {
    label: "Filled Impressions",
    explanation: "Filled Impressions (only tracked if enabled)",
    formula: (m) => m.impressions_filled,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  impressions_filled_rate: {
    label: "Filled Impression Rate",
    explanation: "Filled Impression Rate (only tracked if enabled)",
    formula: (m) => m.impressions_filled / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  impressions_unfilled: {
    label: "Unfilled Impressions",
    explanation: "Unfilled Impressions (only tracked if enabled)",
    formula: (m) => m.impressions_unfilled,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  impressions_unfilled_rate: {
    label: "Unfilled Impression Rate",
    explanation: "Unfilled Impression Rate (only tracked if enabled)",
    formula: (m) => m.impressions_unfilled / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  impressions_unfilled_n_house: {
    label: "Unfilled & House Impressions",
    explanation: "Unfilled & House Impressions (only tracked if enabled)",
    formula: (m) => m.impressions_lineitem_house + m.impressions_unfilled,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  impressions_unfilled_n_house_rate: {
    label: "Unfilled & House Impression Rate",
    explanation: "Unfilled & House Impression Rate (only tracked if enabled)",
    formula: (m) => m.impressions_unfilled_n_house / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  pageViews: {
    label: "PageViews",
    explanation: "Page views with rendered impressions",
    formula: (m) => m.pageViews,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  pageViewsExact: {
    label: "PageViews (Exact)",
    explanation: "Page views (exact) with rendered impressions",
    formula: (m) => m.pageViewsExact,
    calculationType: CalculationType.Sum,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  sessions: {
    label: "Unique Sessions",
    explanation: "Unique sessions with rendered impressions",
    formula: (m) => m.sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  session_starts: {
    label: "New Sessions",
    explanation: "New Session with rendered impressions",
    formula: (m) => m.session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  pageView_starts: {
    label: "New Page Views",
    explanation: "New Page Views with rendered impressions",
    formula: (m) => m.pageView_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  ads_per_pageView: {
    label: "Ads per PageView",
    explanation: "Average amount of ads per page view with rendered impressions.",
    formula: (m) => m.impressions / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  ads_per_pageViewExact: {
    label: "Ads per PageView (exact)",
    explanation: "Average amount of ads per page view (exact) with rendered impressions.",
    formula: (m) => m.impressions / m.pageViewsExact,
    calculationType: CalculationType.Mean,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  ads_per_session: {
    label: "Ads per Session",
    explanation: "Average amount of ads per session with rendered impressions.",
    formula: (m) => m.impressions / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  revenue: {
    label: "Revenue",
    explanation: "Revenue generated from all tracked sources.",
    formula: (m) => m.prebid_won_revenue + m.dynamicAllocation_revenue + m.direct_revenue + m.content_recommendation_revenue + m.adSense_revenue + m.aps_revenue + m.nativo_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  revenue_with_forecast: {
    label: "Revenue with DA Forecast",
    explanation: "Revenue generated from all tracked sources incl. DA forecast.",
    formula: (m) => m.prebid_won_revenue + m.dynamicAllocation_revenue_with_forecast + m.direct_revenue + m.content_recommendation_revenue + m.adSense_revenue + m.aps_revenue + m.nativo_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  clicks: {
    label: "Clicks",
    explanation: "Clicks",
    formula: (m) => m.clicks,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  rpm: {
    label: "RPM",
    explanation: "Revenue per mille (thousand) impressions.",
    formula: (m) => m.revenue / m.impressions * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  rpm_with_forecast: {
    label: "RPM with DA Forecast",
    explanation: "Revenue with Prediction per mille (thousand) impressions with DA forecast.",
    formula: (m) => m.revenue_with_forecast / m.impressions * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  valueCpm: {
    label: "Value-RPM",
    explanation: "RPM multiplied by viewability.",
    formula: (m) => m.rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  pageView_rpm: {
    label: "PageView RPM",
    explanation: "Revenue per mille (thousand) page views",
    formula: (m) => m.revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  pageView_valueRpm: {
    label: "PageView Value-RPM",
    explanation: "PageView RPM multiplied by viewability.",
    formula: (m) => m.pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  session_rpm: {
    label: "Session RPM",
    explanation: "Revenue per mille (thousand) sessions",
    formula: (m) => m.revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  session_start_rpm: {
    label: "New Session RPM",
    explanation: "Revenue per mille (thousand) new sessions",
    formula: (m) => m.revenue / m.session_starts * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  session_rpm_with_forecast: {
    label: "Session RPM with DA Forecast",
    explanation: "Revenue per mille (thousand) sessions incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  session_valueRpm: {
    label: "Session Value-RPM",
    explanation: "Session RPM multiplied by viewability.",
    formula: (m) => m.session_rpm * m.viewability,
    timeSpan: sessionTimeSpan,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  pageViews_per_session: {
    label: "PageViews per Session",
    explanation: "Average amount of monetized PageViews per Session",
    formula: (m) => m.pageViews / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  pageViewsExact_per_session: {
    label: "PageViews (exact) per Session",
    explanation: "Average amount of monetized PageViews (exact) per Session",
    formula: (m) => m.pageViewsExact / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  viewability: {
    label: "Viewability",
    explanation: "Proportion of impressions in view according to MRC standards.",
    formula: (m) => m.viewable / m.viewable_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  ctr: {
    label: "CTR %",
    explanation: "CTR in percent",
    formula: (m) => m.clicks / m.impressions_filled * 100,
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Maximize
  },
  miss_click_impression_pct: {
    label: "Miss-Click Impression %",
    explanation: "Rate of miss-clicks by impression, in percent",
    formula: (m) => m.clicksBounced / m.impressions * 100,
    calculationType: CalculationType.ProportionPercentage,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  cbr: {
    label: "Miss-Click Rate",
    explanation: "Miss clicks divided by total clicks.",
    formula: (m) => m.clicksBounced / m.clicks,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  clicks_bounce_session_rate: {
    label: "Miss-Click Session Rate",
    explanation: "Sessions with miss clicks divided by total sessions.",
    formula: (m) => m.sessions_with_bounced_clicks / m.sessions,
    calculationType: CalculationType.ProportionRatio,
    timeSpan: sessionTimeSpan,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  sessions_with_bounced_clicks: {
    label: "Sessions with Miss-Clicks",
    explanation: "Number of sessions that had at least one miss-click.",
    formula: (m) => m.sessions_with_bounced_clicks,
    timeSpan: sessionTimeSpan,
    calculationType: CalculationType.Sum,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  clr: {
    label: "Click-Leave Rate",
    explanation: "The share of users not returning to the site after an ad click.",
    formula: (m) => (m.clicks - m.clicksReturned) / m.clicks,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  ivt_rate: {
    label: "IVT Rate",
    explanation: "Rate of invalid traffic over entire traffic",
    formula: (m) => (m.givt_impressions + m.sivt_impressions) / m.ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  givt_rate: {
    label: "GIVT Rate",
    explanation: "Rate of general invalid traffic over entire traffic",
    formula: (m) => m.givt_impressions / m.ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  sivt_rate: {
    label: "SIVT Rate",
    explanation: "Rate of sophisticated invalid traffic over entire traffic",
    formula: (m) => m.sivt_impressions / m.ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  ...WebVitalsMetrics,
  direct_impressions: {
    label: "D Impressions",
    explanation: "Impressions won by direct demand.",
    formula: (m) => m.direct_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  direct_revenue: {
    label: "D Revenue",
    explanation: "Revenue from non prebid and dynamic allocation line items.",
    formula: (m) => m.direct_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  direct_rpm: {
    label: "D RPM",
    explanation: "Direct revenue per mille (thousand) impressions.",
    formula: (m) => m.direct_revenue / m.direct_impressions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  direct_valueRpm: {
    label: "D Value-RPM",
    explanation: "Direct RPM multiplied by viewability.",
    formula: (m) => m.direct_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  direct_winRate: {
    label: "D Win Rate",
    explanation: "Win Rate of Direct over other demand.",
    formula: (m) => m.direct_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  direct_pageView_rpm: {
    label: "D PageView RPM",
    explanation: "Direct revenue per mille (thousand) page views",
    formula: (m) => m.direct_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  direct_pageView_valueRpm: {
    label: "D PageView Value-RPM",
    explanation: "Direct PageView RPM multiplied by viewability.",
    formula: (m) => m.direct_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  direct_session_rpm: {
    label: "D Session RPM",
    explanation: "Direct revenue per mille (thousand) sessions",
    formula: (m) => m.direct_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  direct_session_valueRpm: {
    label: "D Session Value-RPM",
    explanation: "Direct Session RPM multiplied by viewability.",
    formula: (m) => m.direct_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  programmatic_impressions: {
    label: "P Impressions",
    explanation: "Impressions won by Programmatic Demand.",
    formula: (m) => m.prebid_won_impressions + m.dynamicAllocation_impressions + m.adSense_impressions + m.aps_impressions + m.content_recommendation_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  programmatic_revenue: {
    label: "P Revenue",
    explanation: "Programmatic revenue.",
    formula: (m) => m.prebid_won_revenue + m.dynamicAllocation_revenue + m.adSense_revenue + m.aps_revenue + m.content_recommendation_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_revenue_with_forecast: {
    label: "P Revenue with DA Forecast",
    explanation: "Programmatic Revenue generated from all tracked sources incl. DA forecast.",
    formula: (m) => m.prebid_won_revenue + m.dynamicAllocation_revenue_with_forecast + m.adSense_revenue + m.aps_revenue + m.content_recommendation_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_rpm: {
    label: "P RPM",
    explanation: "Programmatic revenue per mille (thousand) impressions.",
    formula: (m) => m.programmatic_revenue / m.programmatic_impressions * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_valueRpm: {
    label: "P Value-RPM",
    explanation: "Programmatic RPM multiplied by viewability.",
    formula: (m) => m.programmatic_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_winRate: {
    label: "P Win Rate",
    explanation: "Win Rate of Programmatic over other demand.",
    formula: (m) => m.programmatic_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  programmatic_pageView_rpm: {
    label: "P PageView RPM",
    explanation: "Programmatic revenue per mille (thousand) page views",
    formula: (m) => m.programmatic_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_pageView_valueRpm: {
    label: "P PageView Value-RPM",
    explanation: "Programmatic PageView RPM multiplied by viewability.",
    formula: (m) => m.programmatic_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_session_rpm: {
    label: "P Session RPM",
    explanation: "Programmatic revenue per mille (thousand) sessions",
    formula: (m) => m.programmatic_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_session_rpm_with_forecast: {
    label: "P Session RPM with Forecast",
    explanation: "Programmatic revenue with forecast per mille (thousand) sessions",
    formula: (m) => m.programmatic_revenue_with_forecast / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  programmatic_session_valueRpm: {
    label: "P Session Value-RPM",
    explanation: "Programmatic Session RPM multiplied by viewability.",
    formula: (m) => m.programmatic_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  nativo_impressions: {
    label: "NTV Impressions",
    explanation: "Impressions won by Nativo.",
    formula: (m) => m.nativo_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  nativo_rpm: {
    label: "NTV RPM",
    explanation: "RPM from impressions won by Nativo.",
    formula: (m) => m.nativo_revenue / m.nativo_impressions * 1e3,
    issStats: manualISSStats.nativo_revenue_per_nativo_impression,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  nativo_revenue: {
    label: "NTV Revenue",
    explanation: "Nativo Revenue",
    formula: (m) => m.nativo_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  nativo_pageView_rpm: {
    label: "NTV PageView RPM",
    explanation: "Nativo PageView RPM from impressions won by Nativo.",
    formula: (m) => m.nativo_revenue / m.pageViews * 1e3,
    issStats: manualISSStats.nativo_revenue_per_nativo_pageView,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  nativo_session_rpm: {
    label: "NTV Session RPM",
    explanation: "Session RPM from impressions won by Nativo.",
    formula: (m) => m.nativo_revenue / m.sessions * 1e3,
    issStats: manualISSStats.nativo_revenue_per_nativo_session,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  nativo_winRate: {
    label: "NTV Win Rate",
    explanation: "Win Rate of Nativo over other demand.",
    formula: (m) => m.nativo_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  adSense_impressions: {
    label: "AdS Impressions",
    explanation: "Impressions won by AdSense.",
    formula: (m) => m.adSense_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  adSense_rpm: {
    label: "AdS RPM",
    explanation: "RPM from impressions won by AdSense.",
    formula: (m) => m.adSense_revenue / m.adSense_impressions * 1e3,
    issStats: manualISSStats.adSense_revenue_per_adSense_impression,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  adSense_revenue: {
    label: "AdS Revenue",
    explanation: "AdSense Revenue",
    formula: (m) => m.adSense_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  adSense_pageView_rpm: {
    label: "AdS PageView RPM",
    explanation: "AdSense PageView RPM from impressions won by AdSense.",
    formula: (m) => m.adSense_revenue / m.pageViews * 1e3,
    issStats: manualISSStats.adSense_revenue_per_adSense_pageView,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  adSense_session_rpm: {
    label: "AdS Session RPM",
    explanation: "Session RPM from impressions won by AdSense.",
    formula: (m) => m.adSense_revenue / m.sessions * 1e3,
    issStats: manualISSStats.adSense_revenue_per_adSense_session,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  adSense_winRate: {
    label: "AdS Win Rate",
    explanation: "Win Rate of AdSense over other demand.",
    formula: (m) => m.adSense_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_impressions: {
    label: "AYM Impressions",
    explanation: "Impressions won by AY Mediation.",
    formula: (m) => m.ayMediation_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_rpm: {
    label: "AYM RPM",
    explanation: "RPM from impressions won by AY Mediation.",
    formula: (m) => m.ayMediation_revenue / m.ayMediation_impressions * 1e3,
    issStats: manualISSStats.ayMediation_revenue_per_ayMediation_impression,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_revenue: {
    label: "AYM Revenue",
    explanation: "AY Mediation Revenue",
    formula: (m) => m.ayMediation_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_pageView_rpm: {
    label: "AYM PageView RPM",
    explanation: "AY Mediation. PageView RPM from impressions won by AY Mediation.",
    formula: (m) => m.ayMediation_revenue / m.pageViews * 1e3,
    issStats: manualISSStats.ayMediation_revenue_per_ayMediation_pageView,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_session_rpm: {
    label: "AYM Session RPM",
    explanation: "Session RPM from impressions won by AY Mediation.",
    formula: (m) => m.ayMediation_revenue / m.sessions * 1e3,
    issStats: manualISSStats.ayMediation_revenue_per_ayMediation_session,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  ayMediation_winRate: {
    label: "AYM Win Rate",
    explanation: "Win Rate of AY Mediation. over other demand.",
    formula: (m) => m.ayMediation_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  aps_impressions: {
    label: "APS Impressions",
    explanation: "Impressions won by Amazon Publisher Services.",
    formula: (m) => m.aps_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  aps_rpm: {
    label: "APS RPM",
    explanation: "RPM from impressions won by Amazon Publisher Services.",
    formula: (m) => m.aps_revenue / m.aps_impressions * 1e3,
    issStats: manualISSStats.aps_revenue_per_aps_impression,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  aps_revenue: {
    label: "APS Revenue",
    explanation: "Amazon Publisher Services Revenue",
    formula: (m) => m.aps_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  aps_pageView_rpm: {
    label: "APS PageView RPM",
    explanation: "PageView RPM from impressions won by Amazon Publisher Services.",
    formula: (m) => m.aps_revenue / m.pageViews * 1e3,
    issStats: manualISSStats.aps_revenue_per_aps_pageView,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  aps_session_rpm: {
    label: "APS Session RPM",
    explanation: "Session RPM from impressions won by Amazon Publisher Services.",
    formula: (m) => m.aps_revenue / m.sessions * 1e3,
    issStats: manualISSStats.aps_revenue_per_aps_session,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  aps_winRate: {
    label: "APS Win Rate",
    explanation: "Win Rate of Amazon Publisher Services over other demand.",
    formula: (m) => m.aps_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_impressions: {
    label: "CR Impressions",
    explanation: "Impressions won by Content Recommendation.",
    formula: (m) => m.outbrain_impressions + m.taboola_impressions + m.yahooGemini_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_rpm: {
    label: "CR RPM",
    explanation: "RPM from impressions won by Content Recommendation.",
    formula: (m) => m.content_recommendation_revenue / m.content_recommendation_impressions * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_revenue: {
    label: "CR Revenue",
    explanation: "Content Recommendation Revenue",
    formula: (m) => m.outbrain_revenue + m.taboola_revenue + m.yahooGemini_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_pageView_rpm: {
    label: "CR PageView RPM",
    explanation: "Content Recommendation Revenue PageView RPM ",
    formula: (m) => m.content_recommendation_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_session_rpm: {
    label: "CR Session RPM",
    explanation: "Session RPM from impressions won by Content Recommendation.",
    formula: (m) => m.content_recommendation_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  content_recommendation_winRate: {
    label: "CR Win Rate",
    explanation: "Win Rate of Content Recommendation over other demand.",
    formula: (m) => m.content_recommendation_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_impressions: {
    label: "DA Impressions",
    explanation: "Impressions won by Dynamic Allocation (AdX/AdSense).",
    formula: (m) => m.dynamicAllocation_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_revenue: {
    label: "DA Revenue",
    explanation: "Dynamic Allocation (AdX/AdSense) revenue.",
    formula: (m) => m.dynamicAllocation_revenue,
    calculationType: CalculationType.Sum,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_revenue_with_forecast: {
    label: "DA Revenue with Forecast",
    explanation: "Dynamic Allocation (AdX/AdSense) revenue with forecast.",
    formula: (m) => m.dynamicAllocation_revenue_with_forecast,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_revenue_with_forecast_client: {
    label: "DA Revenue with Forecast (Client)",
    explanation: "Dynamic Allocation (AdX/AdSense) revenue with forecast of Client-Side model.",
    formula: (m) => m.dynamicAllocation_revenue_with_forecast_client,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_rpm: {
    label: "DA RPM",
    explanation: "Dynamic Allocation revenue per mille (thousand) impressions.",
    formula: (m) => m.dynamicAllocation_revenue / m.dynamicAllocation_impressions * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_valueRpm: {
    label: "DA Value-RPM",
    explanation: "Dynamic Allocation RPM multiplied by viewability.",
    formula: (m) => m.dynamicAllocation_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_winRate: {
    label: "DA Win Rate",
    explanation: "Win Rate of Dynamic Allocation over other demand.",
    formula: (m) => m.dynamicAllocation_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_pageView_rpm: {
    label: "DA PageView RPM",
    explanation: "Dynamic Allocation revenue per mille (thousand) page views",
    formula: (m) => m.dynamicAllocation_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_pageViewExact_rpm: {
    label: "DA PageView (exact) RPM",
    explanation: "Dynamic Allocation revenue per mille (thousand) page views (exact)",
    formula: (m) => m.dynamicAllocation_revenue / m.pageViewsExact * 1e3,
    calculationType: CalculationType.Mean,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_pageView_valueRpm: {
    label: "DA PageView Value-RPM",
    explanation: "Dynamic Allocation PageView RPM multiplied by viewability.",
    formula: (m) => m.dynamicAllocation_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_session_rpm: {
    label: "DA Session RPM",
    explanation: "Dynamic Allocation revenue per mille (thousand) sessions",
    formula: (m) => m.dynamicAllocation_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_session_rpm_with_forecast: {
    label: "DA Session RPM with Forecast",
    explanation: "Dynamic Allocation revenue per mille (thousand) sessions",
    formula: (m) => m.dynamicAllocation_revenue_with_forecast / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_session_valueRpm: {
    label: "DA Session Value-RPM",
    explanation: "Dynamic Allocation Session RPM multiplied by viewability.",
    formula: (m) => m.dynamicAllocation_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  acquisition_cost: {
    label: "Acquisition Cost",
    explanation: "Acquisition cost based on the configured URL parameters.",
    formula: (m) => m.acquisition_cost,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  acquisition_cost_measurable_session_starts: {
    label: "Acquisition Cost Available",
    explanation: "Count of events where acquisition cost is available.",
    formula: (m) => m.acquisition_cost_measurable_session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  floor_impressions: {
    label: "Floored Impressions",
    explanation: "Impressions with floor price.",
    formula: (m) => m.floor_measurable_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  floor_rpm: {
    label: "Floor Price",
    explanation: "Floor Price CPM.",
    formula: (m) => m.floor / m.floor_measurable_impressions,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  floor_empirical_revenue_ratio: {
    label: "Floor Empirical Revenue Ratio",
    explanation: "Floor Empirical Revenue.",
    formula: (m) => m.floor_empirical_revenue / m.revenue,
    calculationType: CalculationType.SpecialRate,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  prebid_user_id_providers_avg: {
    label: "PB Avg. Provided UID's",
    explanation: "Avg. number of Prebid provided User ID's per impression.",
    formula: (m) => m.prebid_user_id_providers_sum_uses / m.impressions,
    calculationType: CalculationType.Mean,
    issStats: manualISSStats.prebid_user_id_providers_avg,
    objective: OptimizationObjective.NoObjective
  },
  prebid_user_id_providers_zero_usages: {
    label: "PB No UID Impressions",
    explanation: "Number of impressions without a provided Prebid User ID.",
    formula: (m) => m.prebid_user_id_providers_zero_usages,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  prebid_user_id_providers_zero_usages_rate: {
    label: "PB No UID Impressions Rate",
    explanation: "Rate of impressions without a provided Prebid User ID .",
    formula: (m) => m.prebid_user_id_providers_zero_usages / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.NoObjective
  },
  prebid_rpm: {
    label: "PB RPM",
    explanation: "Prebid revenue per mille (thousand) impressions.",
    formula: (m) => m.prebid_revenue / m.impressions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_cache_rate: {
    label: "PB Cache Rate",
    explanation: "The number of impressions that were from a cached bid.",
    formula: (m) => m.prebid_cached_count / m.prebid_won_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.NoObjective
  },
  prebid_valueRpm: {
    label: "PB Value-RPM",
    explanation: "Prebid RPM multiplied by viewability.",
    formula: (m) => m.prebid_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  prebid_winRate: {
    label: "PB Win Rate",
    explanation: "Win Rate of Prebid over other demand.",
    formula: (m) => m.prebid_won_impressions / m.impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  prebid_revenue: {
    label: "PB Revenue",
    explanation: "Sum of the highest Prebid bids. In other words the revenue prebid would have generated without any other demand.",
    formula: (m) => m.prebid_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_pageView_rpm: {
    label: "PB PageView RPM",
    explanation: "Prebid revenue per mille (thousand) page views",
    formula: (m) => m.prebid_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_pageView_valueRpm: {
    label: "PB PageView Value-RPM",
    explanation: "Prebid PageView RPM multiplied by viewability.",
    formula: (m) => m.prebid_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  prebid_session_rpm: {
    label: "PB Session RPM",
    explanation: "Prebid revenue per mille (thousand) sessions",
    formula: (m) => m.prebid_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  prebid_session_valueRpm: {
    label: "PB Session Value-RPM",
    explanation: "Prebid Session RPM multiplied by viewability.",
    formula: (m) => m.prebid_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_impressions: {
    label: "PBW Impressions",
    explanation: "Impressions won by Prebid.",
    formula: (m) => m.prebid_won_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_rpm: {
    label: "PBW RPM",
    explanation: "RPM from impressions won by Prebid.",
    formula: (m) => m.prebid_won_revenue / m.prebid_won_impressions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_valueRpm: {
    label: "PBW Value-RPM",
    explanation: "RPM from impressions won by Prebid multiplied by viewability.",
    formula: (m) => m.prebid_won_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_revenue: {
    label: "PBW Revenue",
    explanation: "Prebid-Won Revenue",
    formula: (m) => m.prebid_won_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_pageView_rpm: {
    label: "PBW PageView RPM",
    explanation: "Prebid-Won PageView RPM from impressions won by Prebid.",
    formula: (m) => m.prebid_won_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_pageViewExact_rpm: {
    label: "PBW PageView (exact) RPM",
    explanation: "Prebid-Won PageView (exact) RPM from impressions won by Prebid.",
    formula: (m) => m.prebid_won_revenue / m.pageViewsExact * 1e3,
    calculationType: CalculationType.Mean,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_pageView_valueRpm: {
    label: "PBW PageView Value-RPM",
    explanation: "PageView RPM from impressions won by Prebid multiplied by viewability.",
    formula: (m) => m.prebid_won_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_session_rpm: {
    label: "PBW Session RPM",
    explanation: "Session RPM from impressions won by Prebid.",
    formula: (m) => m.prebid_won_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  prebid_won_session_valueRpm: {
    label: "PBW Session Value-RPM",
    explanation: "Session RPM from impressions won by Prebid multiplied by viewability.",
    formula: (m) => m.prebid_won_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.Maximize
  },
  prebid_lost_impressions: {
    label: "PBL Impressions",
    explanation: "Rendered impressions won by AdX, direct.. (not prebid).",
    formula: (m) => m.prebid_lost_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_rpm: {
    label: "PBL RPM",
    explanation: "RPM of the highest Prebid bids which did not win.",
    formula: (m) => m.prebid_lost_revenue / m.prebid_lost_impressions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_valueRpm: {
    label: "PBL Value-RPM",
    explanation: "RPM of the highest Prebid bids which did not win multiplied by viewability.",
    formula: (m) => m.prebid_lost_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_revenue: {
    label: "PBL Revenue",
    explanation: "Revenue Prebid would have generated if it had won.",
    formula: (m) => m.prebid_lost_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_pageView_rpm: {
    label: "PBL PageView RPM",
    explanation: "Page view RPM of the highest Prebid bids which did not win.",
    formula: (m) => m.prebid_lost_revenue / m.pageViews * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_pageView_valueRpm: {
    label: "PBL PageView Value-RPM",
    explanation: "Page view RPM of the highest Prebid bids which did not win multiplied by.",
    formula: (m) => m.prebid_lost_pageView_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_session_rpm: {
    label: "PBL Session RPM",
    explanation: "Session RPM of the highest Prebid bids which did not win",
    formula: (m) => m.prebid_lost_revenue / m.sessions * 1e3,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.NoObjective
  },
  prebid_lost_session_valueRpm: {
    label: "PBL Session Value-RPM",
    explanation: "Session RPM of the highest Prebid bids which did not win multiplied by Viewability.",
    formula: (m) => m.prebid_lost_session_rpm * m.viewability,
    calculationType: CalculationType.SpecialRate,
    timeSpan: sessionTimeSpan,
    objective: OptimizationObjective.NoObjective
  },
  prebid_incremental_value: {
    label: "PB Incremental Value",
    explanation: "The difference between the highest and second-highest bid in revenue. Giving an indication of the incremental value of the highest bid as well as bidder.",
    formula: (m) => m.prebid_incremental_value / 1e3,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  prebid_incremental_value_rpm: {
    label: "PB Incremental Value RPM",
    explanation: "The difference between the highest and second-highest bid in revenue, divided by number of prebid won impressions, multiplied by 1000.",
    formula: (m) => m.prebid_incremental_value / m.prebid_won_impressions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.NoObjective
  },
  bias: {
    label: "Bias",
    explanation: "Bias",
    formula: (m) => m.bias / m._count,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  lost_rpm_hb: {
    label: "lost_rpm_hb",
    explanation: "lost_rpm_hb",
    formula: (m) => m.prebid_lost_revenue_hb,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  min_cpm: {
    label: "min_cpm",
    explanation: "min_cpm",
    formula: (m) => m.prebid_winRate * m.prebid_won_rpm + (m.prebid_lost_revenue_hb / (m.prebid_lost_impressions == 0 || m.prebid_lost_impressions == null ? 1 : m.prebid_lost_impressions) * m.bias + 0.01) * (1 - m.prebid_winRate),
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  diff: {
    label: "diff",
    explanation: "diff",
    formula: (m) => (m.prebid_rpm - m.min_cpm) / m.min_cpm,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  won_lost_diff: {
    label: "won_lost_diff",
    explanation: "won_lost_diff",
    formula: (m) => (m.prebid_lost_rpm - m.prebid_won_rpm) / m.prebid_won_rpm,
    calculationType: CalculationType.SpecialRate,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.NoObjective
  },
  lineItem_revenue: {
    label: "Line Item Revenue",
    explanation: "The revenue based on the line items CPM",
    formula: (m) => m.lineItem_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  uniqueExternalId: {
    label: "Unique External Ids",
    explanation: "Count of unique external ids",
    formula: (m) => m.uniqueExternalId,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_impressions: {
    label: "DA Predicted Impressions",
    explanation: "Predicted Impressions won by Dynamic Allocation (AdX/AdSense).",
    formula: (m) => m.dynamicAllocation_predicted_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_impressions_error: {
    label: "DA Predicted Impressions Error",
    explanation: "Difference between actual and predicted impressions",
    formula: (m) => m.dynamicAllocation_impressions - m.dynamicAllocation_predicted_impressions,
    calculationType: CalculationType.Sum,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue: {
    label: "DA Predicted Revenue (Client)",
    explanation: "Predicted Dynamic Allocation (AdX/AdSense) revenue.",
    formula: (m) => m.dynamicAllocation_predicted_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_rpm: {
    label: "DA Predicted RPM (Client)",
    explanation: "Predicted Dynamic Allocation (AdX/AdSense) RPM.",
    formula: (m) => m.dynamicAllocation_predicted_revenue / m.dynamicAllocation_impressions * 1e3,
    calculationType: CalculationType.SpecialRate,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_revenue_error: {
    label: "DA Predicted Revenue Error (Client)",
    explanation: "Difference between actual and predicted revenue",
    formula: (m) => m.dynamicAllocation_revenue === 0 ? null : Math.abs(m.dynamicAllocation_revenue_with_forecast_client - m.dynamicAllocation_predicted_revenue),
    calculationType: CalculationType.Sum,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_r2: {
    label: "DA Predicted R-Squared (Client)",
    explanation: "Coefficient of determination",
    formula: (m) => {
      const ssRes = m.dynamicAllocation_predicted_revenue_residual;
      const mm = 1e6;
      const ssTot = m.dynamicAllocation_revenue_sum_of_squares - 1 / m.dynamicAllocation_impressions * Math.pow(m.dynamicAllocation_revenue * mm, 2);
      const r2 = 1 - ssRes / ssTot;
      if (Number.isNaN(r2)) {
        return null;
      }
      return r2;
    },
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_predicted_revenue_mae: {
    label: "DA Predicted KMAE (Client)",
    explanation: "Mean Absolute Error * 1000",
    formula: (m) => m.dynamicAllocation_predicted_revenue_abs_difference === 0 ? null : m.dynamicAllocation_predicted_revenue_abs_difference / 1e3 / m.dynamicAllocation_impressions,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_rmse: {
    label: "DA Predicted KRMSE (Client)",
    explanation: "Root Mean Squared Error * 1000",
    formula: (m) => m.dynamicAllocation_predicted_revenue_residual === 0 ? null : Math.sqrt(m.dynamicAllocation_predicted_revenue_residual / 1e6 / m.dynamicAllocation_impressions),
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_relative_error: {
    label: "DA Predicted Revenue Relative Error (Client)",
    explanation: "Difference between actual and predicted revenue",
    formula: (m) => m.dynamicAllocation_revenue === 0 ? null : Math.abs(m.dynamicAllocation_predicted_revenue - m.dynamicAllocation_revenue_with_forecast_client) / m.dynamicAllocation_revenue_with_forecast_client,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_server: {
    label: "DA Predicted Revenue (Server)",
    explanation: "Predicted Dynamic Allocation (AdX/AdSense) revenue.",
    formula: (m) => m.dynamicAllocation_predicted_revenue_server / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_rpm_server: {
    label: "DA Predicted RPM (Server)",
    explanation: "Predicted Dynamic Allocation (AdX/AdSense) RPM.",
    formula: (m) => m.dynamicAllocation_predicted_revenue_server / m.dynamicAllocation_impressions * 1e3,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  dynamicAllocation_predicted_revenue_error_server: {
    label: "DA Predicted Revenue Error (Server)",
    explanation: "Difference between actual and predicted revenue",
    formula: (m) => m.dynamicAllocation_revenue === 0 ? null : Math.abs(m.dynamicAllocation_revenue_with_forecast - m.dynamicAllocation_predicted_revenue_server),
    calculationType: CalculationType.Sum,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_r2_server: {
    label: "DA Predicted R-Squared (Server)",
    explanation: "Coefficient of determination",
    formula: (m) => {
      const ssRes = m.dynamicAllocation_predicted_revenue_server_residual;
      const mm = 1e6;
      const ssTot = m.dynamicAllocation_revenue_sum_of_squares - 1 / m.dynamicAllocation_impressions * Math.pow(m.dynamicAllocation_revenue * mm, 2);
      const r2 = 1 - ssRes / ssTot;
      if (Number.isNaN(r2)) {
        return null;
      }
      return r2;
    },
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  dynamicAllocation_predicted_revenue_mae_server: {
    label: "DA Predicted KMAE (Server)",
    explanation: "Mean Absolute Error * 1000",
    formula: (m) => m.dynamicAllocation_predicted_revenue_server_abs_difference === 0 ? null : m.dynamicAllocation_predicted_revenue_server_abs_difference / 1e3 / m.dynamicAllocation_impressions,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_rmse_server: {
    label: "DA Predicted KRMSE (Server)",
    explanation: "Root Mean Squared Error * 1000",
    formula: (m) => m.dynamicAllocation_predicted_revenue_server_residual === 0 ? null : Math.sqrt(m.dynamicAllocation_predicted_revenue_server_residual / 1e6 / m.dynamicAllocation_impressions),
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_predicted_revenue_relative_error_server: {
    label: "DA Predicted Revenue Relative Error (Server)",
    explanation: "Difference between actual and predicted revenue",
    formula: (m) => m.dynamicAllocation_revenue_with_forecast === 0 ? null : Math.abs(m.dynamicAllocation_predicted_revenue_server - m.dynamicAllocation_revenue_with_forecast) / m.dynamicAllocation_revenue_with_forecast,
    hideFromApiDocs: true,
    hideFromAlerts: true,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Minimize
  },
  dynamicAllocation_confirmed_clicks_rate: {
    label: "Confirmed Clicks Rate",
    explanation: "Rate of confirmed clicks over Dynamic Allocation impressions",
    formula: (m) => m.dynamicAllocation_impressions === 0 ? null : m.dynamicAllocation_confirmed_clicks / m.dynamicAllocation_impressions,
    keepFormulaFalsyAsFalsy: true,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  }
});

// ../core/src/constants/calculated-metrics/data/buy-metrics.types.ts
var BuyReportMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield, calculatedYieldMetrics);
  return args;
};
var SiteReportMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield, calculatedYieldMetrics);
  return args;
};
var SpentBySectionReportMetricMap = (args) => {
  addFormulaDependenciesAndId(args);
  addRawDependencies(args, rawMetricsYield, calculatedYieldMetrics);
  return args;
};

// ../core/src/constants/calculated-metrics/data/by-placement/buy.ts
var calculatedBuyReportMetrics = BuyReportMetricMap({
  buy_report_clicks: {
    label: "Clicks Bought",
    explanation: "Clicks bought",
    formula: (m) => m.clicks_bought,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  buy_report_sessions: {
    label: "New Sessions",
    explanation: "User Sessions",
    formula: (m) => m.session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  buy_report_session_click_ratio: {
    label: "Session Click Ratio",
    explanation: "New Sessions / Clicks",
    formula: (m) => m.buy_report_sessions / m.buy_report_clicks,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  buy_report_cpc: {
    label: "CPC",
    explanation: "Cash per Click",
    formula: (m) => m.buy_report_spent / m.buy_report_clicks,
    issStats: manualISSStats.buy_spent_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  buy_report_spent: {
    label: "Spent",
    explanation: "Amount spent",
    formula: (m) => m.spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  buy_report_roi: {
    label: "ROI",
    explanation: "Return on Investment (Revenue / Spent)",
    formula: (m) => m.revenue / m.buy_report_spent,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  buy_report_roi_with_forecast: {
    label: "ROI with Forecast",
    explanation: "Return on Investment (Revenue / Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast / m.buy_report_spent,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  buy_report_profit: {
    label: "Profit",
    explanation: "Profit (Revenue - Spent)",
    formula: (m) => m.revenue - m.buy_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  buy_report_profit_with_forecast: {
    label: "Profit with Forecast",
    explanation: "Profit (Revenue - Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast - m.buy_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  buy_report_click_rpm: {
    label: "Click RPM",
    explanation: "Revenue per mille (thousand) clicks",
    formula: (m) => m.revenue / m.buy_report_clicks * 1e3,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  buy_report_click_rpm_with_forecast: {
    label: "Click RPM with Forecast",
    explanation: "Revenue with DA forecast per mille (thousand) clicks",
    formula: (m) => m.revenue_with_forecast / m.buy_report_clicks * 1e3,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  buy_report_click_revenue: {
    label: "Click Revenue",
    explanation: "Revenue per acquired Click",
    formula: (m) => m.revenue / m.buy_report_clicks,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  buy_report_click_revenue_with_forecast: {
    label: "Click Revenue with Forecast",
    explanation: "Revenue with DA forecast per acquired Click",
    formula: (m) => m.revenue_with_forecast / m.buy_report_clicks,
    issStats: manualISSStats.buy_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  buy_report_cps: {
    label: "CPS",
    explanation: "Cost per Session",
    formula: (m) => m.buy_report_spent / m.buy_report_sessions,
    issStats: manualISSStats.buy_spent_per_new_sessions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  buy_report_cpai: {
    label: "CPMAI",
    explanation: "Cost per Mille (thousand) Ad Impressions",
    formula: (m) => m.buy_report_spent / m.impressions * 1e3,
    issStats: manualISSStats.buy_spent_per_impressions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  buy_report_new_sessions: {
    label: "New Sessions",
    explanation: "New Sessions from Bought Clicks",
    formula: (m) => m.buy_report_sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  buy_report_ffi: {
    label: "Prebid FFI",
    explanation: "Sum of the first 5 highest bids from Prebid, per session",
    formula: (m) => m.first_five_indicator / m.session_starts / 1e3 / 1e3,
    issStats: manualISSStats.buy_ffi_per_session_starts,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  }
});

// ../core/src/constants/revenue-multiplier.constants.ts
var REVENUE_MULTIPLIER = 1e6 * 1e3;

// ../core/src/constants/calculated-metrics/data/by-placement/discrepancy_revenue.ts
var getDiscrepancyPercentage = (flatDiscrepancy, actualValue) => {
  if (actualValue === null || actualValue === 0) {
    return 100;
  }
  return flatDiscrepancy / actualValue * 100;
};
var calculatedDiscrepancyRevenueMetrics = DiscrepancyRevenueMetricMap({
  calculated_revenue_discrepancy: {
    label: "Revenue Discrepancy",
    explanation: "Revenue Discrepancy between SSP connections and Assertive Events in percentage",
    formula: (m) => m.actual_revenue - m.assertive_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  calculated_revenue_discrepancy_percentage: {
    label: "Revenue Discrepancy Percentage",
    explanation: "Revenue Discrepancy percentage between SSP connections and Assertive Events in percentage",
    formula: (m) => getDiscrepancyPercentage(m.calculated_revenue_discrepancy, m.actual_revenue),
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 0,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.NoObjective
  },
  calculated_impressions_discrepancy: {
    label: "Impressions Discrepancy",
    explanation: "Impressions Discrepancy between SSP connections and Assertive Events",
    formula: (m) => m.actual_impressions - m.assertive_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  calculated_impressions_discrepancy_percentage: {
    label: "Impressions Discrepancy Percentage",
    explanation: "Impressions Discrepancy percentage between SSP connections and Assertive Events in percentage",
    formula: (m) => getDiscrepancyPercentage(m.calculated_impressions_discrepancy, m.actual_impressions),
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 0,
    keepFormulaFalsyAsFalsy: true,
    objective: OptimizationObjective.NoObjective
  },
  assertive_revenue: {
    label: "Assertive Revenue",
    explanation: "Assertive revenue represented from events",
    formula: (m) => m.assertive_revenue / REVENUE_MULTIPLIER,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  actual_revenue: {
    label: "Actual Revenue",
    explanation: "Actual revenue represented from SSP's connections",
    formula: (m) => m.actual_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  actual_impressions: {
    label: "Actual Impressions",
    explanation: "Actual impressions represented from SSP's connections",
    formula: (m) => m.actual_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  assertive_impressions: {
    label: "Assertive Impressions",
    explanation: "Assertive impressions represented from events",
    formula: (m) => m.assertive_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/financial.ts
var calculatedFinancialReportMetrics = FinancialMetricMap({
  actual_revenue: {
    label: "Actual Revenue (USD)",
    explanation: "Actual revenue represented from SSP's connections in USD",
    formula: (m) => m.actual_revenue,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  actual_impressions: {
    label: "Actual Impressions",
    explanation: "Actual impressions represented from SSP's connections",
    formula: (m) => m.actual_impressions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  actual_revenue_ssp_currency: {
    label: "Actual Revenue (SSP Currency)",
    explanation: "Actual revenue represented from SSP's connections in SSP currency",
    formula: (m) => m.actual_revenue_ssp_currency,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/industry.ts
var industryCorrectionForIncompletePresence = (m, opname) => {
  if (opname === "groupTotal") {
    return m.avg_present_in_days_frac;
  }
  return 1;
};
var calculatedIndustryMetrics = IndustryMetricMap({
  ind_fair_share_win_rate: {
    label: "Normalized Win Rate",
    explanation: "Win Rate normalized by the amount of competition it had.",
    formula: (m, opname) => m.prebid_won_impressions / m.fair_available_impressions * industryCorrectionForIncompletePresence(m, opname),
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  ind_fair_share_revenue: {
    label: "Relative Revenue",
    explanation: "Revenue from this bidder relative to the total prebid revenue.",
    formula: (m, opname) => m.prebid_won_revenue / 1e3 / m.fair_total_prebid_revenue * industryCorrectionForIncompletePresence(m, opname) * 1e3 * 1e3 * 1e3,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  },
  ind_presence: {
    label: "Presence",
    explanation: "Average number of sites working with this category",
    formula: (m, opname) => m.presence / m.entities_count * industryCorrectionForIncompletePresence(m, opname),
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/prebid.ts
var calculatedPrebidMetrics = PrebidMetricMap({
  prebid_report_requests: {
    label: "Requests",
    explanation: "Bid Requests sent out",
    formula: (m) => m.prebid_report_requests,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  prebid_report_auctions: {
    label: "Auctions",
    explanation: "Amount of Prebid Auctions",
    formula: (m) => m.prebid_report_auctions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  prebid_report_request_cpm: {
    label: "Request Bid CPM",
    explanation: "The Bid Request CPM",
    formula: (m) => m.prebid_report_bids_revenue / m.prebid_report_requests * 1e3,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_request_win_rpm: {
    label: "Request Win RPM",
    explanation: "The Won Bids RPM",
    formula: (m) => m.prebid_report_wins_revenue / m.prebid_report_requests * 1e3,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_auction_win_rpm: {
    label: "Auction Win RPM",
    explanation: "The Won Bids RPM",
    formula: (m) => m.prebid_report_wins_revenue / m.prebid_report_auctions * 1e3,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_requests_per_auction: {
    label: "Requests per Auction",
    explanation: "Amount of Requests, per Auction",
    formula: (m) => m.prebid_report_requests / m.prebid_report_auctions,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.NoObjective
  },
  prebid_report_bidVolume_auction_cpm: {
    label: "Bid Volume Auction CPM",
    explanation: "The Bid Volume (sum of Bids) per Auction",
    formula: (m) => m.prebid_report_bids_revenue / m.prebid_report_auctions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bidVolume_pageView_cpm: {
    label: "Bid Volume PageView CPM",
    explanation: "The Bid Volume (sum of Bids) per PageView",
    formula: (m) => m.prebid_report_bids_revenue / m.prebid_report_pageViews * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bidVolume_session_cpm: {
    label: "Bid Volume Session CPM",
    explanation: "The Bid Volume (sum of Bids) per Session",
    formula: (m) => m.prebid_report_bids_revenue / m.prebid_report_sessions * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bidDensity_auction: {
    label: "Bid Density Auction",
    explanation: "The amount of Bids received per Auction",
    formula: (m) => m.prebid_report_bids / m.prebid_report_auctions,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bidDensity_pageView: {
    label: "Bid Density PageView",
    explanation: "The amount of Bids received per Page View",
    formula: (m) => m.prebid_report_bids / m.prebid_report_pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bidDensity_session: {
    label: "Bid Density Session",
    explanation: "The amount of Bids received per Session",
    formula: (m) => m.prebid_report_bids / m.prebid_report_sessions,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_timeToRespond: {
    label: "Response Time",
    explanation: "Average responsive time on bid requests",
    formula: (m) => m.prebid_report_timeToRespond / m.prebid_report_bids,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_ivt_rate: {
    label: "IVT Rate",
    explanation: "Rate of invalid traffic over entire traffic",
    formula: (m) => (m.prebid_report_givt_impressions + m.prebid_report_sivt_impressions) / m.prebid_report_ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_givt_rate: {
    label: "GIVT Rate",
    explanation: "Rate of general invalid traffic over entire traffic",
    formula: (m) => m.prebid_report_givt_impressions / m.prebid_report_ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_sivt_rate: {
    label: "SIVT Rate",
    explanation: "Rate of sophisticated invalid traffic over entire traffic",
    formula: (m) => m.prebid_report_sivt_impressions / m.prebid_report_ivt_measurable_impressions,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_timeouts: {
    label: "Timeouts",
    explanation: "Bid Requests that timed out",
    formula: (m) => m.prebid_report_timeouts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_timeout_rate: {
    label: "Timeout Rate",
    explanation: "Relative amount of bid requests that timed out",
    formula: (m) => m.prebid_report_timeouts / m.prebid_report_requests,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_noBids: {
    label: "NoBids",
    explanation: "NoBid responses",
    formula: (m) => m.prebid_report_noBids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_noBid_rate: {
    label: "NoBid Rate",
    explanation: "Relative amount of NoBids from Requests",
    formula: (m) => m.prebid_report_noBids / m.prebid_report_requests,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_bids: {
    label: "Bids",
    explanation: "Bids received",
    formula: (m) => m.prebid_report_bids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bids_revenue: {
    label: "Bids Revenue",
    explanation: "The sum of all bids received, not just the highest ones",
    formula: (m) => m.prebid_report_bids_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bid_cpm: {
    label: "Bid CPM",
    explanation: "Revenue per mille (thousand) bids",
    formula: (m) => m.prebid_report_bids_revenue / m.prebid_report_bids * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bid_rate: {
    label: "Bid Rate",
    explanation: "Relative amount of Bids from Requests",
    formula: (m) => m.prebid_report_bids / m.prebid_report_requests,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_bid_position: {
    label: "Bid Position",
    explanation: "Average bid position for the placement in the auction.",
    formula: (m) => m.prebid_report_bid_position / m.prebid_report_bids,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.NoObjective
  },
  prebid_report_setTargetingTime: {
    label: "Set Targeting Time",
    explanation: "Average time between bid response received and GAM targeting set in milliseconds.",
    formula: (m) => m.prebid_report_setTargetingTime / m.prebid_report_bids,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_wins: {
    label: "Wins",
    explanation: "Amount of Bids rendered",
    formula: (m) => m.prebid_report_wins,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_wins_revenue: {
    label: "Wins Revenue",
    explanation: "Revenue generated from bids that were rendered",
    formula: (m) => m.prebid_report_wins_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_win_cpm: {
    label: "Win CPM",
    explanation: "Revenue per mille (thousand) rendered bids",
    formula: (m) => m.prebid_report_wins_revenue / m.prebid_report_wins * 1e3,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_win_rate: {
    label: "Win Rate",
    explanation: "Relative amount of Wins from Bids (Wins / Bids)",
    formula: (m) => m.prebid_report_wins / m.prebid_report_bids,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_highest_bid_rate: {
    label: "Highest Bid Rate",
    explanation: "Rate of highest bids over all bids",
    formula: (m) => m.prebid_report_highest_bid / m.prebid_report_bids,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_request_win_rate: {
    label: "Request-Win Rate",
    explanation: "Relative amount of Wins from Requests (Wins / Requests)",
    formula: (m) => m.prebid_report_wins / m.prebid_report_requests,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  prebid_report_renderedTime: {
    label: "Rendered Time",
    explanation: "Average time between auction start and creative rendering.",
    formula: (m) => m.prebid_report_renderedTime / m.prebid_report_wins,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  prebid_report_floor_cpm: {
    label: "Floor CPM",
    explanation: "Average CPM of the floored value.",
    formula: (m) => m.prebid_report_floor_sum / m.prebid_report_floor_non_null,
    calculationType: CalculationType.Mean,
    issStats: manualISSStats.prebid_report_floor_cpm,
    objective: OptimizationObjective.NoObjective
  },
  prebid_report_floor_rate: {
    label: "Floor Rate",
    explanation: "Rate of requests that had a floor price.",
    formula: (m) => m.prebid_report_floor_non_null / m.prebid_report_bids,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/request_reduction_client_v2.ts
var calculatedRequestReductionClientV2Metrics = RequestReductionClientV2MetricMap({
  request_reduction_client_requests: {
    label: "Bid Requests",
    explanation: "Requests for a bid",
    formula: (m) => m.requests,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  request_reduction_client_waste_ratio: {
    label: "Waste",
    explanation: "Ratio of no-bids / bid requests",
    formula: (m) => m.waste / m.requests,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_bids: {
    label: "Bids",
    explanation: "Bids made by bidders",
    formula: (m) => m.bids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_wins: {
    label: "Wins",
    explanation: "Wins made by bidders",
    formula: (m) => m.wins,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_saved_waste: {
    label: "Saved Waste",
    explanation: "Requests there weren't bids that would be filtered",
    formula: (m) => m.saved_waste,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_saved_waste_ratio: {
    label: "Waste Reduction",
    explanation: "Ratio of saved waste / total waste",
    formula: (m) => m.saved_waste / m.waste,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_untouched_bids: {
    label: "Untouched Bids",
    explanation: "Bids that wouldn't be filtered",
    formula: (m) => m.untouched_bids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_lost_bids: {
    label: "Lost Bids",
    explanation: "Bids that would be filtered",
    formula: (m) => m.lost_bids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_lost_wins: {
    label: "Lost Wins",
    explanation: "Wins that would be filtered",
    formula: (m) => m.lost_wins,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_lost_bids_ratio: {
    label: "Lost Bids",
    explanation: "Ratio of lost bids / bids",
    formula: (m) => m.lost_bids / m.bids,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_lost_wins_ratio: {
    label: "Lost Wins Ratio",
    explanation: "Ratio of lost wins / wins",
    formula: (m) => m.lost_wins / m.wins,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_bid_volume_loss_ratio: {
    label: "Lost Bid Volume",
    explanation: "Ratio of lost bid volume (sum of lost bids) / total bid volume (sum of bids)",
    formula: (m) => m.lost_bid_volume / m.bid_volume,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_won_volume_loss_ratio: {
    label: "Lost Won Volume Ratio",
    explanation: "Ratio of lost won volume (sum of winning bids) / total won volume (sum of bids)",
    formula: (m) => m.lost_won_volume / m.won_volume,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_client_untouched_bid_volume: {
    label: "Untouched Bid Volume",
    explanation: "Bid Volume (sum of bids) that was left untouched",
    formula: (m) => m.untouched_bid_volume / 1e9,
    calculationType: CalculationType.Sum,
    decimalPlaces: 2,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_bid_volume_total: {
    label: "Total Bid Volume",
    explanation: "Total Bid Volume (sum of bids)",
    formula: (m) => m.bid_volume / 1e9,
    calculationType: CalculationType.Sum,
    decimalPlaces: 2,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_bid_cpm: {
    label: "Bid CPM",
    explanation: "Bid Volume (sum of bids) / Number of Bids * 1000",
    formula: (m) => m.bid_volume / m.bids / 1e6,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_client_left_waste: {
    label: "Left Waste",
    explanation: "Requests there weren't bids that wouldn't be filtered",
    formula: (m) => m.left_waste,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/request_reduction_server_latency.ts
var calculatedRequestReductionServerLatencyMetrics = RequestReductionServerLatencyMetricMap({
  request_reduction_server_latency_predict_requests: {
    label: "Predict Requests",
    explanation: "Requests for prediction",
    formula: (m) => m.predict_requests,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  request_reduction_server_latency_predict_result_ratio: {
    label: "Predict / Result Ratio",
    explanation: "The ratio of Predict Requests and Result Requests.",
    formula: (m) => m.predict_requests / m.result_requests,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.NoObjective
  },
  request_reduction_server_latency_predict_errors: {
    label: "Predict Errors",
    explanation: "Prediction requests that errored out",
    formula: (m) => m.predict_errors,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_predict_bidders: {
    label: "Predict Bidders",
    explanation: "Number of bidders that participated in the predicted auctions",
    formula: (m) => m.predict_bidders,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_latency_predict_latencies_p50: {
    label: "Predict Latencies P50 (ms)",
    explanation: "P50 of the predict latencies, in milliseconds",
    formula: (m) => m.predict_latencies_p50 / m.predict_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_predict_latencies_p95: {
    label: "Predict Latencies P95 (ms)",
    explanation: "P95 of the predict latencies, in milliseconds",
    formula: (m) => m.predict_latencies_p95 / m.predict_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_predict_latencies_p99: {
    label: "Predict Latencies P99 (ms)",
    explanation: "P99 of the predict latencies, in milliseconds",
    formula: (m) => m.predict_latencies_p99 / m.predict_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_result_requests: {
    label: "Result Requests",
    explanation: "Requests for results",
    formula: (m) => m.result_requests,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  request_reduction_server_latency_result_errors: {
    label: "Result Errors",
    explanation: "Results that errored out",
    formula: (m) => m.result_errors,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_result_latencies_p50: {
    label: "Result Latencies P50 (ms)",
    explanation: "P50 of the latencies of results, in milliseconds",
    formula: (m) => m.result_latencies_p50 / m.result_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_result_latencies_p95: {
    label: "Result Latencies P95 (ms)",
    explanation: "P95 of the latencies of results, in milliseconds",
    formula: (m) => m.result_latencies_p95 / m.result_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_result_latencies_p99: {
    label: "Result Latencies P99 (ms)",
    explanation: "P99 of the latencies of results, in milliseconds",
    formula: (m) => m.result_latencies_p99 / m.result_requests,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_latency_average_bidder_per_auctions: {
    label: "Average Bidders per Auctions",
    explanation: "Average number of bidders per auction",
    formula: (m) => m.predict_bidders / m.predict_requests,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_latency_sampled_traffic_pct: {
    label: "Sampled Traffic %",
    explanation: "Percentage of traffic that was sampled",
    formula: (m) => m.predict_samples / m.predict_requests * 100,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/request_reduction_server_samples.ts
var calculatedRequestReductionServerSamplesProfileMetrics = RequestReductionServerSamplesProfileMetricMap({
  request_reduction_server_samples_bids: {
    label: "Bids",
    explanation: "Bids",
    formula: (m) => m.request_reduction_server_samples_bids,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_bid_revenue: {
    label: "Bid revenue",
    explanation: "Bid revenue",
    formula: (m) => m.request_reduction_server_samples_bid_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_bid_rate: {
    label: "Bid rate",
    explanation: "Bid Responses from Bidder / Bid Requests to Bidder",
    formula: (m) => m.request_reduction_server_samples_bids / m.request_reduction_server_samples_requests,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 4,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_bid_cpm: {
    label: "Bid CPM",
    explanation: "Avg. CPM of Bid Responses from Bidder",
    formula: (m) => m.request_reduction_server_samples_bid_revenue / m.request_reduction_server_samples_bids * 1e3,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_wins: {
    label: "Wins",
    explanation: "Wins",
    formula: (m) => m.request_reduction_server_samples_wins,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_win_revenue: {
    label: "Win revenue",
    explanation: "Win revenue",
    formula: (m) => m.request_reduction_server_samples_win_revenue / 1e3,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_win_rate: {
    label: "Win rate",
    explanation: "Impression Events from Bidder / Bid Requests to Bidder",
    formula: (m) => m.request_reduction_server_samples_wins / m.request_reduction_server_samples_requests,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 4,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_win_cpm: {
    label: "Win CPM",
    explanation: "Avg. CPM of Impression Events from Bidder (Revenue)",
    formula: (m) => m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_wins * 1e3,
    calculationType: CalculationType.SpecialRate,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_requests: {
    label: "Requests",
    explanation: "Total requests",
    formula: (m) => m.request_reduction_server_samples_requests,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  }
});
var calculatedRequestReductionServerSamplesPredictMetrics = RequestReductionServerSamplesPredictMetricMap({
  ...calculatedRequestReductionServerSamplesProfileMetrics,
  request_reduction_server_samples_reduction_efficiency: {
    label: "Reduction Efficiency",
    explanation: "Reduction Efficiency correlates the reduction rate with revenue loss. (higher is better)",
    formula: (m) => 1 - m.request_reduction_server_samples_req_reduction_rate * m.request_reduction_server_samples_lost_revenue_rate * 100,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_req_reduction_rate: {
    label: "Req. Reduction Rate",
    explanation: "Predicted as False / Requests",
    formula: (m) => (m.request_reduction_server_samples_bid_true_negative + m.request_reduction_server_samples_bid_false_negative) / m.request_reduction_server_samples_requests,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  request_reduction_server_samples_lost_bids_rate: {
    label: "Lost Bids Rate",
    explanation: "Reduced / Requests",
    formula: (m) => m.request_reduction_server_samples_bid_false_negative / m.request_reduction_server_samples_bids,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_lost_wins_rate: {
    label: "Lost Wins Rate",
    explanation: "Missed Wins / Wins",
    formula: (m) => m.request_reduction_server_samples_win_false_negative / m.request_reduction_server_samples_wins,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_lost_revenue_rate: {
    label: "Lost Revenue Rate",
    explanation: "Missed Revenue / Revenue",
    formula: (m) => m.request_reduction_server_samples_missed_revenue / m.request_reduction_server_samples_win_revenue,
    calculationType: CalculationType.ProportionRatio,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_missed_revenue: {
    label: "Missed Revenue",
    explanation: "Missed Revenue",
    formula: (m) => m.request_reduction_server_samples_missed_revenue / 1e3,
    calculationType: CalculationType.Sum,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_predicts: {
    label: "Predicted as True",
    explanation: "Predicted as True",
    formula: (m) => m.request_reduction_server_samples_predicts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  request_reduction_server_samples_rpb: {
    label: "Revenue per Billion",
    explanation: "Revenue per Billion Wins",
    formula: (m) => m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_wins * 1e9,
    calculationType: CalculationType.SpecialRate,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_bid_accuracy: {
    label: "Bid Accuracy %",
    explanation: "How often the classifier is correct",
    formula: (m) => 100 * ((m.request_reduction_server_samples_bid_true_positive + m.request_reduction_server_samples_bid_true_negative) / m.request_reduction_server_samples_requests),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_bid_precision: {
    label: "Bid Precision %",
    explanation: "When classifying positives, how often is correct",
    formula: (m) => 100 * (m.request_reduction_server_samples_bid_true_positive / (m.request_reduction_server_samples_bid_true_positive + m.request_reduction_server_samples_bid_false_positive)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_bid_recall: {
    label: "Bid Recall %",
    explanation: "How often the classifier detects actual positives",
    formula: (m) => 100 * (m.request_reduction_server_samples_bid_true_positive / (m.request_reduction_server_samples_bid_true_positive + m.request_reduction_server_samples_bid_false_negative)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_bid_specificity: {
    label: "Bid Specificity %",
    explanation: "How often does the classifier detects actual negatives",
    formula: (m) => 100 * (m.request_reduction_server_samples_bid_true_negative / (m.request_reduction_server_samples_bid_true_negative + m.request_reduction_server_samples_bid_false_positive)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_win_accuracy: {
    label: "Win Accuracy %",
    explanation: "How often the classifier is correct",
    formula: (m) => 100 * ((m.request_reduction_server_samples_win_true_positive + m.request_reduction_server_samples_win_true_negative) / m.request_reduction_server_samples_requests),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_win_precision: {
    label: "Win Precision %",
    explanation: "When classifying positives, how often is correct",
    formula: (m) => 100 * (m.request_reduction_server_samples_win_true_positive / (m.request_reduction_server_samples_win_true_positive + m.request_reduction_server_samples_win_false_positive)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_win_recall: {
    label: "Win Recall %",
    explanation: "How often the classifier detects actual positives",
    formula: (m) => 100 * (m.request_reduction_server_samples_win_true_positive / (m.request_reduction_server_samples_win_true_positive + m.request_reduction_server_samples_win_false_negative)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  },
  request_reduction_server_samples_win_specificity: {
    label: "Win Specificity %",
    explanation: "How often does the classifier detects actual negatives",
    formula: (m) => 100 * (m.request_reduction_server_samples_win_true_negative / (m.request_reduction_server_samples_win_true_negative + m.request_reduction_server_samples_win_false_positive)),
    calculationType: CalculationType.ProportionPercentage,
    objective: OptimizationObjective.Minimize
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/site.ts
var calculatedSiteReportMetrics = SiteReportMetricMap({
  site_report_clicks: {
    label: "Clicks Bought",
    explanation: "Clicks bought",
    formula: (m) => m.clicks_bought,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  site_report_sessions: {
    label: "New Sessions",
    explanation: "User Sessions",
    formula: (m) => m.session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  site_report_session_click_ratio: {
    label: "Session Click Ratio",
    explanation: "New Sessions / Clicks",
    formula: (m) => m.site_report_sessions / m.site_report_clicks,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  site_report_cpc: {
    label: "CPC",
    explanation: "Cash per Click",
    formula: (m) => m.site_report_spent / m.site_report_clicks,
    issStats: manualISSStats.site_spent_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  site_report_spent: {
    label: "Spent",
    explanation: "Amount spent",
    formula: (m) => m.spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  site_report_roi: {
    label: "ROI",
    explanation: "Return on Investment (Revenue / Spent)",
    formula: (m) => m.revenue / m.site_report_spent,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  site_report_roi_with_forecast: {
    label: "ROI with Forecast",
    explanation: "Return on Investment (Revenue / Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast / m.site_report_spent,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  site_report_profit: {
    label: "Profit",
    explanation: "Profit (Revenue - Spent)",
    formula: (m) => m.revenue - m.site_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  site_report_profit_with_forecast: {
    label: "Profit with Forecast",
    explanation: "Profit (Revenue - Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast - m.site_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  site_report_click_rpm: {
    label: "Click RPM",
    explanation: "Revenue per mille (thousand) clicks",
    formula: (m) => m.revenue / m.site_report_clicks * 1e3,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  site_report_click_rpm_with_forecast: {
    label: "Click RPM with Forecast",
    explanation: "Revenue with DA forecast per mille (thousand) clicks",
    formula: (m) => m.revenue_with_forecast / m.site_report_clicks * 1e3,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  site_report_click_revenue: {
    label: "Click Revenue",
    explanation: "Revenue per acquired Click",
    formula: (m) => m.revenue / m.site_report_clicks,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  site_report_click_revenue_with_forecast: {
    label: "Click Revenue with Forecast",
    explanation: "Revenue with DA forecast per acquired Click",
    formula: (m) => m.revenue_with_forecast / m.site_report_clicks,
    issStats: manualISSStats.site_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  site_report_cps: {
    label: "CPS",
    explanation: "Cost per Session",
    formula: (m) => m.site_report_spent / m.site_report_sessions,
    issStats: manualISSStats.site_spent_per_new_sessions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  site_report_cpai: {
    label: "CPMAI",
    explanation: "Cost per Mille (thousand) Ad Impressions",
    formula: (m) => m.site_report_spent / m.impressions * 1e3,
    issStats: manualISSStats.site_spent_per_impressions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  site_report_new_sessions: {
    label: "New Sessions",
    explanation: "New Sessions from Bought Clicks",
    formula: (m) => m.site_report_sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  site_report_ffi: {
    label: "Prebid FFI",
    explanation: "Sum of the first 5 highest bids from Prebid, per session",
    formula: (m) => m.first_five_indicator / m.session_starts / 1e3 / 1e3,
    issStats: manualISSStats.site_ffi_per_session_starts,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/spent_by_section.ts
var calculatedSpentBySectionReportMetrics = SpentBySectionReportMetricMap({
  spent_by_section_report_clicks: {
    label: "Clicks Bought",
    explanation: "Clicks bought",
    formula: (m) => m.clicks_bought,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  spent_by_section_report_sessions: {
    label: "New Sessions",
    explanation: "User Sessions",
    formula: (m) => m.session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_session_click_ratio: {
    label: "Session Click Ratio",
    explanation: "New Sessions / Clicks",
    formula: (m) => m.spent_by_section_report_sessions / m.spent_by_section_report_clicks,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_cpc: {
    label: "CPC",
    explanation: "Cash per Click",
    formula: (m) => m.spent_by_section_report_spent / m.spent_by_section_report_clicks,
    issStats: manualISSStats.spent_by_section_spent_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  spent_by_section_report_spent: {
    label: "Spent",
    explanation: "Amount spent",
    formula: (m) => m.spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  spent_by_section_report_roi: {
    label: "ROI",
    explanation: "Return on Investment (Revenue / Spent)",
    formula: (m) => m.revenue / m.spent_by_section_report_spent,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_roi_with_forecast: {
    label: "ROI with Forecast",
    explanation: "Return on Investment (Revenue / Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast / m.spent_by_section_report_spent,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_profit: {
    label: "Profit",
    explanation: "Profit (Revenue - Spent)",
    formula: (m) => m.revenue - m.spent_by_section_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_profit_with_forecast: {
    label: "Profit with Forecast",
    explanation: "Profit (Revenue - Spent) incl. DA Forecast",
    formula: (m) => m.revenue_with_forecast - m.spent_by_section_report_spent,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_click_rpm: {
    label: "Click RPM",
    explanation: "Revenue per mille (thousand) clicks",
    formula: (m) => m.revenue / m.spent_by_section_report_clicks * 1e3,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_click_rpm_with_forecast: {
    label: "Click RPM with Forecast",
    explanation: "Revenue with DA forecast per mille (thousand) clicks",
    formula: (m) => m.revenue_with_forecast / m.spent_by_section_report_clicks * 1e3,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_click_revenue: {
    label: "Click Revenue",
    explanation: "Revenue per acquired Click",
    formula: (m) => m.revenue / m.spent_by_section_report_clicks,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_click_revenue_with_forecast: {
    label: "Click Revenue with Forecast",
    explanation: "Revenue with DA forecast per acquired Click",
    formula: (m) => m.revenue_with_forecast / m.spent_by_section_report_clicks,
    issStats: manualISSStats.spent_by_section_revenue_per_click,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_cps: {
    label: "CPS",
    explanation: "Cost per Session",
    formula: (m) => m.spent_by_section_report_spent / m.spent_by_section_report_sessions,
    issStats: manualISSStats.spent_by_section_spent_per_new_sessions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  spent_by_section_report_cpai: {
    label: "CPMAI",
    explanation: "Cost per Mille (thousand) Ad Impressions",
    formula: (m) => m.spent_by_section_report_spent / m.impressions * 1e3,
    issStats: manualISSStats.spent_by_section_spent_per_impressions,
    calculationType: CalculationType.Mean,
    decimalPlaces: 3,
    objective: OptimizationObjective.Minimize
  },
  spent_by_section_report_new_sessions: {
    label: "New Sessions",
    explanation: "New Sessions from Bought Clicks",
    formula: (m) => m.spent_by_section_report_sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  spent_by_section_report_ffi: {
    label: "Prebid FFI",
    explanation: "Sum of the first 5 highest bids from Prebid, per session",
    formula: (m) => m.first_five_indicator / m.session_starts / 1e3 / 1e3,
    issStats: manualISSStats.spent_by_section_ffi_per_session_starts,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/web.ts
var sessionTimeSpan2 = 30;
var calculatedWebMetrics = WebMetricMap({
  pageViews_per_session: {
    label: "Page Views per Session",
    explanation: "Average amount of monetized PageViews per Session",
    formula: (m) => m.pageViews / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan2,
    objective: OptimizationObjective.Maximize
  },
  pageViews_without_ads_ratio: {
    label: "Page Views without Ads",
    explanation: "Ratio of pageviews without ads",
    formula: (m) => (m.pageViews_ends - m.pageView_with_ads) / m.pageViews_ends,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  sessions_without_ads_ratio: {
    label: "Sessions without Ads",
    explanation: "Ratio of sessions without ads",
    formula: (m) => (m.sessions_ends - m.session_with_ads) / m.sessions_ends,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  ads_per_session: {
    label: "Ads per Session",
    explanation: "Average amount of ads per session",
    formula: (m) => m.impressions / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan2,
    objective: OptimizationObjective.Maximize
  },
  errors_per_session: {
    label: "Errors per Session",
    explanation: "Average amount of errors per session",
    formula: (m) => m.errors / m.sessions,
    calculationType: CalculationType.Mean,
    timeSpan: sessionTimeSpan2,
    objective: OptimizationObjective.Minimize
  },
  bounceRate: {
    label: "Bounce Rate",
    explanation: "Bounce Rate",
    formula: (m) => m.count_low_engagement / m.count_event_end,
    calculationType: CalculationType.ProportionRatio,
    objective: OptimizationObjective.Minimize
  },
  active_pageView_time_minutes: {
    label: "Active PV Minutes",
    explanation: "Average active page view time, in minutes",
    formula: (m) => m.sum_pageView_activeEngagementTime / m.pageViews / 1e3 / 60,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  active_session_time_minutes: {
    label: "Active Session Minutes",
    explanation: "Average active session time, in minutes",
    formula: (m) => m.sum_pageView_activeEngagementTime / m.sessions / 1e3 / 60,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  ads_per_pageView: {
    label: "Ads per Page View",
    explanation: "Ads per Page View",
    formula: (m) => m.impressions / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  errors_per_pageView: {
    label: "Errors per Page View",
    explanation: "Errors per Page View",
    formula: (m) => m.errors / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize
  },
  errors: {
    label: "Errors",
    explanation: "Count of errors",
    formula: (m) => m.errors,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  mean_scrollY: {
    label: "Scroll Y",
    explanation: "Average vertical scroll.",
    formula: (m) => m.scrollY / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Maximize
  },
  ...WebVitalsMetrics,
  pageViews: {
    label: "PageViews",
    explanation: "Page views",
    formula: (m) => m.pageViews,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  sessions: {
    label: "Unique Sessions",
    explanation: "Unique sessions",
    formula: (m) => m.sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  session_starts: {
    label: "New Sessions",
    explanation: "New Session",
    formula: (m) => m.session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  pageView_starts: {
    label: "New Page Views",
    explanation: "New Page Views",
    formula: (m) => m.pageView_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Maximize
  },
  acquisition_cost: {
    label: "Acquisition Cost",
    explanation: "Acquisition cost based on the configured URL parameters.",
    formula: (m) => m.acquisition_cost,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  acquisition_cost_measurable_session_starts: {
    label: "Acquisition Cost Available",
    explanation: "Count of events where acquisition cost is available.",
    formula: (m) => m.acquisition_cost_measurable_session_starts,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.Minimize
  },
  avg_network_time: {
    label: "Avg. Network Time",
    explanation: "The average time, measured in milliseconds, taken to establish a connection with the website server.",
    formula: (m) => m.nav_network_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_transfer_time: {
    label: "Avg. Transfer Time",
    explanation: "The average time, measured in milliseconds, that the browser takes to download the response from the server for each page view.",
    formula: (m) => m.nav_transfer_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_dom_completion_time: {
    label: "Avg. DOM Completion Time",
    explanation: "This measures the average time it takes for a webpage to load its main content and be ready for interaction, in milliseconds. It's about how quickly the text, images, and interactive elements are fully loaded and usable. A lower time is better, as it means the website becomes ready to use more quickly, leading to a smoother experience for the user.",
    formula: (m) => m.nav_dom_content_loading_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_page_load_time: {
    label: "Avg. Page Load Time",
    explanation: "This is the average time in milliseconds it takes for a full webpage to load and display. It includes everything from text and images to scripts and interactive features. A shorter load time is better, as it means the website loads faster, providing a quicker and more enjoyable experience for the user.",
    formula: (m) => m.nav_dom_content_loading_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_server_time: {
    label: "Avg. Server Time",
    explanation: "This measures the average time, in milliseconds, that the server needs to prepare and generate a webpage before it starts sending it to the user's browser. It's about how quickly the server can get the page ready. A lower time is better, as it means webpages start loading on the user's side more quickly, leading to a faster browsing experience.",
    formula: (m) => m.nav_server_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_dom_processing_time: {
    label: "Avg. DOM Processing Time",
    explanation: "This is the average processing time, in milliseconds, that the browser takes to process and display the webpage after getting all the necessary data from the server. It's about how quickly the browser turns this data into a visible and interactive webpage. A lower time is better as it means the website becomes fully functional and ready to use faster, improving the browsing experience.",
    formula: (m) => m.nav_dom_processing_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_on_load_time: {
    label: "Avg. On Load Time",
    explanation: "This measures the average processing time, in milliseconds, for the browser to fully render and display all the elements of the webpage. It's about how quickly everything on the page, like text, images, and buttons, becomes visible and ready to use after you open the page. A shorter time is better, as it means the webpage loads completely in less time, offering a quicker and more efficient browsing experience.",
    formula: (m) => m.nav_unload_event_time / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_encoded_body_size: {
    label: "Avg. Encoded Body Size",
    explanation: "This measures the average size, in kilobytes (kb), of all the content that is downloaded from a server to display a webpage. It includes everything like text, images, and videos in their compressed (encoded) form. A smaller size is better, as it means the webpage uses less data, which can lead to faster loading times and less strain on the user's internet connection.",
    formula: (m) => m.nav_encoded_body_size / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_decoded_body_size: {
    label: "Avg. Decoded Body Size",
    explanation: "This indicates the average size, in kilobytes (kb), of all the webpage content after it has been decompressed and is ready to be displayed. It encompasses all elements like text, images, and videos in their full, usable form. A lower size is preferable, as it means the webpage is lighter and can load faster, improving the user's experience by reducing loading times.",
    formula: (m) => m.nav_decoded_body_size / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  },
  avg_transfer_size: {
    label: "Avg. Transfer Size",
    explanation: "This measures the average size, in kilobytes (kb), of the HTTP headers for each webpage. HTTP headers are part of the data sent and received when a webpage is loaded, containing information like the type of browser used and the type of content expected. A smaller size is better, as it means less data is being sent and received, which can help the webpage load faster and more efficiently.",
    formula: (m) => m.nav_transfer_size / m.pageViews,
    calculationType: CalculationType.Mean,
    objective: OptimizationObjective.Minimize,
    issStats: manualISSStats.mock_web_avg
  }
});

// ../core/src/constants/calculated-metrics/data/by-placement/error_analytics.ts
var calculatedErrorAnalyticsMetrics = ErrorAnalyticsMetricMap({
  errors: {
    label: "Errors",
    explanation: "Sum of errors",
    formula: (m) => m.errors,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  },
  sessions: {
    label: "Sessions",
    explanation: "Sum of sessions",
    formula: (m) => m.sessions,
    calculationType: CalculationType.Sum,
    objective: OptimizationObjective.NoObjective
  }
});

// ../core/src/constants/time-granularity.constants.ts
var ALL_TIME_GRANULARITIES_EXCLUDING_NO = [
  "by_minute",
  "by_10_minutes",
  "by_hour",
  "by_day",
  "by_week",
  "by_month"
];
var ALL_TIME_GRANULARITIES = [...ALL_TIME_GRANULARITIES_EXCLUDING_NO, "no_granularity"];
var TIME_GRANULARITY_DURATION = {
  by_minute: 1e3 * 60,
  by_10_minutes: 1e3 * 60 * 10,
  by_hour: 1e3 * 60 * 60,
  by_day: 1e3 * 60 * 60 * 24,
  by_week: 1e3 * 60 * 60 * 24 * 7,
  by_month: 1e3 * 60 * 60 * 24 * 30,
  no_granularity: 0
};

// ../core/src/constants/organization-settings.constants.ts
var KvTag;
(function(KvTag2) {
  KvTag2["absolute"] = "absolute";
  KvTag2["rate"] = "rate";
})(KvTag || (KvTag = {}));
var ALL_KV_TAGS = getKeys(KvTag);
var KvTimeFrame;
(function(KvTimeFrame2) {
  KvTimeFrame2["one_second"] = "one_second";
  KvTimeFrame2["one_minute"] = "one_minute";
  KvTimeFrame2["one_hour"] = "one_hour";
  KvTimeFrame2["one_day"] = "one_day";
})(KvTimeFrame || (KvTimeFrame = {}));
var ALL_KV_TIMEFRAME = getKeys(KvTimeFrame);
var KvOptimizationTarget;
(function(KvOptimizationTarget2) {
  KvOptimizationTarget2["reduction_rate"] = "reduction_rate";
  KvOptimizationTarget2["revenue_loss_rate"] = "revenue_loss_rate";
  KvOptimizationTarget2["reduction_efficiency"] = "reduction_efficiency";
})(KvOptimizationTarget || (KvOptimizationTarget = {}));
var ALL_KV_OPTIMIZATION_TARGETS = getKeys(KvOptimizationTarget);

// src/APISpentDimensionsTable.tsx
import React9, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

// ../../node_modules/@babel/runtime/helpers/esm/extends.js
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

// ../../node_modules/@babel/runtime/helpers/esm/objectWithoutPropertiesLoose.js
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  for (var key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (excluded.indexOf(key) >= 0)
        continue;
      target[key] = source[key];
    }
  }
  return target;
}

// ../../node_modules/reactstrap/es/utils.js
import PropTypes from "prop-types";
var globalCssModule;
function mapToCssModules(className, cssModule) {
  if (className === void 0) {
    className = "";
  }
  if (cssModule === void 0) {
    cssModule = globalCssModule;
  }
  if (!cssModule)
    return className;
  return className.split(" ").map(function(c) {
    return cssModule[c] || c;
  }).join(" ");
}
function omit(obj, omitKeys) {
  var result = {};
  Object.keys(obj).forEach(function(key) {
    if (omitKeys.indexOf(key) === -1) {
      result[key] = obj[key];
    }
  });
  return result;
}
var Element = typeof window === "object" && window.Element || function() {
};
function DOMElement(props, propName, componentName) {
  if (!(props[propName] instanceof Element)) {
    return new Error("Invalid prop `" + propName + "` supplied to `" + componentName + "`. Expected prop to be an instance of Element. Validation failed.");
  }
}
var targetPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.func, DOMElement, PropTypes.shape({
  current: PropTypes.any
})]);
var tagPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.shape({
  $$typeof: PropTypes.symbol,
  render: PropTypes.func
}), PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.shape({
  $$typeof: PropTypes.symbol,
  render: PropTypes.func
})]))]);
var canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);
function isObject(value) {
  var type = typeof value;
  return value != null && (type === "object" || type === "function");
}

// ../../node_modules/reactstrap/es/Row.js
import React from "react";
import PropTypes2 from "prop-types";
import classNames from "classnames";
var _excluded = ["className", "cssModule", "noGutters", "tag", "form", "widths"];
var rowColWidths = ["xs", "sm", "md", "lg", "xl"];
var rowColsPropType = PropTypes2.oneOfType([PropTypes2.number, PropTypes2.string]);
var propTypes = {
  tag: tagPropType,
  noGutters: PropTypes2.bool,
  className: PropTypes2.string,
  cssModule: PropTypes2.object,
  form: PropTypes2.bool,
  xs: rowColsPropType,
  sm: rowColsPropType,
  md: rowColsPropType,
  lg: rowColsPropType,
  xl: rowColsPropType
};
var defaultProps = {
  tag: "div",
  widths: rowColWidths
};
var Row = function Row2(props) {
  var className = props.className, cssModule = props.cssModule, noGutters = props.noGutters, Tag = props.tag, form = props.form, widths = props.widths, attributes = _objectWithoutPropertiesLoose(props, _excluded);
  var colClasses = [];
  widths.forEach(function(colWidth, i) {
    var colSize = props[colWidth];
    delete attributes[colWidth];
    if (!colSize) {
      return;
    }
    var isXs = !i;
    colClasses.push(isXs ? "row-cols-" + colSize : "row-cols-" + colWidth + "-" + colSize);
  });
  var classes = mapToCssModules(classNames(className, noGutters ? "no-gutters" : null, form ? "form-row" : "row", colClasses), cssModule);
  return /* @__PURE__ */ React.createElement(Tag, _extends({}, attributes, {
    className: classes
  }));
};
Row.propTypes = propTypes;
Row.defaultProps = defaultProps;
var Row_default = Row;

// ../../node_modules/reactstrap/es/Col.js
import React2 from "react";
import PropTypes3 from "prop-types";
import classNames2 from "classnames";
var _excluded2 = ["className", "cssModule", "widths", "tag"];
var colWidths = ["xs", "sm", "md", "lg", "xl"];
var stringOrNumberProp = PropTypes3.oneOfType([PropTypes3.number, PropTypes3.string]);
var columnProps = PropTypes3.oneOfType([PropTypes3.bool, PropTypes3.number, PropTypes3.string, PropTypes3.shape({
  size: PropTypes3.oneOfType([PropTypes3.bool, PropTypes3.number, PropTypes3.string]),
  order: stringOrNumberProp,
  offset: stringOrNumberProp
})]);
var propTypes2 = {
  tag: tagPropType,
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
  className: PropTypes3.string,
  cssModule: PropTypes3.object,
  widths: PropTypes3.array
};
var defaultProps2 = {
  tag: "div",
  widths: colWidths
};
var getColumnSizeClass = function getColumnSizeClass2(isXs, colWidth, colSize) {
  if (colSize === true || colSize === "") {
    return isXs ? "col" : "col-" + colWidth;
  } else if (colSize === "auto") {
    return isXs ? "col-auto" : "col-" + colWidth + "-auto";
  }
  return isXs ? "col-" + colSize : "col-" + colWidth + "-" + colSize;
};
var Col = function Col2(props) {
  var className = props.className, cssModule = props.cssModule, widths = props.widths, Tag = props.tag, attributes = _objectWithoutPropertiesLoose(props, _excluded2);
  var colClasses = [];
  widths.forEach(function(colWidth, i) {
    var columnProp = props[colWidth];
    delete attributes[colWidth];
    if (!columnProp && columnProp !== "") {
      return;
    }
    var isXs = !i;
    if (isObject(columnProp)) {
      var _classNames;
      var colSizeInterfix = isXs ? "-" : "-" + colWidth + "-";
      var colClass = getColumnSizeClass(isXs, colWidth, columnProp.size);
      colClasses.push(mapToCssModules(classNames2((_classNames = {}, _classNames[colClass] = columnProp.size || columnProp.size === "", _classNames["order" + colSizeInterfix + columnProp.order] = columnProp.order || columnProp.order === 0, _classNames["offset" + colSizeInterfix + columnProp.offset] = columnProp.offset || columnProp.offset === 0, _classNames)), cssModule));
    } else {
      var _colClass = getColumnSizeClass(isXs, colWidth, columnProp);
      colClasses.push(_colClass);
    }
  });
  if (!colClasses.length) {
    colClasses.push("col");
  }
  var classes = mapToCssModules(classNames2(className, colClasses), cssModule);
  return /* @__PURE__ */ React2.createElement(Tag, _extends({}, attributes, {
    className: classes
  }));
};
Col.propTypes = propTypes2;
Col.defaultProps = defaultProps2;
var Col_default = Col;

// ../../node_modules/reactstrap/es/Nav.js
import React3 from "react";
import PropTypes4 from "prop-types";
import classNames3 from "classnames";
var _excluded3 = ["className", "cssModule", "tabs", "pills", "vertical", "horizontal", "justified", "fill", "navbar", "card", "tag"];
var propTypes3 = {
  tabs: PropTypes4.bool,
  pills: PropTypes4.bool,
  vertical: PropTypes4.oneOfType([PropTypes4.bool, PropTypes4.string]),
  horizontal: PropTypes4.string,
  justified: PropTypes4.bool,
  fill: PropTypes4.bool,
  navbar: PropTypes4.bool,
  card: PropTypes4.bool,
  tag: tagPropType,
  className: PropTypes4.string,
  cssModule: PropTypes4.object
};
var defaultProps3 = {
  tag: "ul",
  vertical: false
};
var getVerticalClass = function getVerticalClass2(vertical) {
  if (vertical === false) {
    return false;
  } else if (vertical === true || vertical === "xs") {
    return "flex-column";
  }
  return "flex-" + vertical + "-column";
};
var Nav = function Nav2(props) {
  var className = props.className, cssModule = props.cssModule, tabs = props.tabs, pills = props.pills, vertical = props.vertical, horizontal = props.horizontal, justified = props.justified, fill = props.fill, navbar = props.navbar, card = props.card, Tag = props.tag, attributes = _objectWithoutPropertiesLoose(props, _excluded3);
  var classes = mapToCssModules(classNames3(className, navbar ? "navbar-nav" : "nav", horizontal ? "justify-content-" + horizontal : false, getVerticalClass(vertical), {
    "nav-tabs": tabs,
    "card-header-tabs": card && tabs,
    "nav-pills": pills,
    "card-header-pills": card && pills,
    "nav-justified": justified,
    "nav-fill": fill
  }), cssModule);
  return /* @__PURE__ */ React3.createElement(Tag, _extends({}, attributes, {
    className: classes
  }));
};
Nav.propTypes = propTypes3;
Nav.defaultProps = defaultProps3;
var Nav_default = Nav;

// ../../node_modules/reactstrap/es/NavItem.js
import React4 from "react";
import PropTypes5 from "prop-types";
import classNames4 from "classnames";
var _excluded4 = ["className", "cssModule", "active", "tag"];
var propTypes4 = {
  tag: tagPropType,
  active: PropTypes5.bool,
  className: PropTypes5.string,
  cssModule: PropTypes5.object
};
var defaultProps4 = {
  tag: "li"
};
var NavItem = function NavItem2(props) {
  var className = props.className, cssModule = props.cssModule, active = props.active, Tag = props.tag, attributes = _objectWithoutPropertiesLoose(props, _excluded4);
  var classes = mapToCssModules(classNames4(className, "nav-item", active ? "active" : false), cssModule);
  return /* @__PURE__ */ React4.createElement(Tag, _extends({}, attributes, {
    className: classes
  }));
};
NavItem.propTypes = propTypes4;
NavItem.defaultProps = defaultProps4;
var NavItem_default = NavItem;

// ../../node_modules/@babel/runtime/helpers/esm/assertThisInitialized.js
function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return self;
}

// ../../node_modules/@babel/runtime/helpers/esm/setPrototypeOf.js
function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function _setPrototypeOf2(o2, p2) {
    o2.__proto__ = p2;
    return o2;
  };
  return _setPrototypeOf(o, p);
}

// ../../node_modules/@babel/runtime/helpers/esm/inheritsLoose.js
function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  _setPrototypeOf(subClass, superClass);
}

// ../../node_modules/reactstrap/es/NavLink.js
import React5 from "react";
import PropTypes6 from "prop-types";
import classNames5 from "classnames";
var _excluded5 = ["className", "cssModule", "active", "tag", "innerRef"];
var propTypes5 = {
  tag: tagPropType,
  innerRef: PropTypes6.oneOfType([PropTypes6.object, PropTypes6.func, PropTypes6.string]),
  disabled: PropTypes6.bool,
  active: PropTypes6.bool,
  className: PropTypes6.string,
  cssModule: PropTypes6.object,
  onClick: PropTypes6.func,
  href: PropTypes6.any
};
var defaultProps5 = {
  tag: "a"
};
var NavLink = /* @__PURE__ */ function(_React$Component) {
  _inheritsLoose(NavLink2, _React$Component);
  function NavLink2(props) {
    var _this;
    _this = _React$Component.call(this, props) || this;
    _this.onClick = _this.onClick.bind(_assertThisInitialized(_this));
    return _this;
  }
  var _proto = NavLink2.prototype;
  _proto.onClick = function onClick(e) {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }
    if (this.props.href === "#") {
      e.preventDefault();
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
  _proto.render = function render() {
    var _this$props = this.props, className = _this$props.className, cssModule = _this$props.cssModule, active = _this$props.active, Tag = _this$props.tag, innerRef = _this$props.innerRef, attributes = _objectWithoutPropertiesLoose(_this$props, _excluded5);
    var classes = mapToCssModules(classNames5(className, "nav-link", {
      disabled: attributes.disabled,
      active
    }), cssModule);
    return /* @__PURE__ */ React5.createElement(Tag, _extends({}, attributes, {
      ref: innerRef,
      onClick: this.onClick,
      className: classes
    }));
  };
  return NavLink2;
}(React5.Component);
NavLink.propTypes = propTypes5;
NavLink.defaultProps = defaultProps5;
var NavLink_default = NavLink;

// ../../node_modules/reactstrap/es/TabContent.js
import React7, { Component } from "react";
import PropTypes7 from "prop-types";
import classNames6 from "classnames";

// ../../node_modules/reactstrap/es/TabContext.js
import React6 from "react";
var TabContext = /* @__PURE__ */ React6.createContext({});

// ../../node_modules/reactstrap/es/TabContent.js
var propTypes6 = {
  tag: tagPropType,
  activeTab: PropTypes7.any,
  className: PropTypes7.string,
  cssModule: PropTypes7.object
};
var defaultProps6 = {
  tag: "div"
};
var TabContent = /* @__PURE__ */ function(_Component) {
  _inheritsLoose(TabContent2, _Component);
  TabContent2.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.activeTab !== nextProps.activeTab) {
      return {
        activeTab: nextProps.activeTab
      };
    }
    return null;
  };
  function TabContent2(props) {
    var _this;
    _this = _Component.call(this, props) || this;
    _this.state = {
      activeTab: _this.props.activeTab
    };
    return _this;
  }
  var _proto = TabContent2.prototype;
  _proto.render = function render() {
    var _this$props = this.props, className = _this$props.className, cssModule = _this$props.cssModule, Tag = _this$props.tag;
    var attributes = omit(this.props, Object.keys(propTypes6));
    var classes = mapToCssModules(classNames6("tab-content", className), cssModule);
    return /* @__PURE__ */ React7.createElement(TabContext.Provider, {
      value: {
        activeTabId: this.state.activeTab
      }
    }, /* @__PURE__ */ React7.createElement(Tag, _extends({}, attributes, {
      className: classes
    })));
  };
  return TabContent2;
}(Component);
var TabContent_default = TabContent;
TabContent.propTypes = propTypes6;
TabContent.defaultProps = defaultProps6;

// ../../node_modules/reactstrap/es/TabPane.js
import React8 from "react";
import PropTypes8 from "prop-types";
import classNames7 from "classnames";
var _excluded6 = ["className", "cssModule", "tabId", "tag"];
var propTypes7 = {
  tag: tagPropType,
  className: PropTypes8.string,
  cssModule: PropTypes8.object,
  tabId: PropTypes8.any
};
var defaultProps7 = {
  tag: "div"
};
function TabPane(props) {
  var className = props.className, cssModule = props.cssModule, tabId = props.tabId, Tag = props.tag, attributes = _objectWithoutPropertiesLoose(props, _excluded6);
  var getClasses = function getClasses2(activeTabId) {
    return mapToCssModules(classNames7("tab-pane", className, {
      active: tabId === activeTabId
    }), cssModule);
  };
  return /* @__PURE__ */ React8.createElement(TabContext.Consumer, null, function(_ref) {
    var activeTabId = _ref.activeTabId;
    return /* @__PURE__ */ React8.createElement(Tag, _extends({}, attributes, {
      className: getClasses(activeTabId)
    }));
  });
}
TabPane.propTypes = propTypes7;
TabPane.defaultProps = defaultProps7;

// src/APISpentDimensionsTable.tsx
var DimensionTable = (props) => {
  const columns = [
    {
      text: "Dimension",
      dataField: "title",
      sort: true,
      filter: textFilter({ placeholder: "Search..." })
    },
    {
      text: "ID",
      dataField: "id",
      formatter: (cell) => /* @__PURE__ */ React9.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true,
      filter: textFilter({ placeholder: "Search..." })
    }
  ];
  return /* @__PURE__ */ React9.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React9.createElement(BootstrapTable, {
    data: props.data,
    columns,
    keyField: "title",
    pagination: paginationFactory({}),
    filter: filterFactory(),
    bootstrap4: true,
    condensed: true
  }));
};
var adTableData = [
  "platform",
  "campaign_name",
  "adset_name",
  "ad_name",
  "campaign_id",
  "adset_id",
  "ad_id",
  "sub_account_id",
  "sub_account_name"
].map((id) => {
  const dimensionId = EXPOSED_SPENT_API_AD_DIMENSION_IDS_MAPPING[id];
  const title = dimensions[dimensionId].label;
  return { id, title };
});
var publisherTableData = [
  "site",
  "platform",
  "site_name",
  "site_url",
  "site_id",
  "campaign_name",
  "campaign_id",
  "sub_account_id",
  "sub_account_name"
].map((id) => {
  const dimensionId = EXPOSED_SPENT_API_PUBLISHER_DIMENSION_IDS_MAPPING[id];
  const title = dimensions[dimensionId].label;
  return { id, title };
});
var sectionTableData = [
  "platform",
  "section_name",
  "section_url",
  "section_id",
  "campaign_name",
  "campaign_id",
  "sub_account_id",
  "sub_account_name"
].map((id) => {
  const dimensionId = EXPOSED_SPENT_API_SECTION_DIMENSION_IDS_MAPPING[id];
  const title = dimensions[dimensionId].label;
  return { id, title };
});
var APISpentDimensionsTable = () => {
  const [activeTab, setActiveTab] = useState("1");
  return /* @__PURE__ */ React9.createElement("div", null, /* @__PURE__ */ React9.createElement(Nav_default, {
    tabs: true
  }, /* @__PURE__ */ React9.createElement(NavItem_default, null, /* @__PURE__ */ React9.createElement(NavLink_default, {
    className: activeTab === "1" ? "active" : "",
    onClick: () => {
      setActiveTab("1");
    }
  }, "Level: ad")), /* @__PURE__ */ React9.createElement(NavItem_default, null, /* @__PURE__ */ React9.createElement(NavLink_default, {
    className: activeTab === "2" ? "active" : "",
    onClick: () => {
      setActiveTab("2");
    }
  }, "Level: publisher")), /* @__PURE__ */ React9.createElement(NavItem_default, null, /* @__PURE__ */ React9.createElement(NavLink_default, {
    className: activeTab === "3" ? "active" : "",
    onClick: () => {
      setActiveTab("3");
    }
  }, "Level: section"))), /* @__PURE__ */ React9.createElement(TabContent_default, {
    activeTab
  }, /* @__PURE__ */ React9.createElement(TabPane, {
    tabId: "1"
  }, /* @__PURE__ */ React9.createElement(Row_default, null, /* @__PURE__ */ React9.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React9.createElement(DimensionTable, {
    data: adTableData
  })))), /* @__PURE__ */ React9.createElement(TabPane, {
    tabId: "2"
  }, /* @__PURE__ */ React9.createElement(Row_default, null, /* @__PURE__ */ React9.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React9.createElement(DimensionTable, {
    data: publisherTableData
  })))), /* @__PURE__ */ React9.createElement(TabPane, {
    tabId: "3"
  }, /* @__PURE__ */ React9.createElement(Row_default, null, /* @__PURE__ */ React9.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React9.createElement(DimensionTable, {
    data: sectionTableData
  }))))));
};

// src/APICurrencyTable.tsx
import React10 from "react";
import BootstrapTable2 from "react-bootstrap-table-next";
import filterFactory2, { textFilter as textFilter2 } from "react-bootstrap-table2-filter";
import paginationFactory2 from "react-bootstrap-table2-paginator";
var APICurrencyTable = () => {
  const formattedTimezones = ALL_CURRENCIES.map((it) => ({ label: it, value: it }));
  const columns = [
    {
      text: "Currency",
      dataField: "label",
      sort: true,
      filter: textFilter2({ placeholder: "Search..." })
    }
  ];
  return /* @__PURE__ */ React10.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React10.createElement(BootstrapTable2, {
    data: formattedTimezones,
    columns,
    pagination: paginationFactory2({}),
    filter: filterFactory2(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }));
};

// src/APIDimensionTable.tsx
import React11 from "react";
import BootstrapTable3 from "react-bootstrap-table-next";
import filterFactory3, { textFilter as textFilter3 } from "react-bootstrap-table2-filter";
import paginationFactory3 from "react-bootstrap-table2-paginator";
var getDimensionTableData = (analyticsReport) => {
  const data = std_exports.getKeys(dimensions).filter((dimensionId) => !dimensions[dimensionId].isTombstone).filter((dimensionId) => !dimensions[dimensionId].limitedToAdminOrDev).filter((dimensionId) => {
    switch (analyticsReport) {
      case "yield": {
        return (dimensions[dimensionId].analytics_reports.includes(analyticsReport) || dimensions[dimensionId].analytics_reports.includes("yield_api")) && !dimensions[dimensionId].hideFromApiDocs;
      }
      case "web": {
        return (dimensions[dimensionId].analytics_reports.includes(analyticsReport) || dimensions[dimensionId].analytics_reports.includes("web_api")) && !dimensions[dimensionId].hideFromApiDocs;
      }
    }
  }).map((dimensionId) => {
    return {
      title: dimensions[dimensionId].label,
      description: dimensions[dimensionId].description,
      dimensionId
    };
  }).sort((a, b) => String(a == null ? void 0 : a.dimensionId).localeCompare(String(b == null ? void 0 : b.dimensionId)));
  return data;
};
var APIDimensionTable = ({ analyticsReport = "yield" }) => {
  const columns = [
    {
      text: "Dimension",
      dataField: "title",
      sort: true,
      filter: textFilter3({ placeholder: "Search..." })
    },
    {
      text: "ID",
      dataField: "dimensionId",
      formatter: (cell) => /* @__PURE__ */ React11.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true,
      filter: textFilter3({ placeholder: "Search..." })
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      filter: textFilter3({ placeholder: "Search..." })
    }
  ];
  return /* @__PURE__ */ React11.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React11.createElement(BootstrapTable3, {
    data: getDimensionTableData(analyticsReport),
    columns,
    keyField: "title",
    pagination: paginationFactory3({}),
    filter: filterFactory3(),
    bootstrap4: true,
    condensed: true
  }));
};

// src/APIFilterTable.tsx
import React12 from "react";
import BootstrapTable4 from "react-bootstrap-table-next";
import filterFactory4, { textFilter as textFilter4 } from "react-bootstrap-table2-filter";
import paginationFactory4 from "react-bootstrap-table2-paginator";
var getFilterTableData = (analyticsReport) => {
  const data = Object.values(dimensions).filter((it) => !it.isTombstone).filter((it) => !it.limitedToAdminOrDev).filter((it) => {
    var _a;
    return ((_a = it == null ? void 0 : it.analytics_reports) != null ? _a : []).includes(analyticsReport);
  }).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label)).filter((it) => it.id !== "dfpHashRaw");
  return data;
};
var APIFilterTable = ({ analyticsReport = "yield" }) => {
  const columns = [
    {
      text: "Filter",
      dataField: "label",
      filter: textFilter4({ placeholder: "Search..." }),
      sort: true
    },
    {
      text: "ID",
      dataField: "id",
      filter: textFilter4({ placeholder: "Search..." }),
      sort: true,
      formatter: (cell) => /* @__PURE__ */ React12.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell)
    }
  ];
  return /* @__PURE__ */ React12.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React12.createElement(BootstrapTable4, {
    data: getFilterTableData(analyticsReport),
    pagination: paginationFactory4({}),
    filter: filterFactory4(),
    columns,
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }));
};

// src/APITimezoneTable.tsx
import React13 from "react";
import BootstrapTable5 from "react-bootstrap-table-next";
import filterFactory5, { textFilter as textFilter5 } from "react-bootstrap-table2-filter";
import paginationFactory5 from "react-bootstrap-table2-paginator";
var APITimezoneTable = () => {
  const formattedTimezones = ALL_TIMEZONES.map((it) => ({ label: it, value: it })).filter((entry) => !entry.label.includes("Etc") && !entry.label.includes("GMT")).map((entry) => {
    return {
      label: zuluTimezones.includes(entry.label) ? `${entry.label} \u26A1` : entry.label,
      value: entry.value
    };
  });
  const columns = [
    {
      text: "Timezone",
      dataField: "label",
      sort: true,
      filter: textFilter5({ placeholder: "Search..." })
    },
    {
      text: "ID",
      dataField: "value",
      sort: true,
      filter: textFilter5({ placeholder: "Search..." }),
      formatter: (cell) => /* @__PURE__ */ React13.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell)
    }
  ];
  return /* @__PURE__ */ React13.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React13.createElement(BootstrapTable5, {
    data: formattedTimezones,
    columns,
    pagination: paginationFactory5({}),
    filter: filterFactory5(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }));
};

// src/APIUploadDimensions.tsx
import React14 from "react";
import BootstrapTable6 from "react-bootstrap-table-next";
import filterFactory6, { textFilter as textFilter6 } from "react-bootstrap-table2-filter";
import paginationFactory6 from "react-bootstrap-table2-paginator";
var APIUploadDimensions = () => {
  const columns = [
    {
      text: "Dimension",
      dataField: "label",
      sort: true,
      filter: textFilter6({ placeholder: "Search..." })
    },
    {
      text: "ID",
      dataField: "id",
      formatter: (cell) => /* @__PURE__ */ React14.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true,
      filter: textFilter6({ placeholder: "Search..." })
    }
  ];
  return /* @__PURE__ */ React14.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React14.createElement(BootstrapTable6, {
    data: MAPPING_ALLOWED_ORIGINAL_DIMENSIONS,
    columns,
    keyField: "label",
    pagination: paginationFactory6({}),
    filter: filterFactory6(),
    bootstrap4: true,
    condensed: true
  }));
};

// src/ApiWebMetricTable.tsx
import React15 from "react";
import BootstrapTable7 from "react-bootstrap-table-next";
import filterFactory7, { textFilter as textFilter7 } from "react-bootstrap-table2-filter";
import paginationFactory7 from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
var getWebMetrics = () => {
  return Object.entries(rawMetricsWeb).filter(([_key, value]) => {
    return !(value == null ? void 0 : value.hideFromApi);
  }).map(([key, _value]) => {
    return {
      id: key,
      label: key,
      rawDependencies: [],
      formula: ""
    };
  }).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label));
};
var APIWebMetricTable = ({ sizePerPage = 10 }) => {
  const columns = [
    {
      text: "Metric",
      dataField: "label",
      filter: textFilter7({ placeholder: "Search..." }),
      sort: true
    },
    {
      text: "ID",
      filter: textFilter7({ placeholder: "Search..." }),
      dataField: "id",
      formatter: (cell) => /* @__PURE__ */ React15.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true
    }
  ];
  return /* @__PURE__ */ React15.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React15.createElement(BootstrapTable7, {
    data: getWebMetrics(),
    columns,
    pagination: paginationFactory7({
      sizePerPage
    }),
    filter: filterFactory7(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }));
};

// src/APIWebReportTable.tsx
import React16, { useState as useState2 } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
var APIWebReportTables = () => {
  const [activeTab, setActiveTab] = useState2("1");
  return /* @__PURE__ */ React16.createElement("div", null, /* @__PURE__ */ React16.createElement(Nav_default, {
    tabs: true
  }, /* @__PURE__ */ React16.createElement(NavItem_default, null, /* @__PURE__ */ React16.createElement(NavLink_default, {
    className: activeTab === "1" ? "active" : "",
    onClick: () => {
      setActiveTab("1");
    }
  }, "Dimensions")), /* @__PURE__ */ React16.createElement(NavItem_default, null, /* @__PURE__ */ React16.createElement(NavLink_default, {
    className: activeTab === "2" ? "active" : "",
    onClick: () => {
      setActiveTab("2");
    }
  }, "Metrics")), /* @__PURE__ */ React16.createElement(NavItem_default, null, /* @__PURE__ */ React16.createElement(NavLink_default, {
    className: activeTab === "3" ? "active" : "",
    onClick: () => {
      setActiveTab("3");
    }
  }, "Filters")), /* @__PURE__ */ React16.createElement(NavItem_default, null, /* @__PURE__ */ React16.createElement(NavLink_default, {
    className: activeTab === "4" ? "active" : "",
    onClick: () => {
      setActiveTab("4");
    }
  }, "Timezone"))), /* @__PURE__ */ React16.createElement(TabContent_default, {
    activeTab
  }, /* @__PURE__ */ React16.createElement(TabPane, {
    tabId: "1"
  }, /* @__PURE__ */ React16.createElement(Row_default, null, /* @__PURE__ */ React16.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React16.createElement(APIDimensionTable, {
    analyticsReport: "web"
  })))), /* @__PURE__ */ React16.createElement(TabPane, {
    tabId: "2"
  }, /* @__PURE__ */ React16.createElement(Row_default, null, /* @__PURE__ */ React16.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React16.createElement(APIWebMetricTable, null)))), /* @__PURE__ */ React16.createElement(TabPane, {
    tabId: "3"
  }, /* @__PURE__ */ React16.createElement(Row_default, null, /* @__PURE__ */ React16.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React16.createElement(APIFilterTable, {
    analyticsReport: "web"
  })))), /* @__PURE__ */ React16.createElement(TabPane, {
    tabId: "4"
  }, /* @__PURE__ */ React16.createElement(Row_default, null, /* @__PURE__ */ React16.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React16.createElement(APITimezoneTable, null))))));
};

// ../core/src/constants/calculated-metrics/parsed-metrics/pretty-data.js
var parsedPrettyJsMetrics = {
  "yield": {
    "ad_unfilled": "ad_unfilled_count",
    "ivt_rate": "(givt_impressions + sivt_impressions) / ivt_measurable_impressions",
    "givt_rate": "givt_impressions / ivt_measurable_impressions",
    "sivt_rate": "sivt_impressions / ivt_measurable_impressions",
    "impressions": "impressions",
    "impressions_filled": "impressions_filled",
    "impressions_filled_rate": "impressions_filled / impressions",
    "impressions_unfilled": "impressions_unfilled",
    "impressions_unfilled_rate": "impressions_unfilled / impressions",
    "impressions_unfilled_n_house": "impressions_lineitem_house + impressions_unfilled",
    "impressions_unfilled_n_house_rate": "(impressions_lineitem_house + impressions_unfilled) / impressions",
    "pageViews": "pageViews",
    "pageViewsExact": "pageViewsExact",
    "sessions": "sessions",
    "session_starts": "session_starts",
    "pageView_starts": "pageView_starts",
    "ads_per_pageView": "impressions / pageViews",
    "ads_per_pageViewExact": "impressions / pageViewsExact",
    "ads_per_session": "impressions / sessions",
    "revenue": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue",
    "revenue_with_forecast": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue",
    "clicks": "clicks",
    "rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions * 1000",
    "rpm_with_forecast": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions * 1000",
    "valueCpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "pageView_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / pageViews * 1000",
    "pageView_valueRpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "session_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions * 1000",
    "session_start_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / session_starts * 1000",
    "session_rpm_with_forecast": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions * 1000",
    "session_valueRpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue) / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "pageViews_per_session": "pageViews / sessions",
    "pageViewsExact_per_session": "pageViewsExact / sessions",
    "viewability": "viewable / viewable_measurable_impressions",
    "ctr": "clicks / impressions_filled * 100",
    "cbr": "clicksBounced / clicks",
    "clr": "(clicks - clicksReturned) / clicks",
    "direct_impressions": "direct_impressions",
    "direct_revenue": "direct_revenue / 1000000",
    "direct_rpm": "direct_revenue / 1000000 / direct_impressions * 1000",
    "direct_valueRpm": "direct_revenue / 1000000 / direct_impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "direct_winRate": "direct_impressions / impressions",
    "direct_pageView_rpm": "direct_revenue / 1000000 / pageViews * 1000",
    "direct_pageView_valueRpm": "direct_revenue / 1000000 / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "direct_session_rpm": "direct_revenue / 1000000 / sessions * 1000",
    "direct_session_valueRpm": "direct_revenue / 1000000 / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "programmatic_impressions": "prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)",
    "programmatic_revenue": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)",
    "programmatic_revenue_with_forecast": "prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)",
    "programmatic_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / (prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)) * 1000",
    "programmatic_valueRpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / (prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)) * 1000 * (viewable / viewable_measurable_impressions)",
    "programmatic_winRate": "(prebid_won_impressions + dynamicAllocation_impressions + adSense_impressions + aps_impressions + (outbrain_impressions + taboola_impressions + yahooGemini_impressions)) / impressions",
    "programmatic_pageView_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / pageViews * 1000",
    "programmatic_pageView_valueRpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "programmatic_session_rpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions * 1000",
    "programmatic_session_rpm_with_forecast": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue_with_forecast / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions * 1000",
    "programmatic_session_valueRpm": "(prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + adSense_revenue / 1000000 + aps_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000)) / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "nativo_impressions": "nativo_impressions",
    "nativo_revenue": "nativo_revenue",
    "nativo_rpm": "nativo_revenue / nativo_impressions * 1000",
    "nativo_pageView_rpm": "nativo_revenue / pageViews * 1000",
    "nativo_session_rpm": "nativo_revenue / sessions * 1000",
    "nativo_winRate": "nativo_impressions / impressions",
    "content_recommendation_impressions": "outbrain_impressions + taboola_impressions + yahooGemini_impressions",
    "content_recommendation_revenue": "outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000",
    "content_recommendation_rpm": "(outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / (outbrain_impressions + taboola_impressions + yahooGemini_impressions) * 1000",
    "content_recommendation_pageView_rpm": "(outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / pageViews * 1000",
    "content_recommendation_session_rpm": "(outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) / sessions * 1000",
    "content_recommendation_winRate": "(outbrain_impressions + taboola_impressions + yahooGemini_impressions) / impressions",
    "dynamicAllocation_impressions": "dynamicAllocation_impressions",
    "dynamicAllocation_revenue": "dynamicAllocation_revenue / 1000000",
    "dynamicAllocation_revenue_with_forecast": "dynamicAllocation_revenue_with_forecast / 1000000",
    "dynamicAllocation_revenue_with_forecast_client": "dynamicAllocation_revenue_with_forecast_client / 1000000",
    "dynamicAllocation_rpm": "dynamicAllocation_revenue / 1000000 / dynamicAllocation_impressions * 1000",
    "dynamicAllocation_valueRpm": "dynamicAllocation_revenue / 1000000 / dynamicAllocation_impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "dynamicAllocation_winRate": "dynamicAllocation_impressions / impressions",
    "dynamicAllocation_pageView_rpm": "dynamicAllocation_revenue / 1000000 / pageViews * 1000",
    "dynamicAllocation_pageViewExact_rpm": "dynamicAllocation_revenue / 1000000 / pageViewsExact * 1000",
    "dynamicAllocation_pageView_valueRpm": "dynamicAllocation_revenue / 1000000 / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "dynamicAllocation_session_rpm": "dynamicAllocation_revenue / 1000000 / sessions * 1000",
    "dynamicAllocation_session_rpm_with_forecast": "dynamicAllocation_revenue_with_forecast / 1000000 / sessions * 1000",
    "dynamicAllocation_session_valueRpm": "dynamicAllocation_revenue / 1000000 / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "acquisition_cost": "acquisition_cost / 1000000",
    "acquisition_cost_measurable_session_starts": "acquisition_cost_measurable_session_starts",
    "prebid_rpm": "prebid_revenue / 1000000 / 1000 / impressions * 1000",
    "prebid_cache_rate": "prebid_cached_count / prebid_won_impressions",
    "prebid_valueRpm": "prebid_revenue / 1000000 / 1000 / impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_winRate": "prebid_won_impressions / impressions",
    "prebid_revenue": "prebid_revenue / 1000000 / 1000",
    "prebid_pageView_rpm": "prebid_revenue / 1000000 / 1000 / pageViews * 1000",
    "prebid_pageView_valueRpm": "prebid_revenue / 1000000 / 1000 / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_session_rpm": "prebid_revenue / 1000000 / 1000 / sessions * 1000",
    "prebid_session_valueRpm": "prebid_revenue / 1000000 / 1000 / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_won_impressions": "prebid_won_impressions",
    "prebid_won_rpm": "prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions * 1000",
    "prebid_won_valueRpm": "prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_won_revenue": "prebid_won_revenue / 1000000 / 1000",
    "prebid_won_pageView_rpm": "prebid_won_revenue / 1000000 / 1000 / pageViews * 1000",
    "prebid_won_pageViewExact_rpm": "prebid_won_revenue / 1000000 / 1000 / pageViewsExact * 1000",
    "prebid_won_pageView_valueRpm": "prebid_won_revenue / 1000000 / 1000 / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_won_session_rpm": "prebid_won_revenue / 1000000 / 1000 / sessions * 1000",
    "prebid_won_session_valueRpm": "prebid_won_revenue / 1000000 / 1000 / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_lost_impressions": "prebid_lost_impressions",
    "prebid_lost_rpm": "prebid_lost_revenue / 1000000 / 1000 / prebid_lost_impressions * 1000",
    "prebid_lost_valueRpm": "prebid_lost_revenue / 1000000 / 1000 / prebid_lost_impressions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_lost_revenue": "prebid_lost_revenue / 1000000 / 1000",
    "prebid_lost_pageView_rpm": "prebid_lost_revenue / 1000000 / 1000 / pageViews * 1000",
    "prebid_lost_pageView_valueRpm": "prebid_lost_revenue / 1000000 / 1000 / pageViews * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_lost_session_rpm": "prebid_lost_revenue / 1000000 / 1000 / sessions * 1000",
    "prebid_lost_session_valueRpm": "prebid_lost_revenue / 1000000 / 1000 / sessions * 1000 * (viewable / viewable_measurable_impressions)",
    "prebid_incremental_value": "prebid_incremental_value / 1000000 / 1000",
    "prebid_incremental_value_rpm": "prebid_incremental_value / 1000000 / 1000 / prebid_won_impressions * 1000",
    "won_lost_diff": "(prebid_lost_revenue / 1000000 / 1000 / prebid_lost_impressions * 1000 - prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions * 1000) / (prebid_won_revenue / 1000000 / 1000 / prebid_won_impressions * 1000)",
    "lineItem_revenue": "lineItem_revenue / 1000000",
    "dynamicAllocation_predicted_impressions": "dynamicAllocation_predicted_impressions",
    "dynamicAllocation_predicted_impressions_error": "dynamicAllocation_impressions - dynamicAllocation_predicted_impressions",
    "dynamicAllocation_predicted_revenue": "dynamicAllocation_predicted_revenue / 1000000 / 1000",
    "dynamicAllocation_predicted_rpm": "dynamicAllocation_predicted_revenue / 1000000 / 1000 / dynamicAllocation_impressions * 1000",
    "dynamicAllocation_predicted_revenue_error": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs(dynamicAllocation_revenue_with_forecast_client / 1000000 - dynamicAllocation_predicted_revenue / 1000000 / 1000)",
    "dynamicAllocation_predicted_revenue_relative_error": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs(dynamicAllocation_predicted_revenue / 1000000 / 1000 - dynamicAllocation_revenue_with_forecast_client / 1000000) / (dynamicAllocation_revenue_with_forecast_client / 1000000)",
    "dynamicAllocation_predicted_revenue_mae": "dynamicAllocation_predicted_revenue_abs_difference === 0 ? null : dynamicAllocation_predicted_revenue_abs_difference / 1000 / dynamicAllocation_impressions",
    "dynamicAllocation_predicted_revenue_rmse": "dynamicAllocation_predicted_revenue_residual === 0 ? null : Math.sqrt(dynamicAllocation_predicted_revenue_residual / 1000000 / dynamicAllocation_impressions)",
    "dynamicAllocation_predicted_revenue_server": "dynamicAllocation_predicted_revenue_server / 1000000 / 1000",
    "dynamicAllocation_predicted_rpm_server": "dynamicAllocation_predicted_revenue_server / 1000000 / 1000 / dynamicAllocation_impressions * 1000",
    "dynamicAllocation_predicted_revenue_error_server": "dynamicAllocation_revenue / 1000000 === 0 ? null : Math.abs(dynamicAllocation_revenue_with_forecast / 1000000 - dynamicAllocation_predicted_revenue_server / 1000000 / 1000)",
    "dynamicAllocation_predicted_revenue_mae_server": "dynamicAllocation_predicted_revenue_server_abs_difference === 0 ? null : dynamicAllocation_predicted_revenue_server_abs_difference / 1000 / dynamicAllocation_impressions",
    "dynamicAllocation_predicted_revenue_rmse_server": "dynamicAllocation_predicted_revenue_server_residual === 0 ? null : Math.sqrt(dynamicAllocation_predicted_revenue_server_residual / 1000000 / dynamicAllocation_impressions)",
    "dynamicAllocation_predicted_revenue_relative_error_server": "dynamicAllocation_revenue_with_forecast / 1000000 === 0 ? null : Math.abs(dynamicAllocation_predicted_revenue_server / 1000000 / 1000 - dynamicAllocation_revenue_with_forecast / 1000000) / (dynamicAllocation_revenue_with_forecast / 1000000)",
    "dynamicAllocation_confirmed_clicks_rate": "dynamicAllocation_impressions === 0 ? null : dynamicAllocation_confirmed_clicks / dynamicAllocation_impressions",
    "vitals_lcp": "vitals_lcp_sum / vitals_lcp_measurable",
    "vitals_lcp_good_rate": "vitals_lcp_good / vitals_lcp_measurable",
    "vitals_lcp_medium_rate": "vitals_lcp_medium / vitals_lcp_measurable",
    "vitals_lcp_bad_rate": "vitals_lcp_bad / vitals_lcp_measurable",
    "vitals_fid": "vitals_fid_sum / vitals_fid_measurable",
    "vitals_fid_good_rate": "vitals_fid_good / vitals_fid_measurable",
    "vitals_fid_medium_rate": "vitals_fid_medium / vitals_fid_measurable",
    "vitals_fid_bad_rate": "vitals_fid_bad / vitals_fid_measurable",
    "vitals_cls": "vitals_cls_sum / vitals_cls_measurable",
    "vitals_cls_good_rate": "vitals_cls_good / vitals_cls_measurable",
    "vitals_cls_medium_rate": "vitals_cls_medium / vitals_cls_measurable",
    "vitals_cls_bad_rate": "vitals_cls_bad / vitals_cls_measurable",
    "vitals_inp": "vitals_inp_sum / vitals_inp_measurable",
    "vitals_inp_good_rate": "vitals_inp_good / vitals_inp_measurable",
    "vitals_inp_medium_rate": "vitals_inp_medium / vitals_inp_measurable",
    "vitals_inp_bad_rate": "vitals_inp_bad / vitals_inp_measurable",
    "vitals_ttfb": "vitals_ttfb_sum / vitals_ttfb_measurable",
    "vitals_ttfb_good_rate": "vitals_ttfb_good / vitals_ttfb_measurable",
    "vitals_ttfb_medium_rate": "vitals_ttfb_medium / vitals_ttfb_measurable",
    "vitals_ttfb_bad_rate": "vitals_ttfb_bad / vitals_ttfb_measurable",
    "vitals_fcp": "vitals_fcp_sum / vitals_fcp_measurable",
    "vitals_fcp_good_rate": "vitals_fcp_good / vitals_fcp_measurable",
    "vitals_fcp_medium_rate": "vitals_fcp_medium / vitals_fcp_measurable",
    "vitals_fcp_bad_rate": "vitals_fcp_bad / vitals_fcp_measurable",
    "uniqueExternalId": "uniqueExternalId",
    "adSense_impressions": "adSense_impressions",
    "adSense_revenue": "adSense_revenue / 1000000",
    "adSense_rpm": "adSense_revenue / 1000000 / adSense_impressions * 1000",
    "adSense_pageView_rpm": "adSense_revenue / 1000000 / pageViews * 1000",
    "adSense_session_rpm": "adSense_revenue / 1000000 / sessions * 1000",
    "adSense_winRate": "adSense_impressions / impressions",
    "ayMediation_impressions": "ayMediation_impressions",
    "ayMediation_revenue": "ayMediation_revenue",
    "ayMediation_rpm": "ayMediation_revenue / ayMediation_impressions * 1000",
    "ayMediation_pageView_rpm": "ayMediation_revenue / pageViews * 1000",
    "ayMediation_session_rpm": "ayMediation_revenue / sessions * 1000",
    "ayMediation_winRate": "ayMediation_impressions / impressions",
    "floor_rpm": "floor / 1000000 / floor_measurable_impressions",
    "floor_impressions": "floor_measurable_impressions",
    "floor_empirical_revenue_ratio": "floor_empirical_revenue / (prebid_won_revenue / 1000000 / 1000 + dynamicAllocation_revenue / 1000000 + direct_revenue / 1000000 + (outbrain_revenue / 1000000 + taboola_revenue / 1000000 + yahooGemini_revenue / 1000000) + adSense_revenue / 1000000 + aps_revenue / 1000000 + nativo_revenue)",
    "clicks_bounce_session_rate": "sessions_with_bounced_clicks / sessions",
    "sessions_with_bounced_clicks": "sessions_with_bounced_clicks",
    "miss_click_impression_pct": "clicksBounced / impressions * 100",
    "blocked_impressions": "blocked_impressions",
    "blocked_impressions_rate": "blocked_impressions / impressions",
    "aps_impressions": "aps_impressions",
    "aps_revenue": "aps_revenue / 1000000",
    "aps_rpm": "aps_revenue / 1000000 / aps_impressions * 1000",
    "aps_pageView_rpm": "aps_revenue / 1000000 / pageViews * 1000",
    "aps_session_rpm": "aps_revenue / 1000000 / sessions * 1000",
    "aps_winRate": "aps_impressions / impressions",
    "prebid_user_id_providers_avg": "prebid_user_id_providers_sum_uses / impressions",
    "prebid_user_id_providers_zero_usages": "prebid_user_id_providers_zero_usages",
    "prebid_user_id_providers_zero_usages_rate": "prebid_user_id_providers_zero_usages / impressions"
  },
  "web": {
    "vitals_lcp": "vitals_lcp_sum / vitals_lcp_measurable",
    "vitals_lcp_good_rate": "vitals_lcp_good / vitals_lcp_measurable",
    "vitals_lcp_medium_rate": "vitals_lcp_medium / vitals_lcp_measurable",
    "vitals_lcp_bad_rate": "vitals_lcp_bad / vitals_lcp_measurable",
    "vitals_fid": "vitals_fid_sum / vitals_fid_measurable",
    "vitals_fid_good_rate": "vitals_fid_good / vitals_fid_measurable",
    "vitals_fid_medium_rate": "vitals_fid_medium / vitals_fid_measurable",
    "vitals_fid_bad_rate": "vitals_fid_bad / vitals_fid_measurable",
    "vitals_cls": "vitals_cls_sum / vitals_cls_measurable",
    "vitals_cls_good_rate": "vitals_cls_good / vitals_cls_measurable",
    "vitals_cls_medium_rate": "vitals_cls_medium / vitals_cls_measurable",
    "vitals_cls_bad_rate": "vitals_cls_bad / vitals_cls_measurable",
    "vitals_inp": "vitals_inp_sum / vitals_inp_measurable",
    "vitals_inp_good_rate": "vitals_inp_good / vitals_inp_measurable",
    "vitals_inp_medium_rate": "vitals_inp_medium / vitals_inp_measurable",
    "vitals_inp_bad_rate": "vitals_inp_bad / vitals_inp_measurable",
    "vitals_ttfb": "vitals_ttfb_sum / vitals_ttfb_measurable",
    "vitals_ttfb_good_rate": "vitals_ttfb_good / vitals_ttfb_measurable",
    "vitals_ttfb_medium_rate": "vitals_ttfb_medium / vitals_ttfb_measurable",
    "vitals_ttfb_bad_rate": "vitals_ttfb_bad / vitals_ttfb_measurable",
    "vitals_fcp": "vitals_fcp_sum / vitals_fcp_measurable",
    "vitals_fcp_good_rate": "vitals_fcp_good / vitals_fcp_measurable",
    "vitals_fcp_medium_rate": "vitals_fcp_medium / vitals_fcp_measurable",
    "vitals_fcp_bad_rate": "vitals_fcp_bad / vitals_fcp_measurable",
    "pageViews": "pageViews",
    "sessions": "sessions",
    "session_starts": "session_starts",
    "pageView_starts": "pageView_starts",
    "mean_scrollY": "scrollY / pageViews",
    "ads_per_pageView": "impressions / pageViews",
    "active_pageView_time_minutes": "sum_pageView_activeEngagementTime / pageViews / 1000 / 60",
    "bounceRate": "count_low_engagement / count_event_end",
    "pageViews_per_session": "pageViews / sessions",
    "ads_per_session": "impressions / sessions",
    "errors_per_pageView": "errors / pageViews",
    "errors_per_session": "errors / sessions",
    "active_session_time_minutes": "sum_pageView_activeEngagementTime / sessions / 1000 / 60",
    "pageViews_without_ads_ratio": "(pageViews_ends - pageView_with_ads) / pageViews_ends",
    "sessions_without_ads_ratio": "(sessions_ends - session_with_ads) / sessions_ends",
    "errors": "errors",
    "acquisition_cost": "acquisition_cost / 1000000",
    "acquisition_cost_measurable_session_starts": "acquisition_cost_measurable_session_starts",
    "avg_network_time": "nav_network_time / pageViews",
    "avg_transfer_time": "nav_transfer_time / pageViews",
    "avg_dom_completion_time": "nav_dom_content_loading_time / pageViews",
    "avg_page_load_time": "nav_dom_content_loading_time / pageViews",
    "avg_server_time": "nav_server_time / pageViews",
    "avg_dom_processing_time": "nav_dom_processing_time / pageViews",
    "avg_on_load_time": "nav_unload_event_time / pageViews",
    "avg_encoded_body_size": "nav_encoded_body_size / pageViews",
    "avg_decoded_body_size": "nav_decoded_body_size / pageViews",
    "avg_transfer_size": "nav_transfer_size / pageViews"
  },
  "prebid": {
    "prebid_report_ivt_rate": "(prebid_report_givt_impressions + prebid_report_sivt_impressions) / prebid_report_ivt_measurable_impressions",
    "prebid_report_givt_rate": "prebid_report_givt_impressions / prebid_report_ivt_measurable_impressions",
    "prebid_report_sivt_rate": "prebid_report_sivt_impressions / prebid_report_ivt_measurable_impressions",
    "prebid_report_requests": "prebid_report_requests",
    "prebid_report_auctions": "prebid_report_auctions",
    "prebid_report_requests_per_auction": "prebid_report_requests / prebid_report_auctions",
    "prebid_report_auction_win_rpm": "prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_auctions * 1000",
    "prebid_report_request_win_rpm": "prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_requests * 1000",
    "prebid_report_request_cpm": "prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_requests * 1000",
    "prebid_report_bidVolume_auction_cpm": "prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_auctions * 1000",
    "prebid_report_bidVolume_pageView_cpm": "prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_pageViews * 1000",
    "prebid_report_bidVolume_session_cpm": "prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_sessions * 1000",
    "prebid_report_bidDensity_auction": "prebid_report_bids / prebid_report_auctions",
    "prebid_report_bidDensity_pageView": "prebid_report_bids / prebid_report_pageViews",
    "prebid_report_bidDensity_session": "prebid_report_bids / prebid_report_sessions",
    "prebid_report_timeToRespond": "prebid_report_timeToRespond / prebid_report_bids",
    "prebid_report_timeouts": "prebid_report_timeouts",
    "prebid_report_timeout_rate": "prebid_report_timeouts / prebid_report_requests",
    "prebid_report_noBids": "prebid_report_noBids",
    "prebid_report_noBid_rate": "prebid_report_noBids / prebid_report_requests",
    "prebid_report_bids": "prebid_report_bids",
    "prebid_report_bids_revenue": "prebid_report_bids_revenue / 1000000 / 1000",
    "prebid_report_bid_cpm": "prebid_report_bids_revenue / 1000000 / 1000 / prebid_report_bids * 1000",
    "prebid_report_bid_rate": "prebid_report_bids / prebid_report_requests",
    "prebid_report_bid_position": "prebid_report_bid_position / prebid_report_bids",
    "prebid_report_setTargetingTime": "prebid_report_setTargetingTime / prebid_report_bids",
    "prebid_report_wins": "prebid_report_wins",
    "prebid_report_wins_revenue": "prebid_report_wins_revenue / 1000000 / 1000",
    "prebid_report_win_cpm": "prebid_report_wins_revenue / 1000000 / 1000 / prebid_report_wins * 1000",
    "prebid_report_win_rate": "prebid_report_wins / prebid_report_bids",
    "prebid_report_request_win_rate": "prebid_report_wins / prebid_report_requests",
    "prebid_report_renderedTime": "prebid_report_renderedTime / prebid_report_wins",
    "prebid_report_floor_cpm": "prebid_report_floor_sum / 1000000 / prebid_report_floor_non_null",
    "prebid_report_floor_rate": "prebid_report_floor_non_null / prebid_report_bids",
    "prebid_report_highest_bid_rate": "prebid_report_highest_bid / prebid_report_bids"
  },
  "error_analytics": {
    "errors": "errors",
    "sessions": "sessions"
  },
  "request_reduction_server_samples_predict": {
    "request_reduction_server_samples_requests": "request_reduction_server_samples_requests",
    "request_reduction_server_samples_win_rate": "request_reduction_server_samples_wins / request_reduction_server_samples_requests",
    "request_reduction_server_samples_bid_rate": "request_reduction_server_samples_bids / request_reduction_server_samples_requests",
    "request_reduction_server_samples_wins": "request_reduction_server_samples_wins",
    "request_reduction_server_samples_bids": "request_reduction_server_samples_bids",
    "request_reduction_server_samples_win_revenue": "request_reduction_server_samples_win_revenue / 1000",
    "request_reduction_server_samples_bid_revenue": "request_reduction_server_samples_bid_revenue / 1000",
    "request_reduction_server_samples_win_cpm": "request_reduction_server_samples_win_revenue / 1000 / request_reduction_server_samples_wins * 1000",
    "request_reduction_server_samples_bid_cpm": "request_reduction_server_samples_bid_revenue / 1000 / request_reduction_server_samples_bids * 1000",
    "request_reduction_server_samples_req_reduction_rate": "(request_reduction_server_samples_bid_true_negative + request_reduction_server_samples_bid_false_negative) / request_reduction_server_samples_requests",
    "request_reduction_server_samples_lost_bids_rate": "request_reduction_server_samples_bid_false_negative / request_reduction_server_samples_bids",
    "request_reduction_server_samples_lost_wins_rate": "request_reduction_server_samples_win_false_negative / request_reduction_server_samples_wins",
    "request_reduction_server_samples_lost_revenue_rate": "request_reduction_server_samples_missed_revenue / 1000 / (request_reduction_server_samples_win_revenue / 1000)",
    "request_reduction_server_samples_missed_revenue": "request_reduction_server_samples_missed_revenue / 1000",
    "request_reduction_server_samples_predicts": "request_reduction_server_samples_predicts",
    "request_reduction_server_samples_rpb": "request_reduction_server_samples_win_revenue / 1000 / request_reduction_server_samples_wins * 1000000000",
    "request_reduction_server_samples_reduction_efficiency": "1 - (request_reduction_server_samples_bid_true_negative + request_reduction_server_samples_bid_false_negative) / request_reduction_server_samples_requests * (request_reduction_server_samples_missed_revenue / 1000 / (request_reduction_server_samples_win_revenue / 1000)) * 100",
    "request_reduction_server_samples_bid_accuracy": "100 * ((request_reduction_server_samples_bid_true_positive + request_reduction_server_samples_bid_true_negative) / request_reduction_server_samples_requests)",
    "request_reduction_server_samples_bid_precision": "100 * (request_reduction_server_samples_bid_true_positive / (request_reduction_server_samples_bid_true_positive + request_reduction_server_samples_bid_false_positive))",
    "request_reduction_server_samples_bid_recall": "100 * (request_reduction_server_samples_bid_true_positive / (request_reduction_server_samples_bid_true_positive + request_reduction_server_samples_bid_false_negative))",
    "request_reduction_server_samples_bid_specificity": "100 * (request_reduction_server_samples_bid_true_negative / (request_reduction_server_samples_bid_true_negative + request_reduction_server_samples_bid_false_positive))",
    "request_reduction_server_samples_win_accuracy": "100 * ((request_reduction_server_samples_win_true_positive + request_reduction_server_samples_win_true_negative) / request_reduction_server_samples_requests)",
    "request_reduction_server_samples_win_precision": "100 * (request_reduction_server_samples_win_true_positive / (request_reduction_server_samples_win_true_positive + request_reduction_server_samples_win_false_positive))",
    "request_reduction_server_samples_win_recall": "100 * (request_reduction_server_samples_win_true_positive / (request_reduction_server_samples_win_true_positive + request_reduction_server_samples_win_false_negative))",
    "request_reduction_server_samples_win_specificity": "100 * (request_reduction_server_samples_win_true_negative / (request_reduction_server_samples_win_true_negative + request_reduction_server_samples_win_false_positive))"
  }
};

// ../core/src/constants/calculated-metrics/parsed-metrics/index.ts
var printParsedRecursiveMetricAsExprCached = (placement, metric) => {
  return parsedPrettyJsMetrics?.[placement]?.[metric.id] ?? "";
};

// src/APIMetricTable.tsx
import React17 from "react";
import BootstrapTable8 from "react-bootstrap-table-next";
import filterFactory8, { textFilter as textFilter8 } from "react-bootstrap-table2-filter";
import paginationFactory8 from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
var getMetrics = (metricsToShow) => {
  switch (metricsToShow) {
    case "raw": {
      return Object.entries(rawMetricsYield).filter(([_key, value]) => {
        return !(value == null ? void 0 : value.hideFromApi);
      }).map(([key, _value]) => {
        return {
          id: key,
          label: key,
          rawDependencies: [],
          formula: ""
        };
      }).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label));
    }
    case "calculated": {
      return Object.values(calculatedYieldMetrics).filter((m) => {
        const hidden = m.hideFromApiDocs;
        return !hidden;
      }).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label)).map((calc) => {
        if (calc.id.startsWith("aps_")) {
          return calc;
        }
        return __spreadProps(__spreadValues({}, calc), {
          rawDependencies: calc.rawDependencies.filter((dep) => !dep.startsWith("aps_revenue"))
        });
      });
    }
    default: {
      throw new Error("invalid metrics to show " + metricsToShow);
    }
  }
};
var JsCodeBlock = ({ code }) => {
  return /* @__PURE__ */ React17.createElement("pre", null, /* @__PURE__ */ React17.createElement("code", null, code));
};
var APIMetricTable = ({
  metricsToShow,
  sizePerPage = 10
}) => {
  const columns = [
    {
      text: "Metric",
      dataField: "label",
      filter: textFilter8({ placeholder: "Search..." }),
      sort: true
    },
    {
      text: "ID",
      filter: textFilter8({ placeholder: "Search..." }),
      dataField: "id",
      formatter: (cell) => /* @__PURE__ */ React17.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true
    }
  ];
  if (metricsToShow === "calculated") {
    const calculatedColumns = [
      {
        text: "Required Raw Metrics",
        filter: textFilter8({ placeholder: "Search..." }),
        dataField: "rawDependencies",
        formatter: (cell) => /* @__PURE__ */ React17.createElement("div", {
          className: "tw-flex tw-flex-wrap"
        }, cell.map((dep) => {
          return /* @__PURE__ */ React17.createElement("code", {
            className: "tw-mr-1 tw-mb-1",
            key: dep,
            style: { whiteSpace: "nowrap" }
          }, dep);
        })),
        sort: true
      },
      {
        text: "Calculation Formula",
        filter: textFilter8({ placeholder: "Search..." }),
        dataField: "formula",
        formatter: (_cell, data) => {
          const jsExpr = printParsedRecursiveMetricAsExprCached("yield", data);
          return /* @__PURE__ */ React17.createElement(JsCodeBlock, {
            code: jsExpr
          });
        },
        sort: true
      }
    ];
    columns.push(...calculatedColumns);
  }
  return /* @__PURE__ */ React17.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React17.createElement(BootstrapTable8, {
    data: getMetrics(metricsToShow),
    columns,
    pagination: paginationFactory8({
      sizePerPage
    }),
    filter: filterFactory8(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }));
};

// src/APIReportTables.tsx
import Markdown from "markdown-to-jsx";
import React18, { useState as useState3 } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
var APIReportTables = () => {
  const [activeTab, setActiveTab] = useState3("1");
  return /* @__PURE__ */ React18.createElement("div", null, /* @__PURE__ */ React18.createElement(Nav_default, {
    tabs: true
  }, /* @__PURE__ */ React18.createElement(NavItem_default, null, /* @__PURE__ */ React18.createElement(NavLink_default, {
    className: activeTab === "1" ? "active" : "",
    onClick: () => {
      setActiveTab("1");
    }
  }, "Dimensions")), /* @__PURE__ */ React18.createElement(NavItem_default, null, /* @__PURE__ */ React18.createElement(NavLink_default, {
    className: activeTab === "2" ? "active" : "",
    onClick: () => {
      setActiveTab("2");
    }
  }, "Metrics")), /* @__PURE__ */ React18.createElement(NavItem_default, null, /* @__PURE__ */ React18.createElement(NavLink_default, {
    className: activeTab === "3" ? "active" : "",
    onClick: () => {
      setActiveTab("3");
    }
  }, "Filters")), /* @__PURE__ */ React18.createElement(NavItem_default, null, /* @__PURE__ */ React18.createElement(NavLink_default, {
    className: activeTab === "4" ? "active" : "",
    onClick: () => {
      setActiveTab("4");
    }
  }, "Timezone"))), /* @__PURE__ */ React18.createElement(TabContent_default, {
    activeTab
  }, /* @__PURE__ */ React18.createElement(TabPane, {
    tabId: "1"
  }, /* @__PURE__ */ React18.createElement(Row_default, null, /* @__PURE__ */ React18.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React18.createElement(APIDimensionTable, null)))), /* @__PURE__ */ React18.createElement(TabPane, {
    tabId: "2"
  }, /* @__PURE__ */ React18.createElement(Row_default, null, /* @__PURE__ */ React18.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React18.createElement(APIMetricTable, {
    metricsToShow: "raw"
  }), /* @__PURE__ */ React18.createElement(Markdown, null, "**Note** : `revenue` and `rpm` metrics are a multitude of 1,000,000")))), /* @__PURE__ */ React18.createElement(TabPane, {
    tabId: "3"
  }, /* @__PURE__ */ React18.createElement(Row_default, null, /* @__PURE__ */ React18.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React18.createElement(APIFilterTable, null)))), /* @__PURE__ */ React18.createElement(TabPane, {
    tabId: "4"
  }, /* @__PURE__ */ React18.createElement(Row_default, null, /* @__PURE__ */ React18.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React18.createElement(APITimezoneTable, null))))));
};

// ../core/src/utils/cleanUpRouteString.ts
function cleanUpRouteString(route) {
  const numbersRemoved = route.replace(/^\d+(\.\d+)?/, "");
  const ampersandReplaced = numbersRemoved.replace(/&/g, "-");
  const cleanedRoute = ampersandReplaced.replace(/[^a-zA-Z0-9-/\s]/g, "");
  const hyphenatedSpaces = cleanedRoute.replace(/\s+/g, "-");
  const hyphenatedRoute = hyphenatedSpaces.replace(/\/+/g, "--");
  const trimmedRoute = hyphenatedRoute.replace(/^-+|-+$/g, "");
  const finalRoute = trimmedRoute.replace(/^\d+\./, "");
  const correctedRoute = finalRoute.replace(/-{4}/g, "--");
  return correctedRoute.toLowerCase();
}

// src/DocsMDX.tsx
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import json from "highlight.js/lib/languages/json";
import Markdown2 from "markdown-to-jsx";
import React21, { useEffect, useRef } from "react";
import "highlight.js/styles/github.css";

// ../core/src/constants/api-mappings/request-reduction-server-samples.mappings.ts
var EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING = {
  bidder: "request_reduction_server_samples_bidder",
  bidder_mapped: "request_reduction_server_samples_bidder_mapped",
  country: "request_reduction_server_samples_country",
  publisher_id: "request_reduction_server_samples_publisher_id",
  placement_id: "request_reduction_server_samples_placement_id",
  request_type: "request_reduction_server_samples_request_type",
  format: "request_reduction_server_samples_format",
  sizes: "request_reduction_server_samples_sizes",
  platform: "request_reduction_server_samples_platform",
  bundle: "request_reduction_server_samples_bundle",
  os: "request_reduction_server_samples_os",
  browser: "request_reduction_server_samples_browser",
  device: "request_reduction_server_samples_device",
  has_gps: "request_reduction_server_samples_has_gps",
  codebase_version: "request_reduction_server_samples_codebase_version",
  version: "request_reduction_server_samples_version",
  device_language: "request_reduction_server_samples_device_language",
  utc_offset: "request_reduction_server_samples_utc_offset",
  gdpr: "request_reduction_server_samples_gdpr",
  us_privacy: "request_reduction_server_samples_us_privacy",
  video_startdelay: "request_reduction_server_samples_video_startdelay",
  video_skip: "request_reduction_server_samples_video_skip",
  video_rewarded: "request_reduction_server_samples_video_rewarded",
  url: "request_reduction_server_samples_url",
  video_playback_method: "request_reduction_server_samples_video_playback_method",
  site_cat: "request_reduction_server_samples_site_cat",
  unit_types: "request_reduction_server_samples_unit_types",
  page_cat: "request_reduction_server_samples_page_cat",
  has_omsdk: "request_reduction_server_samples_has_omsdk",
  has_age: "request_reduction_server_samples_has_age",
  has_gender: "request_reduction_server_samples_has_gender",
  has_mraid: "request_reduction_server_samples_has_mraid",
  has_vast: "request_reduction_server_samples_has_vast",
  has_vpaid: "request_reduction_server_samples_has_vpaid",
  has_consent: "request_reduction_server_samples_has_consent",
  is_qps: "request_reduction_server_samples_is_qps",
  datacenter: "request_reduction_server_samples_datacenter",
  domain: "request_reduction_server_samples_domain",
  custom1: "request_reduction_server_samples_custom1",
  custom2: "request_reduction_server_samples_custom2",
  custom3: "request_reduction_server_samples_custom3",
  bid_confusion_matrix: "request_reduction_server_samples_bid_confusion_matrix",
  win_confusion_matrix: "request_reduction_server_samples_win_confusion_matrix"
};
var EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_IDS_MAPPING = {
  requests: "request_reduction_server_samples_requests",
  win_rate: "request_reduction_server_samples_win_rate",
  bid_rate: "request_reduction_server_samples_bid_rate",
  wins: "request_reduction_server_samples_wins",
  bids: "request_reduction_server_samples_bids",
  win_revenue: "request_reduction_server_samples_win_revenue",
  bid_revenue: "request_reduction_server_samples_bid_revenue",
  win_cpm: "request_reduction_server_samples_win_cpm",
  bid_cpm: "request_reduction_server_samples_bid_cpm",
  req_reduction_rate: "request_reduction_server_samples_req_reduction_rate",
  lost_bids_rate: "request_reduction_server_samples_lost_bids_rate",
  lost_wins_rate: "request_reduction_server_samples_lost_wins_rate",
  lost_revenue_rate: "request_reduction_server_samples_lost_revenue_rate",
  missed_revenue: "request_reduction_server_samples_missed_revenue",
  predicts: "request_reduction_server_samples_predicts",
  rpb: "request_reduction_server_samples_rpb",
  reduction_efficiency: "request_reduction_server_samples_reduction_efficiency",
  bid_accuracy: "request_reduction_server_samples_bid_accuracy",
  bid_precision: "request_reduction_server_samples_bid_precision",
  bid_recall: "request_reduction_server_samples_bid_recall",
  bid_specificity: "request_reduction_server_samples_bid_specificity",
  win_accuracy: "request_reduction_server_samples_win_accuracy",
  win_precision: "request_reduction_server_samples_win_precision",
  win_recall: "request_reduction_server_samples_win_recall",
  win_specificity: "request_reduction_server_samples_win_specificity"
};

// src/ApiTrafficShapingSamplesPredictTable.tsx
import React19, { useState as useState4 } from "react";
import BootstrapTable9 from "react-bootstrap-table-next";
import filterFactory9, { textFilter as textFilter9 } from "react-bootstrap-table2-filter";
import paginationFactory9 from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
var getDimensionTableData2 = () => {
  const data = std_exports.getKeys(EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING).map((exposedId) => {
    const key = EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING[exposedId];
    const dimension = dimensions[key];
    return {
      title: dimension.label,
      description: dimension.description,
      dimensionId: exposedId
    };
  }).sort((a, b) => String(a == null ? void 0 : a.dimensionId).localeCompare(String(b == null ? void 0 : b.dimensionId)));
  return data;
};
var getDimensionsTableColumns = () => {
  return [
    {
      text: "Dimension",
      dataField: "title",
      sort: true,
      filter: textFilter9({ placeholder: "Search..." })
    },
    {
      text: "ID",
      dataField: "dimensionId",
      formatter: (cell) => /* @__PURE__ */ React19.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true,
      filter: textFilter9({ placeholder: "Search..." })
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      filter: textFilter9({ placeholder: "Search..." })
    }
  ];
};
var getMetricsColumns = () => {
  return [
    {
      text: "Metric",
      dataField: "label",
      filter: textFilter9({ placeholder: "Search..." }),
      sort: true
    },
    {
      text: "ID",
      filter: textFilter9({ placeholder: "Search..." }),
      dataField: "id",
      formatter: (cell) => /* @__PURE__ */ React19.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell),
      sort: true
    }
  ];
};
var getMetrics2 = () => {
  return std_exports.getKeys(EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_IDS_MAPPING).map((exposedId) => {
    const key = EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_IDS_MAPPING[exposedId];
    const metric = calculatedRequestReductionServerSamplesPredictMetrics[key];
    return {
      id: exposedId,
      label: metric.label,
      rawDependencies: [],
      formula: ""
    };
  }).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label));
};
var getFilterColumns = () => {
  return [
    {
      text: "Filter",
      dataField: "label",
      filter: textFilter9({ placeholder: "Search..." }),
      sort: true
    },
    {
      text: "ID",
      dataField: "id",
      filter: textFilter9({ placeholder: "Search..." }),
      sort: true,
      formatter: (cell) => /* @__PURE__ */ React19.createElement("code", {
        style: { whiteSpace: "nowrap" }
      }, cell)
    }
  ];
};
var getFilterTableData2 = () => {
  const data = std_exports.getKeys(EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING).map((exposedId) => {
    const key = EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING[exposedId];
    const dimension = dimensions[key];
    return __spreadProps(__spreadValues({}, dimension), {
      title: dimension.label,
      description: dimension.description,
      dimensionId: exposedId,
      id: exposedId
    });
  }).filter((it) => !it.isTombstone).filter((it) => !it.limitedToAdminOrDev).sort((a, b) => String(a == null ? void 0 : a.label).localeCompare(b == null ? void 0 : b.label));
  return data;
};
var APITrafficShapingSamplesPredictTables = () => {
  const [activeTab, setActiveTab] = useState4("1");
  return /* @__PURE__ */ React19.createElement("div", null, /* @__PURE__ */ React19.createElement(Nav_default, {
    tabs: true
  }, /* @__PURE__ */ React19.createElement(NavItem_default, null, /* @__PURE__ */ React19.createElement(NavLink_default, {
    className: activeTab === "1" ? "active" : "",
    onClick: () => {
      setActiveTab("1");
    }
  }, "Dimensions")), /* @__PURE__ */ React19.createElement(NavItem_default, null, /* @__PURE__ */ React19.createElement(NavLink_default, {
    className: activeTab === "2" ? "active" : "",
    onClick: () => {
      setActiveTab("2");
    }
  }, "Metrics")), /* @__PURE__ */ React19.createElement(NavItem_default, null, /* @__PURE__ */ React19.createElement(NavLink_default, {
    className: activeTab === "3" ? "active" : "",
    onClick: () => {
      setActiveTab("3");
    }
  }, "Filters")), /* @__PURE__ */ React19.createElement(NavItem_default, null, /* @__PURE__ */ React19.createElement(NavLink_default, {
    className: activeTab === "4" ? "active" : "",
    onClick: () => {
      setActiveTab("4");
    }
  }, "Timezone"))), /* @__PURE__ */ React19.createElement(TabContent_default, {
    activeTab
  }, /* @__PURE__ */ React19.createElement(TabPane, {
    tabId: "1"
  }, /* @__PURE__ */ React19.createElement(Row_default, null, /* @__PURE__ */ React19.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React19.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React19.createElement(BootstrapTable9, {
    data: getDimensionTableData2(),
    columns: getDimensionsTableColumns(),
    keyField: "title",
    pagination: paginationFactory9({}),
    filter: filterFactory9(),
    bootstrap4: true,
    condensed: true
  }))))), /* @__PURE__ */ React19.createElement(TabPane, {
    tabId: "2"
  }, /* @__PURE__ */ React19.createElement(Row_default, null, /* @__PURE__ */ React19.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React19.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React19.createElement(BootstrapTable9, {
    data: getMetrics2(),
    columns: getMetricsColumns(),
    pagination: paginationFactory9({
      sizePerPage: 10
    }),
    filter: filterFactory9(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }))))), /* @__PURE__ */ React19.createElement(TabPane, {
    tabId: "3"
  }, /* @__PURE__ */ React19.createElement(Row_default, null, /* @__PURE__ */ React19.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React19.createElement("div", {
    className: "react-bootstrap-table-container"
  }, /* @__PURE__ */ React19.createElement(BootstrapTable9, {
    data: getFilterTableData2(),
    pagination: paginationFactory9({}),
    filter: filterFactory9(),
    columns: getFilterColumns(),
    keyField: "label",
    bootstrap4: true,
    condensed: true
  }))))), /* @__PURE__ */ React19.createElement(TabPane, {
    tabId: "4"
  }, /* @__PURE__ */ React19.createElement(Row_default, null, /* @__PURE__ */ React19.createElement(Col_default, {
    sm: "12"
  }, /* @__PURE__ */ React19.createElement(APITimezoneTable, null))))));
};

// src/TopicCards.tsx
import React20 from "react";
import { Link } from "react-router-dom";
var TopicCards = ({ cards }) => {
  const cardsData = eval(cards);
  const images = [
    /* @__PURE__ */ React20.createElement("svg", {
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      className: "green",
      key: "2"
    }, /* @__PURE__ */ React20.createElement("g", {
      opacity: "0.6"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M21.5 12V84",
      stroke: "url(#paint0_linear_6498_81873)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 75L12.5 75",
      stroke: "url(#paint1_linear_6498_81873)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M30.5 12V84",
      stroke: "url(#paint2_linear_6498_81873)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 66L12.5 66",
      stroke: "url(#paint3_linear_6498_81873)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M39.5 12V84",
      stroke: "url(#paint4_linear_6498_81873)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 57L12.5 57",
      stroke: "url(#paint5_linear_6498_81873)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M48.5 12V84",
      stroke: "url(#paint6_linear_6498_81873)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 48L12.5 48",
      stroke: "url(#paint7_linear_6498_81873)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M57.5 12V84",
      stroke: "url(#paint8_linear_6498_81873)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 39L12.5 39",
      stroke: "url(#paint9_linear_6498_81873)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M66.5 12V84",
      stroke: "url(#paint10_linear_6498_81873)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 30L12.5 30",
      stroke: "url(#paint11_linear_6498_81873)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M75.5 12V84",
      stroke: "url(#paint12_linear_6498_81873)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 21L12.5 21",
      stroke: "url(#paint13_linear_6498_81873)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12V84",
      stroke: "url(#paint14_linear_6498_81873)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12L12.5 12",
      stroke: "url(#paint15_linear_6498_81873)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    })), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.4",
      d: "M26 16.5L62 64.968L17 25.5",
      fill: "url(#paint16_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.4",
      d: "M39.5 30L62 64.968L30.5 39",
      fill: "url(#paint17_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.4",
      d: "M25.3398 32.2021L61.9998 64.9681L18.0078 37.3501",
      fill: "url(#paint18_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.4",
      d: "M38.7052 18.48L62.0032 64.968L31.3672 23.628",
      fill: "url(#paint19_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M61.2968 66.456L53.7188 79.578H63.6848L66.2768 75.09L61.3808 66.612L61.2908 66.456H61.2968ZM66.0908 57L62.8027 62.7L62.8928 62.856L67.7888 71.334L71.0768 65.634L66.0908 57ZM74.1188 66.72L68.2388 76.908H80.0047L74.1248 66.72H74.1188Z",
      fill: "#00F4BE"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M26 25.5V16.5H17V25.5H26Z",
      fill: "url(#paint20_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M35 25.5C37.4853 25.5 39.5 23.4853 39.5 21C39.5 18.5147 37.4853 16.5 35 16.5C32.5147 16.5 30.5 18.5147 30.5 21C30.5 23.4853 32.5147 25.5 35 25.5Z",
      fill: "url(#paint21_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M21.5 39C23.9853 39 26 36.9853 26 34.5C26 32.0147 23.9853 30 21.5 30C19.0147 30 17 32.0147 17 34.5C17 36.9853 19.0147 39 21.5 39Z",
      fill: "url(#paint22_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M39.5 39V30H30.5V39H39.5Z",
      fill: "url(#paint23_linear_6498_81873)"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_6498_81873",
      x1: "20.4959",
      y1: "84.3",
      x2: "20.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint1_linear_6498_81873",
      x1: "12.2",
      y1: "76.0041",
      x2: "84.8",
      y2: "76.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint2_linear_6498_81873",
      x1: "29.4959",
      y1: "84.3",
      x2: "29.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint3_linear_6498_81873",
      x1: "12.2",
      y1: "67.0041",
      x2: "84.8",
      y2: "67.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint4_linear_6498_81873",
      x1: "38.4959",
      y1: "84.3",
      x2: "38.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint5_linear_6498_81873",
      x1: "12.2",
      y1: "58.0041",
      x2: "84.8",
      y2: "58.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint6_linear_6498_81873",
      x1: "47.4959",
      y1: "84.3",
      x2: "47.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint7_linear_6498_81873",
      x1: "12.2",
      y1: "49.0041",
      x2: "84.8",
      y2: "49.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint8_linear_6498_81873",
      x1: "56.4959",
      y1: "84.3",
      x2: "56.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint9_linear_6498_81873",
      x1: "12.2",
      y1: "40.0041",
      x2: "84.8",
      y2: "40.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint10_linear_6498_81873",
      x1: "65.4959",
      y1: "84.3",
      x2: "65.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint11_linear_6498_81873",
      x1: "12.2",
      y1: "31.0041",
      x2: "84.8",
      y2: "31.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint12_linear_6498_81873",
      x1: "74.4959",
      y1: "84.3",
      x2: "74.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint13_linear_6498_81873",
      x1: "12.2",
      y1: "22.0041",
      x2: "84.8",
      y2: "22.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint14_linear_6498_81873",
      x1: "83.4959",
      y1: "84.3",
      x2: "83.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint15_linear_6498_81873",
      x1: "12.2",
      y1: "13.0041",
      x2: "84.8",
      y2: "13.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#00F4BE",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint16_linear_6498_81873",
      x1: "23.588",
      y1: "23.154",
      x2: "62.036",
      y2: "62.832",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint17_linear_6498_81873",
      x1: "36.398",
      y1: "35.946",
      x2: "62.582",
      y2: "62.97",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint18_linear_6498_81873",
      x1: "24.2718",
      y1: "34.9201",
      x2: "56.6718",
      y2: "68.3581",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint19_linear_6498_81873",
      x1: "33.9592",
      y1: "24.798",
      x2: "66.7192",
      y2: "58.608",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint20_linear_6498_81873",
      x1: "17.654",
      y1: "25.128",
      x2: "25.79",
      y2: "16.392",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint21_linear_6498_81873",
      x1: "32.45",
      y1: "24.48",
      x2: "37.388",
      y2: "17.742",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint22_linear_6498_81873",
      x1: "18.95",
      y1: "38.226",
      x2: "24.02",
      y2: "30.822",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint23_linear_6498_81873",
      x1: "31.076",
      y1: "38.694",
      x2: "38.81",
      y2: "30.426",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })))),
    /* @__PURE__ */ React20.createElement("svg", {
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      className: "yellow",
      key: "3"
    }, /* @__PURE__ */ React20.createElement("g", {
      opacity: "0.6"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M21.5 12V84",
      stroke: "url(#paint0_linear_6498_41812)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 75L12.5 75",
      stroke: "url(#paint1_linear_6498_41812)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M30.5 12V84",
      stroke: "url(#paint2_linear_6498_41812)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 66L12.5 66",
      stroke: "url(#paint3_linear_6498_41812)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M39.5 12V84",
      stroke: "url(#paint4_linear_6498_41812)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 57L12.5 57",
      stroke: "url(#paint5_linear_6498_41812)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M48.5 12V84",
      stroke: "url(#paint6_linear_6498_41812)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 48L12.5 48",
      stroke: "url(#paint7_linear_6498_41812)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M57.5 12V84",
      stroke: "url(#paint8_linear_6498_41812)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 39L12.5 39",
      stroke: "url(#paint9_linear_6498_41812)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M66.5 12V84",
      stroke: "url(#paint10_linear_6498_41812)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 30L12.5 30",
      stroke: "url(#paint11_linear_6498_41812)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M75.5 12V84",
      stroke: "url(#paint12_linear_6498_41812)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 21L12.5 21",
      stroke: "url(#paint13_linear_6498_41812)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12V84",
      stroke: "url(#paint14_linear_6498_41812)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12L12.5 12",
      stroke: "url(#paint15_linear_6498_41812)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    })), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.6",
      d: "M48.498 25.5L28.5 30.498C28.5 55.5 48.498 70.5 48.498 70.5C48.498 70.5 68.496 55.5 68.496 30.498L48.498 25.5Z",
      fill: "url(#paint16_linear_6498_41812)"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M52.4609 57.2281L47.9609 49.4341L43.4609 57.2281H52.4609Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M46.4609 36.7319H37.4609V45.7319H46.4609V36.7319Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M59.7344 44.5439C59.7344 42.0587 57.7197 40.0439 55.2344 40.0439C52.7491 40.0439 50.7344 42.0587 50.7344 44.5439C50.7344 47.0292 52.7491 49.0439 55.2344 49.0439C57.7197 49.0439 59.7344 47.0292 59.7344 44.5439Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.2",
      d: "M56.5293 57.5522C57.6327 57.5522 58.5273 56.6576 58.5273 55.5541C58.5273 54.4507 57.6327 53.5562 56.5293 53.5562C55.4258 53.5562 54.5312 54.4507 54.5312 55.5541C54.5312 56.6576 55.4258 57.5522 56.5293 57.5522Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.3",
      d: "M56.5328 56.4543C57.0299 56.4543 57.4328 56.0514 57.4328 55.5543C57.4328 55.0572 57.0299 54.6543 56.5328 54.6543C56.0358 54.6543 55.6328 55.0572 55.6328 55.5543C55.6328 56.0514 56.0358 56.4543 56.5328 56.4543Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.2",
      d: "M36.2636 49.7338C37.3671 49.7338 38.2616 48.8393 38.2616 47.7358C38.2616 46.6323 37.3671 45.7378 36.2636 45.7378C35.1602 45.7378 34.2656 46.6323 34.2656 47.7358C34.2656 48.8393 35.1602 49.7338 36.2636 49.7338Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.3",
      d: "M36.2672 48.6359C36.7642 48.6359 37.1672 48.233 37.1672 47.7359C37.1672 47.2389 36.7642 46.8359 36.2672 46.8359C35.7701 46.8359 35.3672 47.2389 35.3672 47.7359C35.3672 48.233 35.7701 48.6359 36.2672 48.6359Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.2",
      d: "M43.6855 31.6439C44.789 31.6439 45.6835 30.7494 45.6835 29.6459C45.6835 28.5425 44.789 27.6479 43.6855 27.6479C42.582 27.6479 41.6875 28.5425 41.6875 29.6459C41.6875 30.7494 42.582 31.6439 43.6855 31.6439Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.3",
      d: "M43.6891 30.5461C44.1861 30.5461 44.5891 30.1431 44.5891 29.6461C44.5891 29.149 44.1861 28.7461 43.6891 28.7461C43.192 28.7461 42.7891 29.149 42.7891 29.6461C42.7891 30.1431 43.192 30.5461 43.6891 30.5461Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.2",
      d: "M64.6621 40.998C65.7655 40.998 66.6601 40.1034 66.6601 39C66.6601 37.8965 65.7655 37.002 64.6621 37.002C63.5586 37.002 62.6641 37.8965 62.6641 39C62.6641 40.1034 63.5586 40.998 64.6621 40.998Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      opacity: "0.3",
      d: "M64.6656 39.9001C65.1627 39.9001 65.5656 39.4972 65.5656 39.0001C65.5656 38.503 65.1627 38.1001 64.6656 38.1001C64.1686 38.1001 63.7656 38.503 63.7656 39.0001C63.7656 39.4972 64.1686 39.9001 64.6656 39.9001Z",
      fill: "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M68.4211 72.936H66.7891",
      stroke: "#FFCC00",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M67.6078 75.0359C68.7676 75.0359 69.7078 74.0957 69.7078 72.9359C69.7078 71.7761 68.7676 70.8359 67.6078 70.8359C66.448 70.8359 65.5078 71.7761 65.5078 72.9359C65.5078 74.0957 66.448 75.0359 67.6078 75.0359Z",
      stroke: "#FFCC00",
      "stroke-width": "2",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M78.4914 34.5H76.8594",
      stroke: "#FFCC00",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M77.6781 36.5999C78.8379 36.5999 79.7781 35.6597 79.7781 34.4999C79.7781 33.3401 78.8379 32.3999 77.6781 32.3999C76.5183 32.3999 75.5781 33.3401 75.5781 34.4999C75.5781 35.6597 76.5183 36.5999 77.6781 36.5999Z",
      stroke: "#FFCC00",
      "stroke-width": "2",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M34.6867 23.856H33.0547",
      stroke: "#FFCC00",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M33.8734 25.9559C35.0332 25.9559 35.9734 25.0157 35.9734 23.8559C35.9734 22.6961 35.0332 21.7559 33.8734 21.7559C32.7136 21.7559 31.7734 22.6961 31.7734 23.8559C31.7734 25.0157 32.7136 25.9559 33.8734 25.9559Z",
      stroke: "#FFCC00",
      "stroke-width": "2",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M20.6711 57.228H19.0391",
      stroke: "#FFCC00",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M19.8578 59.3279C21.0176 59.3279 21.9578 58.3877 21.9578 57.2279C21.9578 56.0681 21.0176 55.1279 19.8578 55.1279C18.698 55.1279 17.7578 56.0681 17.7578 57.2279C17.7578 58.3877 18.698 59.3279 19.8578 59.3279Z",
      stroke: "#FFCC00",
      "stroke-width": "2",
      "stroke-miterlimit": "10"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M16.8125 43.5L17.9765 43.788",
      stroke: "url(#paint17_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M20.2422 44.3462L32.7042 47.4302",
      stroke: "url(#paint18_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M33.8359 47.7119L34.9999 47.9999L33.9919 48.6479",
      stroke: "url(#paint19_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M31.7863 50.064L24.0703 55.02",
      stroke: "url(#paint20_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M22.9611 55.728L21.9531 56.376",
      stroke: "url(#paint21_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M48.4969 16.5L48.0469 17.61",
      stroke: "url(#paint22_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M47.2201 19.6621L44.3281 26.8261",
      stroke: "url(#paint23_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M43.9164 27.8521L43.4664 28.9621L42.3984 28.4101",
      stroke: "url(#paint24_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M40.5117 27.438L37.6797 25.98",
      stroke: "url(#paint25_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M36.7399 25.4939L35.6719 24.9419",
      stroke: "url(#paint26_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M80.0002 48.0001L78.9922 47.3521",
      stroke: "url(#paint27_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M77.2721 46.2537L67.8281 40.1997",
      stroke: "url(#paint28_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M66.9611 39.6481L65.9531 39.0001L67.0811 38.5801",
      stroke: "url(#paint29_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M69.5938 37.6501L73.3737 36.2461",
      stroke: "url(#paint30_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M74.6328 35.7779L75.7608 35.3579",
      stroke: "url(#paint31_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M79.9994 63.9362L78.8594 63.5522",
      stroke: "url(#paint32_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M76.6196 62.808L59.7656 57.21",
      stroke: "url(#paint33_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M58.64 56.8321L57.5 56.4541L58.136 57.4741",
      stroke: "url(#paint34_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M59.2422 59.2383L65.3142 68.9343",
      stroke: "url(#paint35_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M65.8672 69.8159L66.5032 70.8359",
      stroke: "url(#paint36_linear_6498_41812)",
      "stroke-width": "2",
      "stroke-miterlimit": "5"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_6498_41812",
      x1: "20.4959",
      y1: "84.3",
      x2: "20.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint1_linear_6498_41812",
      x1: "12.2",
      y1: "76.0041",
      x2: "84.8",
      y2: "76.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint2_linear_6498_41812",
      x1: "29.4959",
      y1: "84.3",
      x2: "29.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint3_linear_6498_41812",
      x1: "12.2",
      y1: "67.0041",
      x2: "84.8",
      y2: "67.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint4_linear_6498_41812",
      x1: "38.4959",
      y1: "84.3",
      x2: "38.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint5_linear_6498_41812",
      x1: "12.2",
      y1: "58.0041",
      x2: "84.8",
      y2: "58.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint6_linear_6498_41812",
      x1: "47.4959",
      y1: "84.3",
      x2: "47.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint7_linear_6498_41812",
      x1: "12.2",
      y1: "49.0041",
      x2: "84.8",
      y2: "49.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint8_linear_6498_41812",
      x1: "56.4959",
      y1: "84.3",
      x2: "56.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint9_linear_6498_41812",
      x1: "12.2",
      y1: "40.0041",
      x2: "84.8",
      y2: "40.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint10_linear_6498_41812",
      x1: "65.4959",
      y1: "84.3",
      x2: "65.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint11_linear_6498_41812",
      x1: "12.2",
      y1: "31.0041",
      x2: "84.8",
      y2: "31.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint12_linear_6498_41812",
      x1: "74.4959",
      y1: "84.3",
      x2: "74.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint13_linear_6498_41812",
      x1: "12.2",
      y1: "22.0041",
      x2: "84.8",
      y2: "22.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint14_linear_6498_41812",
      x1: "83.4959",
      y1: "84.3",
      x2: "83.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint15_linear_6498_41812",
      x1: "12.2",
      y1: "13.0041",
      x2: "84.8",
      y2: "13.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint16_linear_6498_41812",
      x1: "35.712",
      y1: "63.036",
      x2: "53.316",
      y2: "25.434",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint17_linear_6498_41812",
      x1: "17.3945",
      y1: "42.918",
      x2: "17.3945",
      y2: "56.8801",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint18_linear_6498_41812",
      x1: "26.4762",
      y1: "42.9182",
      x2: "26.4762",
      y2: "56.8802",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint19_linear_6498_41812",
      x1: "35.0839",
      y1: "42.9179",
      x2: "35.0839",
      y2: "56.8799",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint20_linear_6498_41812",
      x1: "27.9283",
      y1: "42.918",
      x2: "27.9283",
      y2: "56.88",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint21_linear_6498_41812",
      x1: "22.4571",
      y1: "42.918",
      x2: "22.4571",
      y2: "56.88",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint22_linear_6498_41812",
      x1: "48.2749",
      y1: "16.278",
      x2: "48.2749",
      y2: "29.796",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint23_linear_6498_41812",
      x1: "45.7741",
      y1: "16.2781",
      x2: "45.7741",
      y2: "29.7961",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint24_linear_6498_41812",
      x1: "43.2984",
      y1: "16.278",
      x2: "43.2984",
      y2: "29.7961",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint25_linear_6498_41812",
      x1: "39.0957",
      y1: "16.278",
      x2: "39.0957",
      y2: "29.796",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint26_linear_6498_41812",
      x1: "36.2059",
      y1: "16.2779",
      x2: "36.2059",
      y2: "29.7959",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint27_linear_6498_41812",
      x1: "79.4962",
      y1: "48.5041",
      x2: "79.4962",
      y2: "34.8",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint28_linear_6498_41812",
      x1: "72.5501",
      y1: "48.5037",
      x2: "72.5501",
      y2: "34.7997",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint29_linear_6498_41812",
      x1: "65.9531",
      y1: "48.5041",
      x2: "65.9531",
      y2: "34.8001",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint30_linear_6498_41812",
      x1: "71.4837",
      y1: "48.5041",
      x2: "71.4837",
      y2: "34.8001",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint31_linear_6498_41812",
      x1: "75.1968",
      y1: "48.5039",
      x2: "75.1968",
      y2: "34.7999",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint32_linear_6498_41812",
      x1: "69.0074",
      y1: "74.1662",
      x2: "79.9994",
      y2: "63.1743",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint33_linear_6498_41812",
      x1: "61.5236",
      y1: "66.678",
      x2: "72.5156",
      y2: "55.686",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint34_linear_6498_41812",
      x1: "54.248",
      y1: "59.4121",
      x2: "65.24",
      y2: "48.4201",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint35_linear_6498_41812",
      x1: "60.6042",
      y1: "65.7603",
      x2: "71.5962",
      y2: "54.7683",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint36_linear_6498_41812",
      x1: "65.6752",
      y1: "70.8359",
      x2: "76.6672",
      y2: "59.8379",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    })))),
    /* @__PURE__ */ React20.createElement("svg", {
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      className: "purple",
      key: "4"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M32.7568 43.1367L12 80.8H39.3056L46.409 67.9108L32.9988 43.5767L32.7568 43.1379V43.1367ZM45.8852 16L36.8735 32.3518L37.1154 32.7907L50.5257 57.1247L59.5374 40.7729L45.8852 16ZM67.8849 43.9043L51.7697 73.1454H84L67.8849 43.9043Z",
      fill: "url(#paint0_linear_6498_41678)"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_6498_41678",
      x1: "30",
      y1: "80.8",
      x2: "64.4007",
      y2: "15.0003",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      offset: "0.00520833",
      "stop-color": "#00C9FF"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "0.989583",
      "stop-color": "#6800FF"
    })))),
    /* @__PURE__ */ React20.createElement("svg", {
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      className: "warm",
      key: "5"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M32.7568 43.1367L12 80.8H39.3056L46.409 67.9108L32.9988 43.5767L32.7568 43.1379V43.1367ZM45.8852 16L36.8735 32.3518L37.1154 32.7907L50.5257 57.1247L59.5374 40.7729L45.8852 16ZM67.8849 43.9043L51.7697 73.1454H84L67.8849 43.9043Z",
      fill: "url(#paint0_linear_6498_41407)"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_6498_41407",
      x1: "30",
      y1: "80.8",
      x2: "64.4007",
      y2: "15.0003",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      offset: "0.00520833",
      "stop-color": "#FFCC00"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "0.989583",
      "stop-color": "#FF008E"
    })))),
    /* @__PURE__ */ React20.createElement("svg", {
      className: "purple-green",
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M32.7568 43.1367L12 80.8H39.3056L46.409 67.9108L32.9988 43.5767L32.7568 43.1379V43.1367ZM45.8852 16L36.8735 32.3518L37.1154 32.7907L50.5257 57.1247L59.5374 40.7729L45.8852 16ZM67.8849 43.9043L51.7697 73.1454H84L67.8849 43.9043Z",
      fill: "url(#paint0_linear_7222_66419)"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_7222_66419",
      x1: "61.9048",
      y1: "38.8273",
      x2: "18.5758",
      y2: "78.7843",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#ED28D7"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#00F4BE"
    })))),
    /* @__PURE__ */ React20.createElement("svg", {
      className: "warm",
      width: "97",
      height: "96",
      viewBox: "0 0 97 96",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, /* @__PURE__ */ React20.createElement("g", {
      opacity: "0.6"
    }, /* @__PURE__ */ React20.createElement("path", {
      d: "M21.5 12V84",
      stroke: "url(#paint0_linear_7222_3499)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 75L12.5 75",
      stroke: "url(#paint1_linear_7222_3499)",
      "stroke-opacity": "0.1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M30.5 12V84",
      stroke: "url(#paint2_linear_7222_3499)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 66L12.5 66",
      stroke: "url(#paint3_linear_7222_3499)",
      "stroke-opacity": "0.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M39.5 12V84",
      stroke: "url(#paint4_linear_7222_3499)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 57L12.5 57",
      stroke: "url(#paint5_linear_7222_3499)",
      "stroke-opacity": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M48.5 12V84",
      stroke: "url(#paint6_linear_7222_3499)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 48L12.5 48",
      stroke: "url(#paint7_linear_7222_3499)",
      "stroke-opacity": "0.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M57.5 12V84",
      stroke: "url(#paint8_linear_7222_3499)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 39L12.5 39",
      stroke: "url(#paint9_linear_7222_3499)",
      "stroke-opacity": "0.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M66.5 12V84",
      stroke: "url(#paint10_linear_7222_3499)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 30L12.5 30",
      stroke: "url(#paint11_linear_7222_3499)",
      "stroke-opacity": "0.6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M75.5 12V84",
      stroke: "url(#paint12_linear_7222_3499)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 21L12.5 21",
      stroke: "url(#paint13_linear_7222_3499)",
      "stroke-opacity": "0.7",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12V84",
      stroke: "url(#paint14_linear_7222_3499)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }), /* @__PURE__ */ React20.createElement("path", {
      d: "M84.5 12L12.5 12",
      stroke: "url(#paint15_linear_7222_3499)",
      "stroke-opacity": "0.8",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    })), /* @__PURE__ */ React20.createElement("path", {
      d: "M17.1406 83.9999V70.5539L35.1406 43.7579L53.4766 53.9339L79.6066 17.7539V83.9999",
      fill: "url(#paint16_linear_7222_3499)"
    }), /* @__PURE__ */ React20.createElement("defs", null, /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint0_linear_7222_3499",
      x1: "20.4959",
      y1: "84.3",
      x2: "20.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint1_linear_7222_3499",
      x1: "12.2",
      y1: "76.0041",
      x2: "84.8",
      y2: "76.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint2_linear_7222_3499",
      x1: "29.4959",
      y1: "84.3",
      x2: "29.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint3_linear_7222_3499",
      x1: "12.2",
      y1: "67.0041",
      x2: "84.8",
      y2: "67.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint4_linear_7222_3499",
      x1: "38.4959",
      y1: "84.3",
      x2: "38.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint5_linear_7222_3499",
      x1: "12.2",
      y1: "58.0041",
      x2: "84.8",
      y2: "58.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint6_linear_7222_3499",
      x1: "47.4959",
      y1: "84.3",
      x2: "47.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint7_linear_7222_3499",
      x1: "12.2",
      y1: "49.0041",
      x2: "84.8",
      y2: "49.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint8_linear_7222_3499",
      x1: "56.4959",
      y1: "84.3",
      x2: "56.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint9_linear_7222_3499",
      x1: "12.2",
      y1: "40.0041",
      x2: "84.8",
      y2: "40.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint10_linear_7222_3499",
      x1: "65.4959",
      y1: "84.3",
      x2: "65.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint11_linear_7222_3499",
      x1: "12.2",
      y1: "31.0041",
      x2: "84.8",
      y2: "31.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint12_linear_7222_3499",
      x1: "74.4959",
      y1: "84.3",
      x2: "74.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint13_linear_7222_3499",
      x1: "12.2",
      y1: "22.0041",
      x2: "84.8",
      y2: "22.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint14_linear_7222_3499",
      x1: "83.4959",
      y1: "84.3",
      x2: "83.4959",
      y2: "11.7",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint15_linear_7222_3499",
      x1: "12.2",
      y1: "13.0041",
      x2: "84.8",
      y2: "13.0041",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FFCC00",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    })), /* @__PURE__ */ React20.createElement("linearGradient", {
      id: "paint16_linear_7222_3499",
      x1: "48.3106",
      y1: "84.6719",
      x2: "48.5806",
      y2: "52.1399",
      gradientUnits: "userSpaceOnUse"
    }, /* @__PURE__ */ React20.createElement("stop", {
      "stop-color": "#FF008E",
      "stop-opacity": "0"
    }), /* @__PURE__ */ React20.createElement("stop", {
      offset: "1",
      "stop-color": "#FFCC00"
    }))))
  ];
  const cardsObj = images.map((image, index) => ({
    image,
    title: cardsData[index].title,
    description: cardsData[index].description,
    link: cardsData[index].link
  }));
  return /* @__PURE__ */ React20.createElement("div", {
    className: "cards-wrapper"
  }, cardsObj.map(({ title, image, description, link }, index) => {
    return /* @__PURE__ */ React20.createElement(Link, {
      key: index,
      to: `/docs${link}`,
      className: "card"
    }, /* @__PURE__ */ React20.createElement("div", {
      className: "card-image"
    }, image), /* @__PURE__ */ React20.createElement("div", {
      className: "card-content"
    }, /* @__PURE__ */ React20.createElement("h3", {
      className: "card-title"
    }, title), /* @__PURE__ */ React20.createElement("p", {
      className: "card-description"
    }, description)));
  }));
};

// src/DocsMDX.tsx
var DocsMDX = ({ isPreview = false, markdown }) => {
  hljs.registerLanguage("json", json);
  hljs.registerLanguage("javascript", javascript);
  const elRef = useRef(null);
  useEffect(() => {
    var _a, _b, _c, _d;
    (_b = (_a = elRef.current) == null ? void 0 : _a.querySelectorAll("pre code")) == null ? void 0 : _b.forEach((block) => {
      hljs.highlightBlock(block);
    });
    if (isPreview)
      return;
    (_d = (_c = elRef.current) == null ? void 0 : _c.querySelectorAll("table")) == null ? void 0 : _d.forEach((table2) => {
      var _a2;
      const parent = document.createElement("div");
      parent.classList.add("scrollable-table");
      (_a2 = table2.parentNode) == null ? void 0 : _a2.insertBefore(parent, table2);
      parent.appendChild(table2);
    });
  }, [isPreview]);
  return /* @__PURE__ */ React21.createElement("div", {
    ref: (ref) => elRef.current = ref,
    className: "markdown"
  }, /* @__PURE__ */ React21.createElement(Markdown2, {
    options: {
      slugify: (str) => {
        return cleanUpRouteString(str);
      },
      overrides: {
        img: {
          component: (_a) => {
            var _b = _a, { src } = _b, props = __objRest(_b, ["src"]);
            return /* @__PURE__ */ React21.createElement("img", __spreadValues({
              src: String(src).startsWith("http") ? src : DOCS_HOST + src
            }, props));
          }
        },
        APIMetricTable: {
          component: APIMetricTable
        },
        APIFilterTables: {
          component: APIReportTables
        },
        APIUploadDimensions: {
          component: APIUploadDimensions
        },
        APITimezoneTable: {
          component: APITimezoneTable
        },
        APICurrencyTable: {
          component: APICurrencyTable
        },
        APISpentDimensionsTable: {
          component: APISpentDimensionsTable
        },
        TopicCards: {
          component: TopicCards
        },
        APIWebReportTables: {
          component: APIWebReportTables
        },
        APITrafficShapingSamplesPredictTables: {
          component: APITrafficShapingSamplesPredictTables
        }
      }
    }
  }, markdown.replace(/~!HIDEONTHEWEBSITE/g, "")));
};
var areEqual = (prevProps, nextProps) => {
  return prevProps.markdown === nextProps.markdown;
};
var MemoDocsMDX = React21.memo(DocsMDX, areEqual);

// src/statuspageContext.tsx
import React22, { createContext as createContext2, useContext as useContext3, useEffect as useEffect3 } from "react";

// ../../node_modules/swr/esm/use-swr.js
import { useCallback, useContext, useState as useState5, useRef as useRef2, useMemo, useDebugValue } from "react";

// ../../node_modules/dequal/lite/index.mjs
var has = Object.prototype.hasOwnProperty;
function dequal(foo, bar) {
  var ctor, len;
  if (foo === bar)
    return true;
  if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
    if (ctor === Date)
      return foo.getTime() === bar.getTime();
    if (ctor === RegExp)
      return foo.toString() === bar.toString();
    if (ctor === Array) {
      if ((len = foo.length) === bar.length) {
        while (len-- && dequal(foo[len], bar[len]))
          ;
      }
      return len === -1;
    }
    if (!ctor || typeof foo === "object") {
      len = 0;
      for (ctor in foo) {
        if (has.call(foo, ctor) && ++len && !has.call(bar, ctor))
          return false;
        if (!(ctor in bar) || !dequal(foo[ctor], bar[ctor]))
          return false;
      }
      return Object.keys(bar).length === len;
    }
  }
  return foo !== foo && bar !== bar;
}

// ../../node_modules/swr/esm/libs/hash.js
var table = new WeakMap();
var counter = 0;
function hash(args) {
  if (!args.length)
    return "";
  var key = "arg";
  for (var i = 0; i < args.length; ++i) {
    if (args[i] === null) {
      key += "@null";
      continue;
    }
    var _hash = void 0;
    if (typeof args[i] !== "object" && typeof args[i] !== "function") {
      if (typeof args[i] === "string") {
        _hash = '"' + args[i] + '"';
      } else {
        _hash = String(args[i]);
      }
    } else {
      if (!table.has(args[i])) {
        _hash = counter;
        table.set(args[i], counter++);
      } else {
        _hash = table.get(args[i]);
      }
    }
    key += "@" + _hash;
  }
  return key;
}

// ../../node_modules/swr/esm/cache.js
var Cache = function() {
  function Cache2(initialData) {
    if (initialData === void 0) {
      initialData = {};
    }
    this.cache = new Map(Object.entries(initialData));
    this.subs = [];
  }
  Cache2.prototype.get = function(key) {
    var _key = this.serializeKey(key)[0];
    return this.cache.get(_key);
  };
  Cache2.prototype.set = function(key, value) {
    var _key = this.serializeKey(key)[0];
    this.cache.set(_key, value);
    this.notify();
  };
  Cache2.prototype.keys = function() {
    return Array.from(this.cache.keys());
  };
  Cache2.prototype.has = function(key) {
    var _key = this.serializeKey(key)[0];
    return this.cache.has(_key);
  };
  Cache2.prototype.clear = function() {
    this.cache.clear();
    this.notify();
  };
  Cache2.prototype.delete = function(key) {
    var _key = this.serializeKey(key)[0];
    this.cache.delete(_key);
    this.notify();
  };
  Cache2.prototype.serializeKey = function(key) {
    var args = null;
    if (typeof key === "function") {
      try {
        key = key();
      } catch (err) {
        key = "";
      }
    }
    if (Array.isArray(key)) {
      args = key;
      key = hash(key);
    } else {
      key = String(key || "");
    }
    var errorKey = key ? "err@" + key : "";
    var isValidatingKey = key ? "validating@" + key : "";
    return [key, args, errorKey, isValidatingKey];
  };
  Cache2.prototype.subscribe = function(listener) {
    var _this = this;
    if (typeof listener !== "function") {
      throw new Error("Expected the listener to be a function.");
    }
    var isSubscribed = true;
    this.subs.push(listener);
    return function() {
      if (!isSubscribed)
        return;
      isSubscribed = false;
      var index = _this.subs.indexOf(listener);
      if (index > -1) {
        _this.subs[index] = _this.subs[_this.subs.length - 1];
        _this.subs.length--;
      }
    };
  };
  Cache2.prototype.notify = function() {
    for (var _i = 0, _a = this.subs; _i < _a.length; _i++) {
      var listener = _a[_i];
      listener();
    }
  };
  return Cache2;
}();
var cache_default = Cache;

// ../../node_modules/swr/esm/libs/web-preset.js
var online = true;
var isOnline = function() {
  return online;
};
var isDocumentVisible = function() {
  if (typeof document !== "undefined" && document.visibilityState !== void 0) {
    return document.visibilityState !== "hidden";
  }
  return true;
};
var fetcher = function(url) {
  return fetch(url).then(function(res) {
    return res.json();
  });
};
var registerOnFocus = function(cb) {
  if (typeof window !== "undefined" && window.addEventListener !== void 0 && typeof document !== "undefined" && document.addEventListener !== void 0) {
    document.addEventListener("visibilitychange", function() {
      return cb();
    }, false);
    window.addEventListener("focus", function() {
      return cb();
    }, false);
  }
};
var registerOnReconnect = function(cb) {
  if (typeof window !== "undefined" && window.addEventListener !== void 0) {
    window.addEventListener("online", function() {
      online = true;
      cb();
    }, false);
    window.addEventListener("offline", function() {
      return online = false;
    }, false);
  }
};
var web_preset_default = {
  isOnline,
  isDocumentVisible,
  fetcher,
  registerOnFocus,
  registerOnReconnect
};

// ../../node_modules/swr/esm/config.js
var __assign = function() {
  __assign = Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p))
          t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var cache = new cache_default();
function onErrorRetry(_, __, config, revalidate, opts) {
  if (!config.isDocumentVisible()) {
    return;
  }
  if (typeof config.errorRetryCount === "number" && opts.retryCount > config.errorRetryCount) {
    return;
  }
  var count = Math.min(opts.retryCount, 8);
  var timeout = ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;
  setTimeout(revalidate, timeout, opts);
}
var slowConnection = typeof window !== "undefined" && navigator["connection"] && ["slow-2g", "2g"].indexOf(navigator["connection"].effectiveType) !== -1;
var defaultConfig = __assign({
  onLoadingSlow: function() {
  },
  onSuccess: function() {
  },
  onError: function() {
  },
  onErrorRetry,
  errorRetryInterval: (slowConnection ? 10 : 5) * 1e3,
  focusThrottleInterval: 5 * 1e3,
  dedupingInterval: 2 * 1e3,
  loadingTimeout: (slowConnection ? 5 : 3) * 1e3,
  refreshInterval: 0,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  refreshWhenHidden: false,
  refreshWhenOffline: false,
  shouldRetryOnError: true,
  suspense: false,
  compare: dequal,
  isPaused: function() {
    return false;
  }
}, web_preset_default);
var config_default = defaultConfig;

// ../../node_modules/swr/esm/env.js
import { useEffect as useEffect2, useLayoutEffect } from "react";
var IS_SERVER = typeof window === "undefined" || !!(typeof Deno !== "undefined" && Deno && Deno.version && Deno.version.deno);
var rAF = IS_SERVER ? null : window["requestAnimationFrame"] ? function(f) {
  return window["requestAnimationFrame"](f);
} : function(f) {
  return setTimeout(f, 1);
};
var useIsomorphicLayoutEffect = IS_SERVER ? useEffect2 : useLayoutEffect;

// ../../node_modules/swr/esm/swr-config-context.js
import { createContext } from "react";
var SWRConfigContext = createContext({});
SWRConfigContext.displayName = "SWRConfigContext";
var swr_config_context_default = SWRConfigContext;

// ../../node_modules/swr/esm/use-swr.js
var __awaiter = function(thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function(resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function(resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = function(thisArg, body) {
  var _ = { label: 0, sent: function() {
    if (t[0] & 1)
      throw t[1];
    return t[1];
  }, trys: [], ops: [] }, f, y, t, g;
  return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
    return this;
  }), g;
  function verb(n) {
    return function(v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f)
      throw new TypeError("Generator is already executing.");
    while (_)
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
          return t;
        if (y = 0, t)
          op = [op[0] & 2, t.value];
        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;
          case 4:
            _.label++;
            return { value: op[1], done: false };
          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;
          case 7:
            op = _.ops.pop();
            _.trys.pop();
            continue;
          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }
            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }
            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }
            if (t && _.label < t[2]) {
              _.label = t[2];
              _.ops.push(op);
              break;
            }
            if (t[2])
              _.ops.pop();
            _.trys.pop();
            continue;
        }
        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    if (op[0] & 5)
      throw op[1];
    return { value: op[0] ? op[1] : void 0, done: true };
  }
};
var CONCURRENT_PROMISES = {};
var CONCURRENT_PROMISES_TS = {};
var FOCUS_REVALIDATORS = {};
var RECONNECT_REVALIDATORS = {};
var CACHE_REVALIDATORS = {};
var MUTATION_TS = {};
var MUTATION_END_TS = {};
var now = function() {
  var ts = 0;
  return function() {
    return ++ts;
  };
}();
if (!IS_SERVER) {
  revalidate_1 = function(revalidators) {
    if (!config_default.isDocumentVisible() || !config_default.isOnline())
      return;
    for (var key in revalidators) {
      if (revalidators[key][0])
        revalidators[key][0]();
    }
  };
  if (typeof config_default.registerOnFocus === "function") {
    config_default.registerOnFocus(function() {
      return revalidate_1(FOCUS_REVALIDATORS);
    });
  }
  if (typeof config_default.registerOnReconnect === "function") {
    config_default.registerOnReconnect(function() {
      return revalidate_1(RECONNECT_REVALIDATORS);
    });
  }
}
var revalidate_1;
var trigger = function(_key, shouldRevalidate) {
  if (shouldRevalidate === void 0) {
    shouldRevalidate = true;
  }
  var _a = cache.serializeKey(_key), key = _a[0], keyErr = _a[2], keyValidating = _a[3];
  if (!key)
    return Promise.resolve();
  var updaters = CACHE_REVALIDATORS[key];
  if (key && updaters) {
    var currentData = cache.get(key);
    var currentError = cache.get(keyErr);
    var currentIsValidating = cache.get(keyValidating);
    var promises = [];
    for (var i = 0; i < updaters.length; ++i) {
      promises.push(updaters[i](shouldRevalidate, currentData, currentError, currentIsValidating, i > 0));
    }
    return Promise.all(promises).then(function() {
      return cache.get(key);
    });
  }
  return Promise.resolve(cache.get(key));
};
var broadcastState = function(key, data, error, isValidating) {
  var updaters = CACHE_REVALIDATORS[key];
  if (key && updaters) {
    for (var i = 0; i < updaters.length; ++i) {
      updaters[i](false, data, error, isValidating);
    }
  }
};
var mutate = function(_key, _data, shouldRevalidate) {
  if (shouldRevalidate === void 0) {
    shouldRevalidate = true;
  }
  return __awaiter(void 0, void 0, void 0, function() {
    var _a, key, keyErr, beforeMutationTs, beforeConcurrentPromisesTs, data, error, isAsyncMutation, err_1, shouldAbort, updaters, promises, i;
    return __generator(this, function(_b) {
      switch (_b.label) {
        case 0:
          _a = cache.serializeKey(_key), key = _a[0], keyErr = _a[2];
          if (!key)
            return [2];
          if (typeof _data === "undefined")
            return [
              2,
              trigger(_key, shouldRevalidate)
            ];
          MUTATION_TS[key] = now() - 1;
          MUTATION_END_TS[key] = 0;
          beforeMutationTs = MUTATION_TS[key];
          beforeConcurrentPromisesTs = CONCURRENT_PROMISES_TS[key];
          isAsyncMutation = false;
          if (_data && typeof _data === "function") {
            try {
              _data = _data(cache.get(key));
            } catch (err) {
              _data = void 0;
              error = err;
            }
          }
          if (!(_data && typeof _data.then === "function"))
            return [3, 5];
          isAsyncMutation = true;
          _b.label = 1;
        case 1:
          _b.trys.push([1, 3, , 4]);
          return [4, _data];
        case 2:
          data = _b.sent();
          return [3, 4];
        case 3:
          err_1 = _b.sent();
          error = err_1;
          return [3, 4];
        case 4:
          return [3, 6];
        case 5:
          data = _data;
          _b.label = 6;
        case 6:
          shouldAbort = function() {
            if (beforeMutationTs !== MUTATION_TS[key] || beforeConcurrentPromisesTs !== CONCURRENT_PROMISES_TS[key]) {
              if (error)
                throw error;
              return true;
            }
          };
          if (shouldAbort())
            return [2, data];
          if (typeof data !== "undefined") {
            cache.set(key, data);
          }
          cache.set(keyErr, error);
          MUTATION_END_TS[key] = now() - 1;
          if (!isAsyncMutation) {
            if (shouldAbort())
              return [2, data];
          }
          updaters = CACHE_REVALIDATORS[key];
          if (updaters) {
            promises = [];
            for (i = 0; i < updaters.length; ++i) {
              promises.push(updaters[i](!!shouldRevalidate, data, error, void 0, i > 0));
            }
            return [2, Promise.all(promises).then(function() {
              if (error)
                throw error;
              return cache.get(key);
            })];
          }
          if (error)
            throw error;
          return [2, data];
      }
    });
  });
};
function useSWR() {
  var _this = this;
  var args = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }
  var _key = args[0];
  var config = Object.assign({}, config_default, useContext(swr_config_context_default), args.length > 2 ? args[2] : args.length === 2 && typeof args[1] === "object" ? args[1] : {});
  var fn = args.length > 2 ? args[1] : args.length === 2 && typeof args[1] === "function" ? args[1] : args[1] === null ? args[1] : config.fetcher;
  var _a = cache.serializeKey(_key), key = _a[0], fnArgs = _a[1], keyErr = _a[2], keyValidating = _a[3];
  var configRef = useRef2(config);
  useIsomorphicLayoutEffect(function() {
    configRef.current = config;
  });
  var willRevalidateOnMount = function() {
    return config.revalidateOnMount || !config.initialData && config.revalidateOnMount === void 0;
  };
  var resolveData = function() {
    var cachedData = cache.get(key);
    return typeof cachedData === "undefined" ? config.initialData : cachedData;
  };
  var resolveIsValidating = function() {
    return !!cache.get(keyValidating) || key && willRevalidateOnMount();
  };
  var initialData = resolveData();
  var initialError = cache.get(keyErr);
  var initialIsValidating = resolveIsValidating();
  var stateDependencies = useRef2({
    data: false,
    error: false,
    isValidating: false
  });
  var stateRef = useRef2({
    data: initialData,
    error: initialError,
    isValidating: initialIsValidating
  });
  useDebugValue(stateRef.current.data);
  var rerender = useState5({})[1];
  var dispatch = useCallback(function(payload) {
    var shouldUpdateState = false;
    for (var k in payload) {
      if (stateRef.current[k] === payload[k]) {
        continue;
      }
      stateRef.current[k] = payload[k];
      if (stateDependencies.current[k]) {
        shouldUpdateState = true;
      }
    }
    if (shouldUpdateState) {
      if (unmountedRef.current || !initialMountedRef.current)
        return;
      rerender({});
    }
  }, []);
  var unmountedRef = useRef2(false);
  var keyRef = useRef2(key);
  var initialMountedRef = useRef2(false);
  var eventsCallback = useCallback(function(event) {
    var _a2;
    var params = [];
    for (var _i2 = 1; _i2 < arguments.length; _i2++) {
      params[_i2 - 1] = arguments[_i2];
    }
    if (unmountedRef.current)
      return;
    if (!initialMountedRef.current)
      return;
    if (key !== keyRef.current)
      return;
    (_a2 = configRef.current)[event].apply(_a2, params);
  }, [key]);
  var boundMutate = useCallback(function(data, shouldRevalidate) {
    return mutate(keyRef.current, data, shouldRevalidate);
  }, []);
  var addRevalidator = function(revalidators, callback) {
    if (!revalidators[key]) {
      revalidators[key] = [callback];
    } else {
      revalidators[key].push(callback);
    }
    return function() {
      var keyedRevalidators = revalidators[key];
      var index = keyedRevalidators.indexOf(callback);
      if (index >= 0) {
        keyedRevalidators[index] = keyedRevalidators[keyedRevalidators.length - 1];
        keyedRevalidators.pop();
      }
    };
  };
  var revalidate = useCallback(function(revalidateOpts) {
    if (revalidateOpts === void 0) {
      revalidateOpts = {};
    }
    return __awaiter(_this, void 0, void 0, function() {
      var _a2, retryCount, _b, dedupe, loading, shouldDeduping, newData, startAt, newState, err_2;
      return __generator(this, function(_c) {
        switch (_c.label) {
          case 0:
            if (!key || !fn)
              return [2, false];
            if (unmountedRef.current)
              return [2, false];
            if (configRef.current.isPaused())
              return [2, false];
            _a2 = revalidateOpts.retryCount, retryCount = _a2 === void 0 ? 0 : _a2, _b = revalidateOpts.dedupe, dedupe = _b === void 0 ? false : _b;
            loading = true;
            shouldDeduping = typeof CONCURRENT_PROMISES[key] !== "undefined" && dedupe;
            _c.label = 1;
          case 1:
            _c.trys.push([1, 6, , 7]);
            dispatch({
              isValidating: true
            });
            cache.set(keyValidating, true);
            if (!shouldDeduping) {
              broadcastState(key, stateRef.current.data, stateRef.current.error, true);
            }
            newData = void 0;
            startAt = void 0;
            if (!shouldDeduping)
              return [3, 3];
            startAt = CONCURRENT_PROMISES_TS[key];
            return [4, CONCURRENT_PROMISES[key]];
          case 2:
            newData = _c.sent();
            return [3, 5];
          case 3:
            if (config.loadingTimeout && !cache.get(key)) {
              setTimeout(function() {
                if (loading)
                  eventsCallback("onLoadingSlow", key, config);
              }, config.loadingTimeout);
            }
            if (fnArgs !== null) {
              CONCURRENT_PROMISES[key] = fn.apply(void 0, fnArgs);
            } else {
              CONCURRENT_PROMISES[key] = fn(key);
            }
            CONCURRENT_PROMISES_TS[key] = startAt = now();
            return [4, CONCURRENT_PROMISES[key]];
          case 4:
            newData = _c.sent();
            setTimeout(function() {
              delete CONCURRENT_PROMISES[key];
              delete CONCURRENT_PROMISES_TS[key];
            }, config.dedupingInterval);
            eventsCallback("onSuccess", newData, key, config);
            _c.label = 5;
          case 5:
            if (CONCURRENT_PROMISES_TS[key] > startAt) {
              return [2, false];
            }
            if (MUTATION_TS[key] && (startAt <= MUTATION_TS[key] || startAt <= MUTATION_END_TS[key] || MUTATION_END_TS[key] === 0)) {
              dispatch({ isValidating: false });
              return [2, false];
            }
            cache.set(keyErr, void 0);
            cache.set(keyValidating, false);
            newState = {
              isValidating: false
            };
            if (typeof stateRef.current.error !== "undefined") {
              newState.error = void 0;
            }
            if (!config.compare(stateRef.current.data, newData)) {
              newState.data = newData;
            }
            if (!config.compare(cache.get(key), newData)) {
              cache.set(key, newData);
            }
            dispatch(newState);
            if (!shouldDeduping) {
              broadcastState(key, newData, newState.error, false);
            }
            return [3, 7];
          case 6:
            err_2 = _c.sent();
            delete CONCURRENT_PROMISES[key];
            delete CONCURRENT_PROMISES_TS[key];
            if (configRef.current.isPaused()) {
              dispatch({
                isValidating: false
              });
              return [2, false];
            }
            cache.set(keyErr, err_2);
            if (stateRef.current.error !== err_2) {
              dispatch({
                isValidating: false,
                error: err_2
              });
              if (!shouldDeduping) {
                broadcastState(key, void 0, err_2, false);
              }
            }
            eventsCallback("onError", err_2, key, config);
            if (config.shouldRetryOnError) {
              eventsCallback("onErrorRetry", err_2, key, config, revalidate, {
                retryCount: retryCount + 1,
                dedupe: true
              });
            }
            return [3, 7];
          case 7:
            loading = false;
            return [2, true];
        }
      });
    });
  }, [key]);
  useIsomorphicLayoutEffect(function() {
    if (!key)
      return void 0;
    unmountedRef.current = false;
    var isUpdating = initialMountedRef.current;
    initialMountedRef.current = true;
    var currentHookData = stateRef.current.data;
    var latestKeyedData = resolveData();
    keyRef.current = key;
    if (!config.compare(currentHookData, latestKeyedData)) {
      dispatch({ data: latestKeyedData });
    }
    var softRevalidate = function() {
      return revalidate({ dedupe: true });
    };
    if (isUpdating || willRevalidateOnMount()) {
      if (typeof latestKeyedData !== "undefined" && !IS_SERVER) {
        rAF(softRevalidate);
      } else {
        softRevalidate();
      }
    }
    var pending = false;
    var onFocus = function() {
      if (pending || !configRef.current.revalidateOnFocus)
        return;
      pending = true;
      softRevalidate();
      setTimeout(function() {
        return pending = false;
      }, configRef.current.focusThrottleInterval);
    };
    var onReconnect = function() {
      if (configRef.current.revalidateOnReconnect) {
        softRevalidate();
      }
    };
    var onUpdate = function(shouldRevalidate, updatedData, updatedError, updatedIsValidating, dedupe) {
      if (shouldRevalidate === void 0) {
        shouldRevalidate = true;
      }
      if (dedupe === void 0) {
        dedupe = true;
      }
      var newState = {};
      var needUpdate = false;
      if (typeof updatedData !== "undefined" && !config.compare(stateRef.current.data, updatedData)) {
        newState.data = updatedData;
        needUpdate = true;
      }
      if (stateRef.current.error !== updatedError) {
        newState.error = updatedError;
        needUpdate = true;
      }
      if (typeof updatedIsValidating !== "undefined" && stateRef.current.isValidating !== updatedIsValidating) {
        newState.isValidating = updatedIsValidating;
        needUpdate = true;
      }
      if (needUpdate) {
        dispatch(newState);
      }
      if (shouldRevalidate) {
        if (dedupe) {
          return softRevalidate();
        } else {
          return revalidate();
        }
      }
      return false;
    };
    var unsubFocus = addRevalidator(FOCUS_REVALIDATORS, onFocus);
    var unsubReconnect = addRevalidator(RECONNECT_REVALIDATORS, onReconnect);
    var unsubUpdate = addRevalidator(CACHE_REVALIDATORS, onUpdate);
    return function() {
      dispatch = function() {
        return null;
      };
      unmountedRef.current = true;
      unsubFocus();
      unsubReconnect();
      unsubUpdate();
    };
  }, [key, revalidate]);
  useIsomorphicLayoutEffect(function() {
    var timer = null;
    var tick = function() {
      return __awaiter(_this, void 0, void 0, function() {
        return __generator(this, function(_a2) {
          switch (_a2.label) {
            case 0:
              if (!(!stateRef.current.error && (configRef.current.refreshWhenHidden || configRef.current.isDocumentVisible()) && (configRef.current.refreshWhenOffline || configRef.current.isOnline())))
                return [3, 2];
              return [4, revalidate({ dedupe: true })];
            case 1:
              _a2.sent();
              _a2.label = 2;
            case 2:
              if (configRef.current.refreshInterval && timer) {
                timer = setTimeout(tick, configRef.current.refreshInterval);
              }
              return [2];
          }
        });
      });
    };
    if (configRef.current.refreshInterval) {
      timer = setTimeout(tick, configRef.current.refreshInterval);
    }
    return function() {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    };
  }, [
    config.refreshInterval,
    config.refreshWhenHidden,
    config.refreshWhenOffline,
    revalidate
  ]);
  var latestData;
  var latestError;
  if (config.suspense) {
    latestData = cache.get(key);
    latestError = cache.get(keyErr);
    if (typeof latestData === "undefined") {
      latestData = initialData;
    }
    if (typeof latestError === "undefined") {
      latestError = initialError;
    }
    if (typeof latestData === "undefined" && typeof latestError === "undefined") {
      if (!CONCURRENT_PROMISES[key]) {
        revalidate();
      }
      if (CONCURRENT_PROMISES[key] && typeof CONCURRENT_PROMISES[key].then === "function") {
        throw CONCURRENT_PROMISES[key];
      }
      latestData = CONCURRENT_PROMISES[key];
    }
    if (typeof latestData === "undefined" && latestError) {
      throw latestError;
    }
  }
  var memoizedState = useMemo(function() {
    var state = { revalidate, mutate: boundMutate };
    Object.defineProperties(state, {
      error: {
        get: function() {
          stateDependencies.current.error = true;
          if (config.suspense) {
            return latestError;
          }
          return keyRef.current === key ? stateRef.current.error : initialError;
        },
        enumerable: true
      },
      data: {
        get: function() {
          stateDependencies.current.data = true;
          if (config.suspense) {
            return latestData;
          }
          return keyRef.current === key ? stateRef.current.data : initialData;
        },
        enumerable: true
      },
      isValidating: {
        get: function() {
          stateDependencies.current.isValidating = true;
          return key ? stateRef.current.isValidating : false;
        },
        enumerable: true
      }
    });
    return state;
  }, [
    revalidate,
    initialData,
    initialError,
    boundMutate,
    key,
    config.suspense,
    latestError,
    latestData
  ]);
  return memoizedState;
}
Object.defineProperty(swr_config_context_default.Provider, "default", {
  value: config_default
});
var SWRConfig = swr_config_context_default.Provider;
var use_swr_default = useSWR;

// ../../node_modules/swr/esm/use-swr-infinite.js
import { useContext as useContext2, useRef as useRef3, useState as useState6, useCallback as useCallback2 } from "react";

// ../../node_modules/swr/esm/index.js
var esm_default = use_swr_default;

// src/statuspageContext.tsx
var fetcher2 = (url) => window.fetch(url).then((r) => r.json());
var INITIAL_VALUES = {
  isHavingOutage: void 0,
  indicator: void 0,
  description: void 0,
  scheduledMaintenances: void 0
};
var LONG_POLL_STATUS_MS = 5e3;
var getDescription = (summary) => {
  var _a, _b;
  const incidents = (_a = summary == null ? void 0 : summary.incidents) != null ? _a : [];
  const description = (_b = summary == null ? void 0 : summary.status) == null ? void 0 : _b.description;
  if (incidents.length > 0) {
    const titles = incidents.map((it) => it == null ? void 0 : it.name);
    const incs = titles.join(", ");
    return `${description} - (${incs})`;
  }
  return description;
};
var THIRD_PARTY_GROUP_ID = "vqrt4mbyxpy0";
var processSummary = (summary) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const indicator = (_a = summary == null ? void 0 : summary.status) == null ? void 0 : _a.indicator;
  const description = getDescription(summary);
  const isHavingOutage = indicator != null && indicator !== "none";
  const scheduledMaintenances = summary == null ? void 0 : summary.scheduled_maintenances;
  const thirdPartyIds = (_d = (_c = (_b = summary == null ? void 0 : summary.components) == null ? void 0 : _b.find((it) => it.id === THIRD_PARTY_GROUP_ID)) == null ? void 0 : _c.components) != null ? _d : [];
  const nonThirdPartyComponents = (_f = (_e = summary == null ? void 0 : summary.components) == null ? void 0 : _e.filter((it) => !thirdPartyIds.includes(it.id) && it.id !== THIRD_PARTY_GROUP_ID)) != null ? _f : [];
  const isNonThirdPartyBroken = nonThirdPartyComponents.some((it) => it.status !== "operational");
  const thirdPartyComponents = (_h = (_g = summary == null ? void 0 : summary.components) == null ? void 0 : _g.filter((it) => thirdPartyIds.includes(it.id))) != null ? _h : [];
  const isThirdPartyOnlyMinor = thirdPartyComponents.length > 0 && thirdPartyComponents.every((it) => ["degraded_performance", "operational"].includes(it.status));
  if (!isNonThirdPartyBroken && isThirdPartyOnlyMinor) {
    return {
      isHavingOutage: false,
      indicator: "none",
      description: void 0,
      scheduledMaintenances
    };
  }
  return {
    isHavingOutage,
    indicator,
    description,
    scheduledMaintenances
  };
};
var useStatuspageHook = (isDevMode) => {
  const { data: summary, mutate: mutate2 } = esm_default(isDevMode ? null : STATUSPAGE_SUMMARY_URL, fetcher2);
  useEffect3(() => {
    const interval = setInterval(() => {
      mutate2();
    }, LONG_POLL_STATUS_MS);
    return () => {
      clearInterval(interval);
    };
  }, [mutate2]);
  return processSummary(summary);
};
var StatuspageContext = createContext2(INITIAL_VALUES);
var StatuspageProvider = ({ children, isDevMode }) => {
  const hook = useStatuspageHook(isDevMode);
  const getValue = () => {
    if (isDevMode) {
      return {
        isHavingOutage: false,
        indicator: "none",
        description: "All Systems Operational",
        scheduledMaintenances: []
      };
    }
    return hook;
  };
  return /* @__PURE__ */ React22.createElement(StatuspageContext.Provider, {
    value: getValue()
  }, children);
};
var useStatuspageStatus = () => useContext3(StatuspageContext);
export {
  APICurrencyTable,
  APIDimensionTable,
  APIFilterTable,
  APIMetricTable,
  APIReportTables,
  APISpentDimensionsTable,
  APITimezoneTable,
  APIUploadDimensions,
  APIWebMetricTable,
  APIWebReportTables,
  DocsMDX,
  MemoDocsMDX,
  StatuspageContext,
  StatuspageProvider,
  TopicCards,
  getDimensionTableData,
  getFilterTableData,
  getMetrics,
  getWebMetrics,
  processSummary,
  useStatuspageStatus
};
